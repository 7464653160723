import React from "react";
import ReactDOM from "react-dom";
import { Link as RouterLink } from 'react-router-dom';

//import { makeStyles } from '@mui/material' 

///import { CardMedia, AppBar, Paper, Box, List, ListItem, ListItemText, Hidden, Drawer, Toolbar, Typography, Button, IconButton, Container } from '@mui/material'   //'@material-ui/core';

import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Hidden from '@mui/material/Hidden'
import Drawer from '@mui/material/Drawer'

// https://mui.com/material-ui/material-icons/
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close';


//import logoImg from '../../images/logo.webp'
import logoImg from '../../images/logo.png'

import { styled } from '@mui/system';
// https://stackoverflow.com/questions/69263383/what-is-the-alternative-of-makestyles-for-material-ui-v-5


import { useNavigate } from 'react-router-dom';





const MyComponent = styled('Menu')({
    color: 'gray',
    backgroundColor: 'gray',
    padding: 18,
    borderRadius: 0,
});

// https://mui.com/system/styled/
const StyledMenu = (props) => (
    <Menu
        sx={{
            mx: 2, width: '4rem', height: '4rem' // ✔️ this shortcut is specific to the `sx` prop,
        }}
    >
        {props.children}
    </Menu>
);

const StyledCloseIcon = () => (
    <CloseIcon
        sx={{
            mx: 2, width: '4rem', height: '4rem' // ✔️ this shortcut is specific to the `sx` prop,
        }}
    >
    </CloseIcon>
);

const StyledButton2 = (props) => (
    <Button

        sx={{
            fontSize: '1.7rem', color: 'black'
            // was 2 rem
        }}
    >
        {props.children}
    </Button>
);

const StyledBox = (props) => (
    <Box
        sx={{
            background: 'rgb(70,70,70)', height: '120%' // WAS SILVER
        }}
    >
        {props.children}
    </Box>
);

const StyledPaper = (props) => (
    <Paper
        sx={{
            background: 'gray' // WAS SILVER / SLATEGRAY / DIMGRAY ✔️ this shortcut is specific to the `sx` prop,
        }}
    >
        {props.children}
    </Paper>
);


// Define a custom styled component with reduced opacity
const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiPaper-root': {
        opacity: 0.90, // Adjust the opacity value as desired
        //backgroundColor: theme.palette.background.default, // Set the desired background color
    },
}));







const MenuBar = () => {

    const [exit, setExit] = React.useState(false);

    const menuCategories = [

        { label: 'Home', url: 'home' },
        { label: 'Wine List', url: 'winelist', displayItem: 'GLASSES' },
        // { label: 'Flights', url: 'winelist', displayItem: 'FLIGHTS' },
        // { label: 'Wine Bottles', url: 'winelist', displayItem: 'BOTTLES' },
        // { label: '*Cocktails', url: 'cocktails' },
        { label: 'Food Menu', url: 'foodmenu' },
        { label: 'Feedback', url: 'feedback' },

        /// Comment these bac in later
        // { label: 'Staff Picks', url: 'staffpicks' },
        // { label: 'Wine Book', url: 'winebook' },



        ////{ label: 'Wine Book', url: 'winebook' },
        ////{ label: '*Staff Favorites', url: 'favorites' },
        ////{ label: '*Wine Review', url: 'review' },

        ///{ label: '*About', url: 'about' },

        //{ label: 'Template', url: 'selector' },

    ]


    // const menuCategories = [
    //     { label: 'Home', url: 'home' },
    //     { label: 'Search Glasses', url: 'searchglasses' },
    //     { label: 'Search Flights', url: 'selector' },
    //     { label: 'Search Bottles', url: 'selector' },
    //     { label: 'Food Pairing', url: 'selector' },
    //     { label: 'Wine Book', url: 'selector' },
    //     { label: 'Staff Favorite', url: 'selector' },
    //     { label: 'Submit Review', url: 'selector' },
    //     { label: 'About', url: 'selector' },
    // ]


    const [mobileOpen, setMobileOpen] = React.useState(false);
    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
        ////console.log('click')
    }





    let navigate = useNavigate();

    function handleClick(textArg) {
        ////console.log("route change", textArg)
        //dispatch(UpdateRoute("hello"))
        navigate('/'+textArg.url)
    }




    const drawer = (
        <div>
            <List  >
                {menuCategories.map((text) => (
                    <ListItem key={text.label} style={{ backgroundColor: 'rgb(30,30,30)', marginBottom: '0.5rem' }}>


                        {/*                         
                        <RouterLink to={{ pathname: "/" + text.url }} state={{ displayItem: text.displayItem }} component="button" > */}


                        <Button onClick={() => handleClick(text.url)}  component="div" fullWidth style={{ fontSize: '18px', color: 'rgb(150,18,57)' }}> {text.label}</Button>

                        {/* <Button component="div" fullWidth style={{ fontSize: '18px', color: 'rgb(150,18,57)', textDecoration: 'none' }}>
                                {text.label}
                            </Button>



                            <Button
                                component="div"
                                fullWidth
                                style={{
                                    fontSize: '18px',
                                    color: 'rgb(150,18,57)',
                                    textDecoration: 'none', // Remove underline from the button
                                }}
                            >
                                {text.label}
                            </Button> */}



                        {/* </RouterLink> */}


                        {/* <Button onClick={handleClick({text})} component="div" fullWidth style={{ fontSize: '18px', color: 'rgb(150,18,57)', textDecoration: 'none' }}>
                            {text.label}
                        </Button> */}


                    </ListItem>
                ))}
            </List>
        </div>
    )






    const drawer2 = (
        <div>
            <List  >
                {menuCategories.map((text) => (
                    <ListItem key={text.label} style={{ backgroundColor: 'rgb(30,30,30)', marginBottom: '0.5rem' }}>

                        <Button onClick={() => handleClick(text.url)} component="div" fullWidth style={{ fontSize: '18px', color: 'rgb(150,18,57)' }}> {text.label}</Button>

                    </ListItem>
                ))}
            </List>
        </div>
    )


















    return (

        <div>
            <AppBar position="relative" >
                <StyledPaper
                >
                    <Box
                        m={0}
                        display="flex"
                        justifyContent="space-between"
                        backgroundColor='gray'

                    >




                        <RouterLink to={{ pathname: "/" }} component="button" >
                            <Paper
                                style={{
                                    margin: '0.5rem',
                                    backgroundColor: 'black',
                                    backgroundImage: `url(${logoImg})`,
                                    repeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    width: "12rem", // these were 15 and 6
                                    height: "6rem",
                                    //borderRadius:'0px'
                                }} square>
                            </Paper>
                        </RouterLink>


                        <IconButton onClick={handleDrawerToggle} >

                            <StyledMenu>
                            </StyledMenu>
                        </IconButton>
                    </Box>
                </StyledPaper>
            </AppBar>



            <nav>


                <Hidden>
                    <StyledDrawer

                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <StyledBox>
                            <IconButton onClick={handleDrawerToggle} >
                                <StyledCloseIcon>
                                </StyledCloseIcon>
                            </IconButton>

                            {/* 
                            {drawer}
                            */}


                            <div>
                                <List  >
                                    {menuCategories.map((text) => (
                                        <ListItem key={text.label} style={{ backgroundColor: 'rgb(30,30,30)', marginBottom: '0.5rem' }}>

                                            <Button onClick={() => handleClick(text)} component="div" fullWidth style={{ fontSize: '18px', color: 'rgb(150,18,57)' }}> {text.label}</Button>

                                        </ListItem>
                                    ))}
                                </List>
                            </div>



                        </StyledBox>

                    </StyledDrawer>
                </Hidden>



            </nav>


        </div>
    )

}


export default MenuBar;








