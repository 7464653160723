import React from 'react';
import { BrowserRouter , Route, Routes } from 'react-router-dom';

//import Home from './components/Home';
//import Selector from './components/Selector';
//import NotFound from './components/NotFound';
//import SearchGlasses from './components/SearchGlasses';
//import WineBook from './components/WineBook';


import FoodMenu from './pages/foodmenu/FoodMenu';
import Home from './pages/home/Home';
import Selector from './pages/Selector';
import NotFound from './pages/NotFound';
import SearchGlasses from './pages/searchglasses/SearchGlasses';
import WineBook from './pages/winebook/WineBook';
import Cocktails from './pages/cocktails/Cocktails';
import StaffPicks from './pages/staffpicks/StaffPicks';

import WineList from './pages/winemenu/WineList';

import FeedbackPage from './pages/feedback/FeedbackPage.jsx';



import HomeDev from './pages/home/HomeDev';


//import { Counter } from './components/Counter'
import './App.css'

import { updateRoute } from './actions/UpdateRoute';
import { connect } from 'react-redux';
// THIS DIDNT WORK "import useNavigate from 'react-router-dom';"
import { useNavigate } from 'react-router-dom';



const App = (props) => {

 /// const history = useNavigate();
/// useHistory ????



  // const handleRouteChange = (newRoute) => {
  //   console.log("route change")
  //   // Dispatch action to update route in Redux store
  //   props.updateRoute(newRoute);
  // };

  // let navigate = useNavigate();

  // function handleClick() {
  //   console.log("route change")
  //   navigate("/home");
  //}







//   return (
//     <div style={{width: '100%'}}>
//       <Routes>
//         <Route path="demo" element={<Home />} />
//         <Route path="searchglasses" element={<SearchGlasses />} />
        
//         <Route path="flights" element={<WineBook />} />

//         <Route path="winelist" element={<WineList />} />

//         <Route path="cocktails" element={<WineBook />} />
//         <Route path="foodmenu" element={<FoodMenu />} />

//         <Route path="staffpicks" element={<StaffPicks />} />

//         <Route path="winebook" element={<WineBook />} />
        
//         <Route path="feedback" element={<FeedbackPage />} />

//         <Route path="selector" element={<Selector />} />
//         <Route path="demo/*" element={<NotFound />} />
//       </Routes>
//     </div>
//   );
// };










return (
  <div style={{width: '100%'}}>
    <Routes>
      <Route index element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="searchglasses" element={<SearchGlasses />} />
      
      <Route path="flights" element={<WineBook />} />

      <Route path="winelist" element={<WineList />} />

      <Route path="cocktails" element={<WineBook />} />
      <Route path="foodmenu" element={<FoodMenu />} />

      <Route path="staffpicks" element={<StaffPicks />} />

      <Route path="winebook" element={<WineBook />} />
      
      <Route path="feedback" element={<FeedbackPage />} />

      <Route path="selector" element={<Selector />} />
      <Route path="*" element={<NotFound />} />


      <Route path="demo" element={<HomeDev />} />
      <Route path="demo/home" element={<HomeDev />} />
      <Route path="demo/winelist" element={<WineList />} />
      <Route path="demo/foodmenu" element={<FoodMenu />} />
      <Route path="demo/staffpicks" element={<StaffPicks />} />
      <Route path="demo/winebook" element={<WineBook />} />


    </Routes>
  </div>
);
};




















{/* <div>
<Routes>
  <Route index element={<Home />} />
  <Route path="home" element={<Home />} />
  <Route path="searchglasses" element={<SearchGlasses />} />
  <Route path="winebook" element={<WineBook />} />
  <Route path="selector" element={<Selector />} />
  <Route path="*" element={<NotFound />} />
</Routes>
</div> */}




// const mapDispatchToProps = (state) => {
//   return {
//     UpdateRoute: (route) => dispatch(updateRoute(route))
//   };
// };




export default App;


/**
 * 








const App = (props) => {

 /// const history = useNavigate();
/// useHistory ????



  const handleRouteChange = (newRoute) => {
    console.log("route change")
    // Dispatch action to update route in Redux store
    props.updateRoute(newRoute);
  };

  let navigate = useNavigate();

  function handleClick() {
    console.log("route change")
    navigate("/home");
  }

  return (
    <div>
      <Routes>
        <Route index element={<Home onRouteChange={handleClick} />} />
        <Route path="home" element={<Home onRouteChange={handleClick} />} />
        <Route path="selector" element={<Selector onRouteChange={handleRouteChange} />} />
        <Route path="counter" element={<Counter />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

const mapDispatchToProps = (state) => {
  return {
    UpdateRoute: (route) => dispatch(updateRoute(route))
  };
};

export default connect(mapDispatchToProps)(App);


















import React from 'react'
import { Counter } from './components/Counter'
import './App.css'

function App() {
  return (
    <div className="App">

        <Counter />

    </div>
  )
}

export default App




// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import NotFound from './components/NotFound';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;




















  return (
    <div className="App">
      <header className="App-header">
        <img
          src={`${process.env.PUBLIC_URL ?? ''}/logo.svg`}
          className="App-logo"
          alt="logo"
        />
        <Counter />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span>
      </header>
    </div>
  )





 */
