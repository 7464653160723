import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import { connect } from 'react-redux';
import ResultItem from '../common/ResultItem'
import ResultItem2 from '../common/ResultItem2'


import { useState, useEffect, useRef } from "react";

const devModeBool = false

// if (devModeBool == true) {

// }



const Glasses = (props) => {

    // Extract data from props
    ///const { data } = props;
    let renderDisplayItems = props.reducerData.data

    if (renderDisplayItems === undefined) { renderDisplayItems = [] }

    ///// console.log("renderDisplayItemsrenderDisplayItems", renderDisplayItems)














    const [activeItem, setActiveItem] = useState(null);
    const [activeItems, setActiveItems] = useState([null]);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    // useEffect(() => {


    //     const handleScrollOnPage = () => {
    //         // Update activeItem based on the current scroll position.
    //         // Implement your logic to identify the active item here.
    //         ////const currentlyActiveItem = getActiveItem();
    //         ////setActiveItem(currentlyActiveItem);
    //         ////console.log('handling scroll')
    //         const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    //        // console.log('Scroll Position:', scrollPosition);
    //     };



    //     const debounceScroll = debounce(handleScrollOnPage, 500);

    //     window.addEventListener('scroll', debounceScroll);

    //     return () => {
    //         window.removeEventListener('scroll', debounceScroll);
    //     };
    // }, []);




    // // Function to debounce the scroll event
    // const debounce = (fn, delay) => {
    //     let timer;
    //     return function (...args) {
    //         clearTimeout(timer);
    //         timer = setTimeout(() => {
    //             fn.apply(this, args);
    //         }, delay);
    //     };
    // };











    // const handleItemVisible = (itemId) => {
    //     //console.log('item visible', itemId)
    //     setActiveItem(itemId);
    //   };














    // useEffect(() => {
    //     // Helper function to handle scrolling and set the isScrolling state
    //     const handleScrolling = () => {
    //       setIsScrolling(true);
    //       clearTimeout(scrollingTimer);
    //       const scrollingTimer = setTimeout(() => {
    //         setIsScrolling(false);
    //       }, 200); // Adjust the duration as needed
    //     };

    //     // Add scroll event listener to detect scrolling
    //     window.addEventListener('scroll', handleScrolling);

    //     // Remove scroll event listener when the component is unmounted
    //     return () => {
    //       window.removeEventListener('scroll', handleScrolling);
    //       clearTimeout(scrollingTimer);
    //     };
    //   }, []);

    //   const handleItemVisible = (itemId) => {
    //     // Update the active item only when the screen is not scrolling
    //     if (!isScrolling) {
    //       setActiveItem(itemId);
    //     }
    //   };


    useEffect(() => {
        // Helper function to handle scrolling and set the isScrolling state
        let scrollingTimer; // Declare scrollingTimer variable

        const handleScrolling = () => {

            // setTimeout(() => {
            //     setIsScrolling(true);



            //}, 20); // Adjust the duration as needed

            //setIsScrolling(true);
            if (devModeBool == true) {
                console.log("scroll true")
            }


            clearTimeout(scrollingTimer);
            scrollingTimer = setTimeout(() => {
                setIsScrolling(false);
                if (devModeBool == true) {
                    console.log("scroll false")
                }

            }, 1000); // Adjust the duration as needed
        };

        // Add scroll event listener to detect scrolling
        window.addEventListener('scroll', handleScrolling);

        // Remove scroll event listener and clear the timer when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScrolling);
            clearTimeout(scrollingTimer);
        };
    }, []);




    // useEffect(() => {
    //     let scrollingTimer; // Declare scrollingTimer variable

    //     const handleScrolling = () => {

    //         console.log("scroll true")

    //         console.log("scroll true")
    //         clearTimeout(scrollingTimer);
    //         scrollingTimer = setTimeout(() => {
    //             setIsScrolling(false);
    //             console.log("scroll false")
    //         }, 2000); // Adjust the duration as needed
    //     };

    //     // Add scroll event listener to detect scrolling
    //     window.addEventListener('scroll', handleScrolling);

    //     // Remove scroll event listener and clear the timer when the component is unmounted
    //     return () => {
    //         window.removeEventListener('scroll', handleScrolling);
    //         clearTimeout(scrollingTimer);
    //     };
    // }, []);





    const handleItemVisible = (itemId) => {
        // Update the active item only when the screen is not scrolling

        if (devModeBool == true) {
            console.log('handling item visible')
        }


        if (!isScrolling) {
            setActiveItem(itemId);
            // setActiveItems([itemId-1, itemId, itemId+1])

            if (devModeBool == true) {
                console.log(itemId, activeItems)
            }




            if (devModeBool == true) {

                console.log('not scrolling')
            }

        }
        else {

            if (devModeBool == true) {
                console.log('scrolling')
            }


        }
    };





    if (devModeBool == true) {
        console.log('===============================================================', renderDisplayItems.length)
    }


    if (renderDisplayItems.length == 0) {
        return (
            <div >

            </div >
        );
    }
    else if (renderDisplayItems.length <= 200) {
        return (
            <div >



                {renderDisplayItems.map((item, index) => (
                    <ResultItem
                        key={`displayItem_${index}`}
                        id={index + 1}
                        data={item}
                        onItemVisible={handleItemVisible}
                        activeItem={activeItem}
                        //activeItems={activeItems}
                        isScrolling={isScrolling}
                    ></ResultItem>
                ))}
            </div >
        );
    } else {
        return (
            <div >



                {renderDisplayItems.map((item, index) => (
                    <ResultItem2
                        key={`displayItem_${index}`}
                        id={index + 1}
                        data={item}
                    ></ResultItem2>
                ))}
            </div >
        );
    }

};


const mapStateToProps = (state) => {
    // const displayMode = state.reducer4
    // const displayData = state.wineData
    const reducerData = {
        data: state.wineData.wineSearchResults
    }
    return {
        reducerData,
    };
};

export default connect(mapStateToProps)(Glasses);



/*












//         
// style={{display:'flex', justifyContent:'center', width:'80%'}}

//             <h1 style={{ color: 'white', display: 'flex', justifyContent: 'center' }} >SEARCH RESULTS:</h1>
//  


    return (
        <div >



            {renderDisplayItems.map((item, index) => (
                <ResultItem
                    key={`displayItem_${index}`}
                    id={index + 1}
                    data={item}
                    onItemVisible={handleItemVisible}
                    activeItem={activeItem}
                    //activeItems={activeItems}
                    isScrolling={isScrolling}
                ></ResultItem>
            ))}
        </div >
    );
};
//  <h1 key={`displayItem_${index}`} style={{color: 'white'}}>item {index}</h1>
//export default Glasses;

// <h1 style={{ color: 'white' , display: 'flex', justifyContent: 'center'}} >SEARCH RESULTS:</h1>













*/