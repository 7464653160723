import React from 'react';
import { connect } from 'react-redux';

//import Menubar from "./Menubar"
import Menubar from "../../components/layout/MenuBar"
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

// used to detect changes in navigated url
import { useNavigate } from 'react-router-dom';
// used to dispatch action 
import { useSelector, useDispatch } from 'react-redux';
// import of action
import testAction from "../../actions/actions.js"
import UpdateRoute from "../../actions/UpdateRoute.js"

import wineImg from '../../images/wineImg.jpg'


import { useState, useEffect } from "react";
import { Checkbox } from '@mui/material';




import UpdateQuery from "../../actions/UpdateQuery.js"
import UpdateResults from "../../actions/UpdateResults.js"


import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider, Button } from '@mui/material';

import Footer from "../../components/layout/Footer"

import CustomSlider from "./CustomSlider";
import ResultsList from "./ResultsList"


const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: '1rem',
            width: '100vw',

            backgroundColor: 'black',
            height: 'auto', // '69rem',
        }}
    >
        {props.children}
    </Box>
);


const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: '100%',

            backgroundColor: 'black',
        }}
    >
        {props.children}
    </Box>
);

const StyledBoxCheck = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: 'center',
            width: '100%',
        }}
    >
        {props.children}
    </Box>
);







const StyledBoxText = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: 'center',
            width: '100%',
        }}
    >
        {props.children}
    </Box>
);


// const LargeCheckBox_non_working = (props) => (
//     <Checkbox
//         sx={{
//             transform: "scale(2)",
//             color: 'rgb(171,18,57)', // color: 'defaukle', https://codesandbox.io/s/material-ui-checkbox-examples-qq447?from-embed   
//         }}
//     >
//         {props.children}
//     </Checkbox>
// );

// const LargeCheckBox2 = (props) => (
//     <Checkbox
//         sx={{
//             transform: "scale(2)",
//             color: 'rgb(171,18,57)', // color: 'defaukle', https://codesandbox.io/s/material-ui-checkbox-examples-qq447?from-embed   
//         }}
//     >
//         {props.children}
//     </Checkbox>
// );

// const LargeCheckBox2 = styled('Checkbox')({
//     color: 'rgb(20,20,20)',
// });

// THIS WAY OF STYLING WORKED FOR EVENT TRIGGERING
// EXAMPLE AT URL: https://stackoverflow.com/questions/69860893/how-to-properly-use-styled-with-mui-v5


/*


Below is a v5 version of your code with the same look as the v4 version. I added default values for the props just for demonstration purposes.

You had two main issues:

    You added additional div layers for the styling rather than styling the elements that originally received the styles (e.g. Paper, Card).

    You assigned the styled divs to variable names that start with a lowercase letter which caused them to be rendered as DOM tags rather than components (so the styling would have been completely ignored).

From https://reactjs.org/docs/components-and-props.html#rendering-a-component:

    React treats components starting with lowercase letters as DOM tags.




import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";

const StyledPaper = styled(Paper)({
  backgroundColor: "#fdfdff"
});
const HeaderDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  marginBottom: theme.spacing
}));
const StyledCard = styled(Card)(({ theme }) => ({
  display: "inline-block",
  padding: theme.spacing(2),
  color: "#3c44b1"
}));
const TitleDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  "& .MuiTypography-subtitle2": {
    opacity: "0.6"
  }
}));

export default function PageHeader(props) {
  const {
    title = "Title",
    subTitle = "sub-title",
    icon = <PersonIcon />
  } = props;
  return (
    <StyledPaper elevation={0} square>
      <HeaderDiv>

*/


const LargeCheckBox = styled(Checkbox)(({ theme }) => ({
    transform: "scale(1.5)",
    color: "rgb(171,18,57)",
    // "&checked": {
    //             color: "rgb(171,18,57)"
    //           }
    //backgroundColor: "#3c44b1"
}));

const LargeSlider = styled(Checkbox)(({ theme }) => ({
    transform: "scale(1.5)",
    color: "rgb(171,18,57)",
    // "&checked": {
    //             color: "rgb(171,18,57)"
    //           }
    //backgroundColor: "#3c44b1"
}));



const CustomColorCheckbox = (props) => (
    <Box
        sx={{
            variant: 'error', // color: 'defaukle', https://codesandbox.io/s/material-ui-checkbox-examples-qq447?from-embed
        }}
    >
        <LargeCheckBox></LargeCheckBox>

    </Box>
);




// const CustomColorCheckbox = withStyles({
//     root: {
//       color: "#13c552",
//       "&$checked": {
//         color: "#13c552"
//       }
//     },
//     checked: {}
//   })((props) => <Checkbox color="default" {...props} />);



// was Paper, now Typography

const StyledPaper = (props) => (
    <Paper
        sx={{
            backgroundColor: 'silver', color: 'black' // slategray / dimgray / gray / silver
        }}
    >
        {props.children}
    </Paper>
);































const SearchGlasses = (props) => {


    // https://www.youtube.com/watch?v=SUEkAOuQZTQ
    const theme = createTheme({
        palette: {
            primary: {
                main: 'rgb(150,18,57)',
            },
            error: {
                main: '#0044ff',
            },
        }
    })



    let navigate = useNavigate();

    const dispatch = useDispatch();

    function handleClick() {
        console.log("route change")
        dispatch(UpdateRoute("hello"))
        navigate("/selector");
    }


    const [value1, setValue1] = useState([0, 5]);
    const [value2, setValue2] = useState([0, 5]);
    const [value3, setValue3] = useState([0, 5]);
    const [value4, setValue4] = useState([0, 5]);
    const [value5, setValue5] = useState([0, 5]);
    const [value6, setValue6] = useState([0, 5]);



    // const handleChange = ({newValue, name}) => {
    //     setValue1(newValue);
    //     console.log (newValue)
    //     // update variable or perform other actions for Slider 1
    // };

    const handleChange1 = newValue => {
        setValue1(newValue);
        console.log(newValue)
        //dispatch(UpdateQuery(newValue))
        // update variable or perform other actions for Slider 1
    };

    const handleChange2 = newValue => {
        setValue2(newValue);
        // update variable or perform other actions for Slider 2
    };

    const handleChange3 = newValue => {
        setValue3(newValue);
        // update variable or perform other actions for Slider 2
    };

    const handleChange4 = newValue => {
        setValue4(newValue);
        // update variable or perform other actions for Slider 2
    };

    const handleChange5 = newValue => {
        setValue5(newValue);
        // update variable or perform other actions for Slider 2
    };

    const handleChange6 = newValue => {
        setValue6(newValue);
        // update variable or perform other actions for Slider 2
    };
    //let checkedStatus = true



    const [value, setValue] = useState({

        bubbleCheckedStatus: true,
        whiteCheckedStatus: false,
        roseCheckedStatus: false,
        redCheckedStatus: false,
        lightBold: [0, 5],
        smoothTannic: [0, 5],
        drySweet: [0, 5],
        softAcidic: [0, 5],
        gentleFizzy: [0, 5],
        price: [0, 5],

    });

    const [valueCheckBox1, setValueCheckBox1] = useState(true);
    const [valueCheckBox2, setValueCheckBox2] = useState(true);
    const [valueCheckBox3, setValueCheckBox3] = useState(true);
    const [valueCheckBox4, setValueCheckBox4] = useState(true);

    const resetSearch = () => {
        console.log('resetting')
        // setValue({
        //     bubbleCheckedStatus: false,
        //     whiteCheckedStatus: false,
        //     roseCheckedStatus: false,
        //     redCheckedStatus: false,
        //     lightBold: [0,5],
        //     smoothTannic: [0,5],
        //     drySweet: [0,5],
        //     softAcidic: [0,5],
        //     gentleFizzy: [0,5],
        //     price: [0,5],
        // });

        setValueCheckBox1(true)
        setValueCheckBox2(true)
        setValueCheckBox3(true)
        setValueCheckBox4(true)
        setValue1([0, 5]);
        setValue2([0, 5]);
        setValue3([0, 5]);
        setValue4([0, 5]);
        setValue5([0, 5]);
        setValue6([0, 5]);

    }


    // useEffect(() => {
    //     //Runs on the first render
    //     //And any time any dependency value changes
    //   }, [prop, state]);

    useEffect(() => {
        console.log("effect")
        updateReduxQueryStatus()
    }, [valueCheckBox1, valueCheckBox2, valueCheckBox3, valueCheckBox4, value1, value2, value3, value4, value5, value6]);

    const updateReduxQueryStatus = () => {

        // setTimeout(() => {
        let newQueryStatus = {
            bubbleCheckedStatus: valueCheckBox1,
            whiteCheckedStatus: valueCheckBox2,
            roseCheckedStatus: valueCheckBox3,
            redCheckedStatus: valueCheckBox4,
            lightBold: value1,
            smoothTannic: value2,
            drySweet: value3,
            softAcidic: value4,
            gentleFizzy: value5,
            price: value6,
        }

        ////dispatch(UpdateQuery(newQueryStatus))
        // }, "100");

        //dispatch(UpdateQuery(newQueryStatus))



        const handleDispatchActions = async () => {
            try {
                // Dispatch first action
                ////dispatch(actionOne());
                dispatch(UpdateQuery(newQueryStatus))
                // Wait for the first action to complete before dispatching the second action
                ////await dispatch(actionTwo());
                dispatch(UpdateResults(newQueryStatus))
                // Dispatch third action after the second action has completed
                ////dispatch(actionThree());
            } catch (error) {
                // Handle any errors that may occur during dispatching actions
                console.error('Error dispatching actions:', error);
            }
        };

        //console.log("----------------------------mapped state: ", props.reducerData.queryResult.length)
        handleDispatchActions()





    }

    // https://stackoverflow.com/questions/61812978/updating-a-state-immediately-using-react-hooks
    // https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately
    const updateReduxQueryStatus2 = (name, status) => {

        if (name == "valueCheckBox1") {
            //setValueCheckBox1(status)
            useEffect(() => { setValueCheckBox1(checkedStatusReverse) }, [])
            updateReduxQueryStatus()
        }

    }

    /* 
    
                            <Typography align="center" fontSize='1.5rem' backgroundColor='silver' color='black' fontWeight='bold' >
                                Result: {props.reducerData.queryResult.length} choices available
                            </Typography>

                                                    <Typography align="center" fontSize='1.3rem' backgroundColor='black' color='rgb(171,18,57)' >
                            available options
                        </Typography>
    
    */




    return (
        <div >
            <ThemeProvider theme={theme}>
                <StyledBox>
                    <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                            <Menubar ></Menubar>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} >
                            <Card>
                                <Paper
                                    style={{
                                        margin: '0rem',
                                        padding: '10%',
                                        border: '0rem',
                                        backgroundColor: 'black',
                                        backgroundImage: `url(${wineImg})`,
                                        repeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        width: "80%",
                                        height: "auto",
                                    }} square
                                />
                            </Card>
                            <Typography align="center" color='white' fontSize='1.5rem' >SEARCH WINE GLASSES</Typography>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} >
                            <Typography align="center" fontSize='2.2rem' backgroundColor='rgb(150,18,57)' color='black' fontWeight='bold' >
                                {props.reducerData.queryResult.length}
                            </Typography>
                            <Typography align="center" fontSize='1.1rem' backgroundColor='black' color='rgb(171,18,57)' >
                                AVAILABLE MATCHES
                            </Typography>

                        </Grid>


                        <Grid item xs={3} sm={3}>
                            <StyledPaper>
                                <StyledBoxCheck>
                                    <Typography fontWeight='bold' fontSize='1.1rem'>Bubbles</Typography>

                                    <LargeCheckBox
                                        //checked={value.bubbleCheckedStatus}
                                        checked={valueCheckBox1}

                                        onChange={(e) => {
                                            //console.log("setting value", e, e.target.value)
                                            let checkedStatusReverse = valueCheckBox1
                                            ////console.log("checkedStatus", valueCheckBox1, checkedStatusReverse)

                                            if (valueCheckBox1 == true) {
                                                checkedStatusReverse = false
                                            } else {
                                                checkedStatusReverse = true
                                            }
                                            ////console.log("value", value)
                                            ////console.log("checkedStatus", valueCheckBox1, checkedStatusReverse)
                                            //useEffect(() => { setValueCheckBox1(checkedStatusReverse) }, [])

                                            setValueCheckBox1(checkedStatusReverse)
                                            //updateReduxQueryStatus()
                                            //updateReduxQueryStatus2("valueCheckBox1", checkedStatusReverse)

                                            // setValue({
                                            //     bubbleCheckedStatus: checkedStatusReverse,
                                            //     whiteCheckedStatus: value.whiteCheckedStatus,
                                            //     roseCheckedStatus: value.roseCheckedStatus,
                                            //     redCheckedStatus: value.redCheckedStatus,
                                            //     lightBold: value.lightBold,
                                            //     smoothTannic: value.smoothTannic,
                                            //     drySweet: value.drySweet,
                                            //     softAcidic: value.softAcidic,
                                            //     gentleFizzy: value.gentleFizzy,
                                            //     price: value.price,
                                            // });
                                        }}
                                    ></LargeCheckBox>

                                </StyledBoxCheck>
                            </StyledPaper>
                        </Grid>



                        <Grid item xs={3} sm={3}>
                            <StyledPaper >
                                <StyledBoxCheck >
                                    <Typography fontWeight='bold' fontSize='1.1rem'>White</Typography>
                                    <LargeCheckBox
                                        checked={valueCheckBox2}

                                        onChange={(e) => {
                                            let checkedStatusReverse = valueCheckBox2
                                            ////console.log("checkedStatus", valueCheckBox2, checkedStatusReverse)

                                            if (valueCheckBox2 == true) { checkedStatusReverse = false }
                                            else { checkedStatusReverse = true }
                                            ////console.log("value", value)
                                            ////console.log("checkedStatus", valueCheckBox2, checkedStatusReverse)
                                            setValueCheckBox2(checkedStatusReverse)
                                            //updateReduxQueryStatus()
                                        }}
                                    >
                                    </LargeCheckBox>
                                </StyledBoxCheck>
                            </StyledPaper>
                        </Grid>



                        <Grid item xs={3} sm={3}>
                            <StyledPaper>
                                <StyledBoxCheck>
                                    <Typography fontWeight='bold' fontSize='1.1rem'>Rosé</Typography>
                                    <LargeCheckBox
                                        checked={valueCheckBox3}

                                        onChange={(e) => {
                                            let checkedStatusReverse = valueCheckBox3
                                            if (valueCheckBox3 == true) { checkedStatusReverse = false }
                                            else { checkedStatusReverse = true }
                                            setValueCheckBox3(checkedStatusReverse)
                                            //updateReduxQueryStatus()
                                        }}
                                    ></LargeCheckBox>
                                </StyledBoxCheck>
                            </StyledPaper>
                        </Grid>


                        <Grid item xs={3} sm={3}>
                            <StyledPaper>
                                <StyledBoxCheck>
                                    <Typography fontWeight='bold' fontSize='1.1rem'>Red</Typography>
                                    <LargeCheckBox
                                        checked={valueCheckBox4}

                                        onChange={(e) => {
                                            let checkedStatusReverse = valueCheckBox4
                                            if (valueCheckBox4 == true) { checkedStatusReverse = false }
                                            else { checkedStatusReverse = true }
                                            setValueCheckBox4(checkedStatusReverse)
                                            //updateReduxQueryStatus()
                                        }}></LargeCheckBox>
                                </StyledBoxCheck>
                            </StyledPaper>
                        </Grid>





















                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>Light</Typography></StyledBoxText></StyledPaper>
                        </Grid>


                        <Grid item xs={5} sm={5} md={5} >
                            <CustomSlider
                                value={value1}
                                onChange={handleChange1}
                                min={0}
                                max={5}
                                step={1}
                                marks={true}

                            // onChange={(e) => {
                            //     console.log("setting value", e)
                            //     handleChange1()
                            // }}
                            >

                            </CustomSlider>
                        </Grid>

                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>Bold</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>{value1[0]} - {value1[1]} </Typography></StyledBoxText></StyledPaper>
                        </Grid>








                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1rem'>Smooth</Typography></StyledBoxText></StyledPaper>
                        </Grid>


                        <Grid item xs={5} sm={5} md={5} >
                            <Box
                            >
                                <CustomSlider
                                    value={value2}
                                    onChange={handleChange2}
                                    min={0}
                                    max={5}
                                    step={1}
                                    marks={true}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1rem'>Tannic</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1rem'>{value2[0]} - {value2[1]} </Typography></StyledBoxText></StyledPaper>
                        </Grid>





                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>Dry</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} >
                            <CustomSlider
                                value={value3}
                                onChange={handleChange3}
                                min={0}
                                max={5}
                                step={1}
                                marks={true}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>Sweet</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1rem'>{value3[0]} - {value3[1]} </Typography></StyledBoxText></StyledPaper>
                        </Grid>





                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>Soft</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} >
                            <CustomSlider
                                value={value4}
                                onChange={handleChange4}
                                min={0}
                                max={5}
                                step={1}
                                marks={true}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>Acidic</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1rem'>{value4[0]} - {value4[1]} </Typography></StyledBoxText></StyledPaper>
                        </Grid>


                        {/* 
                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>Gentle</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} >
                            <CustomSlider
                                value={value5}
                                onChange={handleChange5}
                                min={0}
                                max={5}
                                step={1}
                                marks={true}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>Fizzy</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1rem'>value</Typography></StyledBoxText></StyledPaper>
                        </Grid> */}






                        {/* <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>Price $</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} >
                            <CustomSlider
                                value={value6}
                                onChange={handleChange6}
                                min={0}
                                max={5}
                                step={1}
                                marks={true}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1.1rem'>$ $ $</Typography></StyledBoxText></StyledPaper>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <StyledPaper><StyledBoxText><Typography fontWeight='bold' fontSize='1rem'>{value6[0]} - {value6[1]} </Typography></StyledBoxText></StyledPaper>
                        </Grid> */}







                        <StyledBox2>
                            <Grid item xs={6} sm={4} md={4} onClick={resetSearch} align="center">
                                <Typography align="center" fontSize='2rem' backgroundColor='silver' color='blue' fontWeight='bold' width='90%' >
                                    <Button>Reset Search</Button>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} align="center">
                                <Typography align="center" fontSize='2rem' backgroundColor='silver' color='blue' fontWeight='bold' width='90%' >
                                    <Button >View Instructions</Button>
                                </Typography>
                            </Grid>
                        </StyledBox2>

                        {/* <Grid item xs={12} sm={12} md={12} >
                        <Typography align="center" variant="h5" backgroundColor='silver' color='black' fontWeight='bold' >
                            MATCHES: {props.reducerData.queryResult.length}
                        </Typography>

                    </Grid> */}



                    </Grid>
                </StyledBox>
            </ThemeProvider>

            <ResultsList></ResultsList>
            <Footer></Footer>



        </div >
    );
};



/* 

                69 bubble-6 rose-6 white-9 red-13 glasses-34
                        <Typography align="center" variant="h5" backgroundColor='silver' color='black' fontWeight='bold' >
                            flights-8 bottles-137
                        </Typography>


*/



const mapStateToProps = (state) => {
    const reducerData = state.reducer2
    //console.log("reducerData ", reducerData)
    return {
        reducerData,
    };
};


// function mapStateToProps(state) {
//     return {
//         stete1: state,
//     }
// }

export default connect(mapStateToProps)(SearchGlasses);

// DRY/SWEET SOFT/ACIDIC GENTLE/FIZZY



/*


                            <LargeCheckBox
                                checked={checkedStatus}
                                onChange={(e) => {
                                    console.log("clicked")
                                    if (checkedStatus == true) {
                                        checkedStatus = false
                                    }
                                }}></LargeCheckBox>



*/