
import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import CategoryHeading from "./CategoryHeading"


const devModeBool = false

// if (devModeBool == true) {

// }



const FoodCategory = (props) => {

    // Extract data from props
    //const { data } = props;

    const { stillLoading } = props;
    const { activeMenu } = props;
    const { foodMenuData } = props;


    if (devModeBool == true) {
        console.log("FoodCategory", stillLoading, activeMenu, foodMenuData)
    }

    

    let foodDataObject = null
    let foodCategorySequenceArray = []
    let activeMenuMatchedCase = null
    let selectedMenuCategoryObject = null
    let activeMenuSequenceArray = []

    if (stillLoading == false) {

        foodDataObject = foodMenuData.foodDataObject
        foodCategorySequenceArray = foodMenuData.foodCategorySequence

        const searchKey = activeMenu;
        const lowercaseSearchKey = searchKey.toLowerCase();


        for (const key in foodDataObject) {

            const lowercaseKey = key.toLowerCase();
            if (lowercaseKey === lowercaseSearchKey) {

                if (devModeBool == true) {
                    console.log('Key found:', key, searchKey);
                    console.log('Value:', foodDataObject[key]);
                }


                activeMenuMatchedCase = key
                break;

            }

        }

        selectedMenuCategoryObject = foodDataObject[activeMenuMatchedCase]

        foodCategorySequenceArray.map((item, index) => {

            if (devModeBool == true) {
                console.log("foodCategorySequenceArray ", item)
            }




            if (selectedMenuCategoryObject.hasOwnProperty(item)) {

                if (devModeBool == true) {
                    console.log("hasOwnProperty")
                }


                activeMenuSequenceArray.push(item)
            } else {

                if (devModeBool == true) {
                    console.log("NOT hasOwnProperty")
                }



            }

        })

    }


    if (devModeBool == true) {
        console.log("activeMenuSequenceArray", activeMenuSequenceArray)
    }







    if (stillLoading == true) {

        return (
            <div></div>
        )

    } else {

        return (
            <div>
                {activeMenuSequenceArray.map((item, index) => (

                    <div key={index}>

                        <CategoryHeading item={selectedMenuCategoryObject[item]} index={index}  ></CategoryHeading>

                    </div>

                ))}

            </div >
        );

    }


};

export default FoodCategory;


// im going to get back to coding now
//lsdvnalsvnkdjfdfnL,mvnlkdjnv:Sflkfbn;KLvnslf ;kVNS'DLVNaLDN,CNslnsmn


/*


    <h1 style={{ color: 'white' }}  >{item}</h1>
    <h1 style={{ color: 'white' }}  >{item}</h1>




                {foodCategorySequenceArray.map((item) => (
                    <h1>category</h1>
                ))}

*/

