import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';

const GLOBAL_OPACITY = '90%'

const SortByPanel = (props) => {

    // Extract data from props
    const { activeMenu } = props
    const { setSortByFunction } = props


   //// console.log("SORTBYPANEL activeMenu", activeMenu)

    function setSortByPanelFunction(argument) {
        setSortByFunction(argument);
    }


    if (activeMenu.toLowerCase() == 'flights') {
        return (<div></div>)
    } else {
        return (


            <div>

                <Typography align="center" fontSize='1rem' backgroundColor='black' color='rgb(171,18,57)'
                    style={{ opacity: GLOBAL_OPACITY , padding: '0px', margin: '0px', width: '100vw'  }}
                >
                    SORT BY:
                </Typography>


                <Grid item xs={12} sm={12} md={12} style={{
                    padding: '5px',
                    paddingTop: '0px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                }}>
                    <Button style={{ opacity: GLOBAL_OPACITY , padding: '0px', margin: '0px', width: '30vw', backgroundColor: 'gray' }}
                        onClick={() => {
                            setSortByPanelFunction('type')
                        }}
                    >TYPE</Button>

                    <Button style={{ opacity: GLOBAL_OPACITY , padding: '0px', margin: '0px', width: '30vw', backgroundColor: 'gray' }}
                        onClick={() => {
                            setSortByPanelFunction('grape')
                        }}
                    >GRAPE</Button>
                    <Button style={{ opacity: GLOBAL_OPACITY , padding: '0px', margin: '0px', width: '30vw', backgroundColor: 'gray' }}
                        onClick={() => {
                            setSortByPanelFunction('region')
                        }}
                    >REGION</Button>

                </Grid>

            </div >
        );


    }

};

export default SortByPanel;

