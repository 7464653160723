
const queryObjectArray = []

const displaySection = 'dinner'

// {
//     section: 'default',
//     subsection: "default",
// }


export default function exportedFunction(state = {
    display: displaySection,
    queryObjectArray: queryObjectArray,
}, action) {

    // need to determine how to get thunk to do this
    // actually dont need to do this, instead the async fetch function using redux thunk does not involve redux
    // thunk just enables the ability to run an async function prior to running the dispatch
    // if (action.type === 'UPDATE_MENU_QUERY') {

    //     return {
    //         query: state.query,
    //         queryResult: state.queryResult,
    //         queryObjectArray: state.queryObjectArray
    //     };
    // }

    if (action.type === 'UPDATE_MENU_RESULTS') {
        /////console.log('action.payload: ', action.payload )
        return {
            display: action.payload.display,
            queryObjectArray: action.payload.items,
        };
    }


    return state;

}