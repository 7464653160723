import React from "react";
// import Slider from "@material-ui/core/Slider"; // OLD VERSION OF MUI
import { Slider } from "@mui/material";

const CustomSlider = ({ value, onChange, min, max, step, marks }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Slider
    size="medium" // or small
      value={value}
      onChange={handleChange}
      min={min}
      max={max}
      step={step}
      marks={marks}
      valueLabelDisplay="auto"
      getAriaValueText={value => `${value}`}
      color= 'primary'
    />
  );
};

export default CustomSlider;