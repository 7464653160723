import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import ModalComponent from './ModalComponentActive'


// import testImg from "../../../images/test/test.jpg"
//import testImg from "../../../images/test/logo.png"




import CONFIG from '../../../config/config.js'
const WINE_IMAGE_SERVER_IP = CONFIG.WINE_IMAGE_SERVER_IP
const WINE_IMAGE_SERVER_URL = CONFIG.WINE_IMAGE_SERVER_URL


import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from "react";
import ResultItemPrice from './ResultItemPrice'

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            padding: '0.5rem',
            gap: '1rem',
            width: 'auto',
            backgroundColor: 'black',
            width: '85%',
            padding: '5px',
            //opacity: "85%",



        }}
    >
        {props.children}
    </Box>
);




const StyledBox3 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "center",
            width: '100%',
            backgroundColor: 'gray',
        }}
    >
        {props.children}
    </Box>
);





// import { useRef } from 'react';
// const childRef = useRef(null);
// function handleParentClick() {
//     // Call a function in the child component
//     childRef.current.childFunction();
//   }

// let buttonOpacity = '0%'

// buttonOpacity={buttonOpacity}
// ref={childRef}




const ResultItem = (props) => {
    // Extract data from props
    const { data } = props;
    const { id } = props;

    /////console.log("data: ", data)

    const { onItemVisible } = props
    const { activeItem } = props
    const { activeItems } = props
    const { isScrolling } = props

    const itemRef = useRef(null);


    //let GLOBAL_OPACITY = '100%'

    const [GLOBAL_OPACITY, setGLOBAL_OPACITY] = useState('100%');
    //console.log('id', id, 'activeItem', activeItem)



    const boxElement1Ref = useRef(null);
    const boxElement2Ref = useRef(null);
    const boxElement3Ref = useRef(null);
    const boxElement4Ref = useRef(null);



    // const [isActive, setIsActive] = useState(false);



    // useEffect(() => {
    //     // Compare the active item id and the element id
    //     //const isActiveElement = id === activeItem;
    //     setIsActive(true);
    // }, []);




    let visStatus
    let visStatus2 = 'hidden'












    useEffect(() => {

        if (id == activeItem) {
            setGLOBAL_OPACITY('60%')

            function setOpacityZero() {
                if (id == activeItem) {
                    setGLOBAL_OPACITY('0%')
                } else {
                    setGLOBAL_OPACITY('100%')
                }
            }
            setTimeout(setOpacityZero, 1800);
            //setTimeout(setOpacityZero('40%'), 3800);

            function setOpacityReturn() {
                if (id == activeItem) {
                    setGLOBAL_OPACITY('60%')
                } else {
                    setGLOBAL_OPACITY('100%')
                }
            }
            setTimeout(setOpacityReturn, 4000);




            visStatus = 'visible'
            if (isScrolling == true) {
                visStatus = 'hidden'

                console.log()
            }

        } else {
            visStatus = 'visible'
            setGLOBAL_OPACITY('100%')
        }

    }, [id, activeItem]);






































    /*
        if (id == activeItem) {
            setGLOBAL_OPACITY('60%')
    
           // GLOBAL_OPACITY = '60%'
    
            ///////setIsActive(true)
            //visStatus2 = 'visible'
    
    
            // function setOpacityZero() {
            //     GLOBAL_OPACITY = '0%'
            // }
    
            // setTimeout(setOpacityZero, 2000);
    
    
    
            // if (boxElement1Ref.current != null && boxElement2Ref.current != null && boxElement3Ref.current != null && boxElement4Ref.current != null) {
            //     setTimeout(() => {
            //         boxElement1Ref.current.style.visibility = 'visible';
            //         boxElement2Ref.current.style.visibility = 'visible';
            //         boxElement3Ref.current.style.visibility = 'visible';
            //         boxElement4Ref.current.style.visibility = 'visible';
    
            //         // boxElement1Ref.current.style.visibility = 'hidden';
            //         // boxElement2Ref.current.style.visibility = 'hidden';
            //         // boxElement3Ref.current.style.visibility = 'hidden';
            //         // boxElement4Ref.current.style.visibility = 'hidden';
    
            //         boxElement1Ref.current.style.opacity = '90%';
            //         boxElement2Ref.current.style.opacity = '90%';
            //         boxElement3Ref.current.style.opacity = '90%';
            //         boxElement4Ref.current.style.opacity = '90%';
    
            //         boxElement1Ref.current.style.transition = 'opacity 0.8s ease-in-out';
            //         boxElement2Ref.current.style.transition = 'opacity 0.8s ease-in-out';
            //         boxElement3Ref.current.style.transition = 'opacity 0.8s ease-in-out';
            //         boxElement4Ref.current.style.transition = 'opacity 0.8s ease-in-out';
    
            //         //setIsActive(true)
    
            //     }, 300);
    
            // }
    
    
            visStatus = 'visible'
            if (isScrolling == true) {
                visStatus = 'hidden'
    
                console.log()
            }
    
        } else {
            visStatus = 'visible'
            // //console.log("boxElement2Ref.current", boxElement2Ref.current)
            // if (boxElement1Ref.current != null && boxElement2Ref.current != null && boxElement3Ref.current != null && boxElement4Ref.current != null) {
            //     // && boxElement2Ref.current && boxElement3Ref.current && boxElement4Ref.current
            //     boxElement1Ref.current.style.visibility = 'hidden';
            //     boxElement2Ref.current.style.visibility = 'hidden';
            //     boxElement3Ref.current.style.visibility = 'hidden';
            //     boxElement4Ref.current.style.visibility = 'hidden';
    
            //     boxElement1Ref.current.style.opacity = '0%';
            //     boxElement2Ref.current.style.opacity = '0%';
            //     boxElement3Ref.current.style.opacity = '0%';
            //     boxElement4Ref.current.style.opacity = '0%';
            //     //console.log("boxElement2Ref.current", boxElement2Ref.current)
            // }
            // // boxElement1Ref.current.style.visibility = 'hidden';
            // // boxElement2Ref.current.style.visibility = 'hidden';
            // // boxElement3Ref.current.style.visibility = 'hidden';
            // // boxElement4Ref.current.style.visibility = 'hidden';
    
            // //setIsActive(false)
        }
    
    
    
    */











    // activeItems.map((currentItem) => {
    //     if (id == currentItem) {
    //         GLOBAL_OPACITY = '70%'
    //     }
    // })






    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //       (entries) => {
    //         entries.forEach((entry) => {
    //           if (entry.isIntersecting) {
    //             // If the item is visible in the viewport, notify the parent component.
    //             onItemVisible(id);
    //           }
    //         });
    //       },
    //       { threshold: 0.6 } // Adjust the threshold as per your needs.
    //     );

    //     if (itemRef.current) {
    //       observer.observe(itemRef.current);
    //     }

    //     return () => {
    //       if (itemRef.current) {
    //         observer.unobserve(itemRef.current);
    //       }
    //     };
    //   }, [id, onItemVisible]);



    const debounce = (fn, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(this, args);
            }, delay);
        };
    };






    useEffect(() => {
        const debounceOnItemVisible = debounce(onItemVisible, 500); // Adjust the debounce delay as needed

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (isScrolling == false) {
                            // Use the debounced version of onItemVisible
                            debounceOnItemVisible(id);
                        }

                    }
                });
            },
            { threshold: 0.99 } // Adjust the threshold as per your needs.
        );

        if (itemRef.current) {
            observer.observe(itemRef.current);
        }

        return () => {
            if (itemRef.current) {
                observer.unobserve(itemRef.current);
            }
        };
    }, [id, onItemVisible]);








    // const StyledBox2 = (props) => (
    //     <Box
    //         sx={{
    //             display: 'flex',
    //             flexDirection: 'row',
    //             flexWrap: "no-wrap",
    //             //backgroundColor: 'gray',
    //             justifyContent: "space-between",
    //             paddingBottom: '0.5rem',
    //             paddingLeft: '15px',
    //             paddingRight: '15px',
    //             opacity: GLOBAL_OPACITY,
    //             transition: 'opacity 0.8s',

    //         }}
    //     >
    //         {props.children}
    //     </Box>
    // );


    //   // Render data using map
    //   const renderedList = data.map((item) => (
    //     // JSX markup for rendering each item in the list
    //     <div key={item.id}>
    //       {/* Render item data */}
    //       <p>{item.name}</p>
    //       {/* ... */}
    //     </div>
    //   ));





    // backgroundSize: '100% 100%',
    //sx={{ backgroundImage: `url(${testImg})`, 

    const imgURL = WINE_IMAGE_SERVER_IP + WINE_IMAGE_SERVER_URL + 'label_' + data.image



    const childRef = useRef(null);
    function handleParentClick() {
        // Call a function in the child component
        childRef.current.childFunction();
    }

    let buttonOpacity = '100%'

    return (


        <div ref={itemRef} onClick={handleParentClick}
            style={{
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '2rem',
                paddingBottom: '1rem'
            }} >

            <Box backgroundColor='black'

                sx={{

                    opacity: '100%',
                    width: '23rem',
                    minWidth: '40%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0px',
                    margin: '0px',
                    border: '0px',

                }} >

                <StyledBox >

                    <Paper sx={{
                        backgroundImage: `url(${imgURL})`,
                        backgroundSize: 'auto',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%',
                        backgroundColor: '#696969',
                        width: '100%',
                        minHeight: '23rem',
                    }}>













                        <Box visibility={visStatus}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "no-wrap",
                                justifyContent: "center",
                                width: '100%',
                                backgroundColor: 'gray',
                                opacity: '100%',
                                // transition: 'opacity 0.3s',

                            }}
                        >

                            <Grid item xs={6} sm={6} md={6} >
                                <Typography
                                    align="center"
                                    fontSize='1.3rem'
                                    fontWeight='bold'
                                    // backgroundColor='gray'
                                    color='black'
                                    margin='0.2rem'

                                >
                                    {data.name}
                                </Typography>
                            </Grid>
                        </Box>



















                        <Box visibility={visStatus}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "no-wrap",
                                //backgroundColor: 'gray',
                                justifyContent: "space-between",
                                paddingBottom: '0.5rem',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                opacity: GLOBAL_OPACITY,
                                transition: 'opacity 1.2s',

                            }}>
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'


                                >
                                    Type: {data.type}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%'>
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'
                                >
                                    Match # {id}
                                </Typography>

                            </Grid>
                        </Box>


                        <Box visibility={visStatus}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "no-wrap",
                                //backgroundColor: 'gray',
                                justifyContent: "space-between",
                                paddingBottom: '0.5rem',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                opacity: GLOBAL_OPACITY,
                                transition: 'opacity 1.2s',

                            }}>
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >

                                    Grape: {data.grape}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >
                                    Region: {data.region}
                                </Typography>

                            </Grid>
                        </Box>








                        <div style={{ height: '10rem' }}></div>



                        {/* 
                        <div >
                            <Box

                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: "no-wrap",
                                    //backgroundColor: 'gray',
                                    justifyContent: "space-between",
                                    paddingBottom: '0.5rem',
                                    paddingLeft: '15px',
                                    paddingRight: '15px',
                                    opacity: GLOBAL_OPACITY,
                                    transition: 'opacity 0.8s',

                                }}>
                                <Grid item xs={3} sm={3} md={3}

                                    width='45%'
                                    backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem' ref={boxElement1Ref}

                                >
                                    <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                        Body: Light - Full
                                    </Typography>
                                    <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%'  >
                                        {data.body_light_full}
                                    </Typography>
                                </Grid>

                                <Grid item xs={3} sm={3} md={3}

                                    width='45%'
                                    backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem' ref={boxElement2Ref}

                                >
                                    <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                        Tannin: Low - High
                                    </Typography>
                                    <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                        {data.tannin_low_high}
                                    </Typography>
                                </Grid>

                            </Box>





                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: "no-wrap",
                                    //backgroundColor: 'gray',
                                    justifyContent: "space-between",
                                    paddingBottom: '0.5rem',
                                    paddingLeft: '15px',
                                    paddingRight: '15px',
                                    opacity: GLOBAL_OPACITY,
                                    transition: 'opacity 0.8s',

                                }}>

                                <Grid item xs={3} sm={3} md={3}

                                    width='45%'
                                    backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem' ref={boxElement3Ref}

                                >

                                    <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                        Dry - Sweet
                                    </Typography>
                                    <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                        {data.dry_sweet}
                                    </Typography>
                                </Grid>

                                <Grid item xs={3} sm={3} md={3}

                                    width='45%'
                                    backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem' ref={boxElement4Ref}

                                >

                                    <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                        Acid: Low - High
                                    </Typography>
                                    <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                        {data.acid_low_high}
                                    </Typography>
                                </Grid>

                            </Box>



                        </div>
 */}














                        <Box visibility={visStatus}>
                            <ResultItemPrice data={data} elementOpacity={GLOBAL_OPACITY} ></ResultItemPrice>
                        </Box>
















                        <Box visibility={visStatus}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "no-wrap",
                                //backgroundColor: 'gray',
                                justifyContent: "space-between",
                                paddingBottom: '0.5rem',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                opacity: '100%',
                                transition: 'opacity 1.8s',

                            }}>
                            <ModalComponent
                                style={{ paddingLeft: '0rem', color: 'blue' }}
                                render={true}
                                data={data}
                                buttonOpacity={'60%'}
                                ref={childRef}
                                isActive={id === activeItem}
                            />
                        </Box>
















                    </Paper>

                </StyledBox>

            </Box>

        </div >



    );
};

export default ResultItem;











/*

















































































        <div onClick={handleParentClick} style={{ width: '90%', display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem' }} >
            <Box backgroundColor='black' sx={{ opacity: GLOBAL_OPACITY, width: '25rem', minWidth: '40%', display: 'flex', justifyContent: 'center' }} >
                <StyledBox >

                    <Paper sx={{

                        backgroundImage: `url(${imgURL})`,
                        backgroundSize: 'auto',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%',
                        backgroundColor: '#696969',
                        width: '100%',


                    }}>

                        <StyledBox3>

                            <Grid item xs={6} sm={6} md={6} >
                                <Typography
                                    align="center"
                                    fontSize='1.3rem'
                                    fontWeight='bold'
                                    // backgroundColor='gray'
                                    color='black'
                                    margin='0.2rem'
                                >
                                    {data.name}
                                </Typography>
                            </Grid>
                        </StyledBox3>



                        <StyledBox2  >
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'

                                >
                                    Type: {data.type}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%'>
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'
                                >
                                    Match # {id}
                                </Typography>

                            </Grid>
                        </StyledBox2>
                        <StyledBox2 >
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >

                                    Grape: {data.grape}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >
                                    Region: {data.region}
                                </Typography>

                            </Grid>
                        </StyledBox2>


                        <StyledBox2  >
                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >
                                <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                    Body: Light - Full
                                </Typography>
                                <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%'  >
                                    {data.body_light_full}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >
                                <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                    Tannin: Low - High
                                </Typography>
                                <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.tannin_low_high}
                                </Typography>
                            </Grid>

                        </StyledBox2>

                        <StyledBox2  >

                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >

                                <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                    Dry - Sweet
                                </Typography>
                                <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.dry_sweet}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >

                                <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                    Acid: Low - High
                                </Typography>
                                <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.acid_low_high}
                                </Typography>
                            </Grid>

                        </StyledBox2>







                        <StyledBox2 display='flex' justifyContent='center' >
                            <Box backgroundColor='gray' display='flex' justifyContent='space-evenly' width='100%' padding='0.2rem' >
                                <Box display='flex' flexDirection='column' >
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        Glass 5oz
                                    </Typography>
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        {data.price_glass_5_oz}
                                    </Typography>
                                </Box>

                                <Box display='flex' flexDirection='column' >
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        Glass 8oz
                                    </Typography>
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        {data.price_glass_8_oz}
                                    </Typography>
                                </Box>

                                <Box display='flex' flexDirection='column' >

                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        Bottle
                                    </Typography>
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        {data.price_bottle}
                                    </Typography>
                                </Box>

                            </Box>
                        </StyledBox2>



                        <ResultItemPrice data={data} ></ResultItemPrice>



                        <StyledBox2>
                            <ModalComponent
                                style={{ paddingLeft: '0rem', color: 'blue' }}
                                render={true}
                                data={data}
                                buttonOpacity={buttonOpacity}
                                ref={childRef}
                            />
                        </StyledBox2>

                    </Paper>


                </StyledBox>



            </Box>

        </div >












*/