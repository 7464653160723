import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';
import MenuBar from "../../components/layout/MenuBar"

import Footer from "../../components/layout/Footer"
import MenuDisplay from './MenuDisplay'

import { UpdateMenu } from "../../actions/UpdateMenu"
import { useDispatch } from 'react-redux';

import { connect } from 'react-redux';
import { useState, useEffect } from "react";

import { createTheme, ThemeProvider, } from '@mui/material';

import foodImg from '../../images/foodImg.jpg'








import QueryFoodDataSuccess from "../../actions/QueryFoodDataSuccess"

import FoodCategory from './FoodCategory'






import CONFIG from '../../config/config.js'
const FOOD_DATA_SERVER_IP = CONFIG.FOOD_DATA_SERVER_IP
const FOOD_DATA_SERVER_URL = CONFIG.FOOD_DATA_SERVER_URL




const devModeBool = false

// if (devModeBool == true) {

// }



const DivWrapper = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: '1rem',
            width: '100%',

            backgroundColor: 'black',
            // height: 'auto', // '69rem',



            // display: 'flex',
            // flexDirection: 'column',
            // gap: '2rem',
            // width: '100%',
            // backgroundColor: 'black',
        }}
    >
        {props.children}
    </Box>
);



const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-around",//"space-between",
            width: 'auto',
            backgroundColor: 'black',
            padding: '5px',
        }}
    >
        {props.children}
    </Box>
);

const StyledButton = (props) => (
    <Button
        sx={{
            fontSize: '1rem',
            fullWidth: "true",
            size: "large",
            padding: '5px',
        }}
    >
        {props.children}
    </Button>
);

const StyledTypography = (props) => (
    <Typography
        sx={{
            width: "100%",
            fontSize: '2rem',
            padding: '0px',
            backgroundColor: 'silver',
            width: 'auto',
        }}
    >
        {props.children}
    </Typography>
);








const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '1rem',
            width: '100%',
            backgroundColor: 'black',
            justifyContent: 'center',
            //height: '50rem',   //height: '69rem',
        }}
    >
        {props.children}
    </Box>
);































const FoodMenu = (props) => {





    const theme = createTheme({
        palette: {
            primary: {
                main: 'rgb(150,18,57)',
            },
            error: {
                main: '#0044ff',
            },
        }
    })







    // Extract data from props
    const { data } = props;


    if (devModeBool == true) {
        console.log("PROPS DATA: ", props)
    }




    const displayHeading = props.reducerData.display
    const displayItems = props.reducerData.queryObjectArray


    if (devModeBool == true) {
        console.log("PROPS displayHeading: ", displayHeading)
    }




    const foodMenuData = props.foodMenu



    const [activeMenu, setActiveMenu] = useState("DINNER");

    const setMenuFunction = (newValue) => {
        setActiveMenu(newValue);

        if (devModeBool == true) {
            console.log("activeMenu: ", activeMenu)
        }

    };

    //console.log("activeMenu: ", activeMenu)







    const dispatch = useDispatch();

    const DispatchUpdateMenu = () => {
        try {
            dispatch(UpdateMenu(activeMenu))
        } catch (error) {

            if (devModeBool == true) {
                console.error('Error dispatching actions:', error);
            }


        }
    };

    useEffect(() => {

        if (devModeBool == true) {
            console.log("effect")
        }


        DispatchUpdateMenu()
    }, [activeMenu]);















    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {

            if (devModeBool == true) {
                console.log("FETCH_FUNCTION")
            }



            let fetchEndpointURL = FOOD_DATA_SERVER_IP + FOOD_DATA_SERVER_URL

            //let fetchEndpointURL = 'http://192.168.3.18:8980/api/dataserver' 

            //'http://localhost:8880/data' 
            //'http://192.168.3.13:8880/data' 
            //'https://your-api-endpoint/data' 

            try {
                // Make the network request to query for database data
                const response = await fetch(fetchEndpointURL);
                const data = await response.json();

                if (devModeBool == true) {
                    console.log('FETCH_DATA SUCCESS ----- ', data)
                }



                // Dispatch an action with the retrieved data

                //dispatch({ type: 'QUERY_WINE_DATA_SUCCESS', payload: data });
                await dispatch(QueryFoodDataSuccess(data));
                setLoading(false);

            } catch (error) {
                // Handle any errors that occur during the request

                if (devModeBool == true) {
                    console.error('Error:', error);
                    console.log('FETCH_DATA ----- ERROR')
                }



            }
        };
        fetchData();
    }, []);





















    return (
        <div>
            <ThemeProvider theme={theme}>
                <DivWrapper>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MenuBar ></MenuBar>
                        </Grid>



                        {/* 
                    <Grid item xs={12} sm={6} md={6}>
                        <Card>
                            <Paper
                                style={{
                                    margin: '0rem',
                                    border: '0rem',
                                    width: '100%',
                                    height: '100%',
                                }}
                                square
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${foodImg})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%',
                                        width: '100%',
                                        minHeight: '10vh',
                                    }}
                                ></div>
                            </Paper>
                        </Card>
                    </Grid> */}





                        <StyledBox2>
                            <Grid item xs={12} sm={8} md={8}  >
                                <Card >
                                    <Paper
                                        style={{
                                            margin: '0rem',
                                            padding: '10%',
                                            border: '0rem',
                                            backgroundColor: 'black',
                                            backgroundImage: `url(${foodImg})`,
                                            repeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            width: "80%",
                                            height: "auto",
                                        }} square
                                    />
                                </Card>
                            </Grid>
                        </StyledBox2>


                    </Grid>




                    <Grid item xs={12} sm={12} md={12} >
                        <StyledBox>





                            <Grid item xs={6} sm={4} md={4} align="center"  >
                                <Typography backgroundColor='silver' width='6rem'
                                    onClick={() => {
                                        setMenuFunction('DINNER')
                                    }} >
                                    <StyledButton>Dinner</StyledButton>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} align="center"  >
                                <Typography backgroundColor='silver' width='6rem'
                                    onClick={() => {
                                        setMenuFunction('LUNCH')
                                    }} >
                                    <StyledButton   >Lunch</StyledButton>
                                </Typography>
                            </Grid>


                            {/* <Grid item xs={6} sm={4} md={4} align="center" >
                                <Typography backgroundColor='silver' width='8rem'
                                    onClick={() => {
                                        setMenuFunction('HAPPY HOUR')
                                    }} >
                                    <StyledButton >Happy Hour</StyledButton>
                                </Typography>
                            </Grid> */}


                        </StyledBox>
                    </Grid>




                    <Grid item xs={6} sm={4} md={4} align="center" >
                        <StyledTypography  >
                            {displayHeading}
                        </StyledTypography>
                    </Grid>


                    <FoodCategory activeMenu={activeMenu} foodMenuData={foodMenuData} stillLoading={loading}></FoodCategory>

                    {/* 
                    <div width='100%'>
                        {displayItems.map((item) => (
                            <MenuDisplay key={item.id} item={item} ></MenuDisplay>
                        ))}
                    </div>
 */}

                    <Footer></Footer>

                </DivWrapper>
            </ThemeProvider>
        </div >
    );
};

//export default FoodMenu;




{/* <div>
{foodMenuSequenceArray.map((item) => (
    <FoodCategory key={item} item={item} ></FoodCategory>
))}
</div> */}































const mapStateToProps = (state) => {
    const reducerData = state.reducer3
    const foodMenu = state.foodMenu
    return {
        reducerData,
        foodMenu,
    };
};

export default connect(mapStateToProps)(FoodMenu);