
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Box, Typography } from '@mui/material';

import ResultItem from "./ResultItem"

const ResultsList = (props) => {
    // Component logic and state go here


    console.log("--mapped length:-- ", props.reducerData.queryResult.length)
    console.log("--mapped state:-- ", props.reducerData.queryResult)




    return (
        <div>

            <Typography align="center" fontSize='2rem' fontWeight='bold' padding='0.5rem' backgroundColor='black' color='rgb(171,18,57)' >
            Search Listing
            </Typography>


            {/* {props.reducerData.queryResult.map(item => (
                <ResultItem key={item[0]}
                id={item[0]} 
                data={item}
                />

            ))} */}


            {props.reducerData.queryObjectArray.map(item => (
                <ResultItem key={item.resultId}
                    id={item.resultId}
                    data={item}
                />

            ))}




            {/* 
            
            
            <Typography align="center" fontSize='1.1rem' backgroundColor='black' color='rgb(171,18,57)' >
                    {item[0]}
                </Typography>
            
            
            
            {props.reducerData.queryResult(queueItem => (
                <FillQueue key={queueItem.id}
                    id={queueItem.id}
                    filePath={queueItem.filePath}
                    fileName={queueItem.fileName}
                    onDelete={this.deleteQueueItem}
                    onPlay={this.playQueueItem}
                    playingNow={this.props.status[0].queueId} />
            ))} */}

            {/* {this.props.playQueue.map(queueItem => (
                <FillQueue key={queueItem.id}
                    id={queueItem.id}
                    filePath={queueItem.filePath}
                    fileName={queueItem.fileName}
                    onDelete={this.deleteQueueItem}
                    onPlay={this.playQueueItem}
                    playingNow={this.props.status[0].queueId} />
            ))} */}


        </div>
        // JSX markup for the component goes here
    );
};


{/* 
                // from file backup2_App.js in reactnews_v3
                <div>

                <p style={buttonStyle}>QUEUE</p>
                {this.props.playQueue.map(queueItem => (
                    <FillQueue key={queueItem.id}
                        id={queueItem.id}
                        filePath={queueItem.filePath}
                        fileName={queueItem.fileName}
                        onDelete={this.deleteQueueItem}
                        onPlay={this.playQueueItem}
                        playingNow={this.props.status[0].queueId} />
                ))}

            </div> */}







const mapStateToProps = (state) => {
    const reducerData = state.reducer2
    //console.log("reducerData ", reducerData)
    return {
        reducerData,
    };
};



export default connect(mapStateToProps)(ResultsList);
