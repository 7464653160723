

export default function exportedFunction(state = {}, action) {
    if (action.type === 'QUERY_FOOD_DATA_SUCCESS') {

        // console.log('QUERY_FOOD_DATA_SUCCESS ', action.payload)
        // console.log('STATE ', state)
        const foodDataArray = action.payload

        // UPDATE_FOOD_SEARCH_RESULTS

        let foodDataObject = {}
        let foodMenuSequence = []
        let foodCategorySequence = []

        foodDataArray.forEach((dataElement, index) => {
            // console.log(index, dataElement)
            // console.log(index, dataElement.menu_name)
            // console.log(index, dataElement.category_name)
            // console.log(index, dataElement.dish_name)
            // console.log(foodDataObject[dataElement.menu_name])
            // console.log(foodDataObject.hasOwnProperty(dataElement.menu_name))

            if (foodDataObject.hasOwnProperty(dataElement.menu_name) == false) {
                //console.log("===== key DOES NOT exist =====")
                foodDataObject[dataElement.menu_name] = {}
                foodMenuSequence.push(dataElement.menu_name)

            } 
            // else {
            //     console.log("key exists")
            // }

            if (foodDataObject[dataElement.menu_name].hasOwnProperty(dataElement.category_name) == false) {
                //console.log("===== key DOES NOT exist =====")
                foodDataObject[dataElement.menu_name][dataElement.category_name] = []

                if (!foodCategorySequence.includes(dataElement.category_name)) {
                    foodCategorySequence.push(dataElement.category_name)
                }



            } 
            // else {
            //     console.log("key exists")
            // }
            //let parsedImageArray = []

            let imageString = dataElement.images
            let parsedImageArray = imageString.split(',').map(item => item.trim());
            let modifiedDataElement = dataElement
            modifiedDataElement.images = parsedImageArray
            ////console.log("imageString ", imageString, parsedImageArray)
            foodDataObject[dataElement.menu_name][dataElement.category_name].push(modifiedDataElement)

        })

        // console.log("foodDataObject ", foodDataObject)
        // console.log("foodMenuSequence ", foodMenuSequence)
        // console.log("foodCategorySequence ", foodCategorySequence)

        return {
            foodDataObject: foodDataObject,
            foodMenuSequence: foodMenuSequence,
            foodCategorySequence: foodCategorySequence
        };
    }
    return state;
}

