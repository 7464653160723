
import MenuBar from "../../components/layout/MenuBarDev"
import Footer from "../../components/layout/Footer"
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import { connect } from 'react-redux';

import ScrollPage from "./ScrollPage"


const itemStyle = {
  width: '100%',
  height: '45rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'gray',
  border: 'none', //'1px solid #ccc',
  position: 'absolute',
};

const containerStyle = {
  position: 'relative',
  width: '90%',
  maxWidth: '600px',
  overflow: 'hidden',
  height: '45rem',
};

const items = [
  {
    name: 'GM Ryan',
    food: 'Seared Mango Salmon',
    foodInfo: {
      price: '25',
      image: '023_seared_mango_salmon-01.jpg',
    },
    wine: 'item 1',
    wineInfo: {
      image: '008-duckhorn_sauvignon_blanc.jpg',
    },
  }, {
    name: 'Server Jack',
    food: 'Togarashi Seared Tuna',
    foodInfo: {
      price: '28',
      image: '024_togarashi_seared_tuna-01.jpg',
    },
    wine: 'item 2',
    wineInfo: {
      image: '009-southern_lines_sauvignon_blanc.jpg',
    },
  }, {
    name: 'Chef Chris',
    food: 'Rouge Burger',
    foodInfo: {
      price: '20',
      image: '025_rouge_burger-01.jpg',
    },
    wine: 'item 4',
    wineInfo: {
      image: '010-gorro_vinho.jpg',
    },
  }
];


const StyledBox = (props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      width: '100%',
      backgroundColor: 'black',
      //height: '50rem',   //height: '69rem',
    }}
  >
    {props.children}
  </Box>
);




const StaffPicks = ({ data }) => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(1); // 1 for right, -1 for left

  const transitions = useTransition(index, {
    from: { opacity: 0, transform: `translateX(${direction === 1 ? '100%' : '-100%'})` },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: `translateX(${direction === 1 ? '-100%' : '100%'})` },
  });

  const nextItem = () => {
    setDirection(1); // Set direction to right
    setIndex((prevState) => (prevState === items.length - 1 ? 0 : prevState + 1));
  };

  const prevItem = () => {
    setDirection(-1); // Set direction to left
    setIndex((prevState) => (prevState === 0 ? items.length - 1 : prevState - 1));
  };


  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setEndX(e.touches[0].clientX);
  }

  const handleTouchEnd = () => {
    const deltaX = endX - startX;

    if (deltaX > 25) {
      // Swipe right, go to the previous item
      //setIndex((prevState) => (prevState === 0 ? items.length - 1 : prevState - 1));
      prevItem()
    } else if (deltaX < -25) {
      // Swipe left, go to the next item
      //setIndex((prevState) => (prevState === items.length - 1 ? 0 : prevState + 1));
      nextItem()
    }
  };



  return (
    <div>
      <StyledBox>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MenuBar ></MenuBar>
          </Grid>
        </Grid>

        <div style={{ display: 'flex', justifyContent: 'center' }}>

          <div style={containerStyle}>
            {transitions((style, i) => (
              <animated.div key={i} style={{ ...style, ...itemStyle }}
                onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}
              >
                <ScrollPage data={items[i]}></ScrollPage>
              </animated.div>
            ))}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <button onClick={prevItem} style={{ fontSize: '2rem', padding: '1rem', backgroundColor: 'gray' }}>Previous</button>
          <button onClick={nextItem} style={{ fontSize: '2rem', padding: '1rem', backgroundColor: 'gray' }}>Next</button>
        </div>

      </StyledBox>
      <Footer></Footer>

    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data,
  };
};

export default connect(mapStateToProps)(StaffPicks);





