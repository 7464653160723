import React, { useState } from "react";
import Modal from "react-modal";

import '../../css/modalStyles.css'
import bottle_image from '../../images/default_bottle.png'

import { LazyLoadImage } from "react-lazy-load-image-component";

const ModalComponent = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    console.log("MODAL PROPS: ", props.data)
    const wineDetails = props.data;
    console.log("MODAL PROPS2: ", wineDetails.wineName)
    //  { wineDetails.wineName }


    //const imgURL = "http://192.168.3.20:6996/images/" + wineDetails.wineID
    const imgURL = "https://www.simpletech.dev/images/images/" + wineDetails.wineID

    if (props.render == true) {

        return (

            <div className="modalApp" style={{ width: '100%', height: '2rem', opacity: '0.8', }}>


                <button style={{ color: 'maroon', fontSize: '1rem', fontWeight: 'bold', width: '60%', padding: '0.3rem', }} onClick={toggleModal}>VIEW DETAILS</button>




                <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModal}
                    contentLabel="My dialog"
                    className="mymodal"
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                >
                    <div style={{
                        //margin: '0rem', padding: '3rem',
                        width: '15rem', height: '35rem', padding: '2rem',
                        ////backgroundImage: `url(${importedImage7})`,
                        backgroundColor: 'silver',
                        //// backgroundRepeat: 'no-repeat',
                        //// backgroundSize: 'cover',
                    }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: 'row',
                                height: '45%',
                            }}


                        // style={{
                        //     backgroundImage: `url(${bottle_image})`,
                        //     ///backgroundColor: 'red',
                        //     backgroundRepeat: 'no-repeat',
                        //     backgroundSize: 'contain',
                        //     height: '50%',
                        //     //minHeight: '1300px',
                        //     width: '30%',//'60rem',
                        // }}
                        // let wineObjArray = ['wineName', 'wineInfo', 'wineID', 'wineType', 'wineGrape', 'wineLocation', 
                        // 'wineAllergen', 'winePrice', 'wineDescription', 'wineFlavors', 'winePairs']

                        >


                            {/* <div
                                style={{
                                    backgroundImage: `url(${bottle_image})`,
                                    //backgroundColor: 'black',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    height: '100%',
                                    //minHeight: '1300px',
                                    width: '40%',//'60rem',
                                }}
                            >

                            </div> */}


                            <div style={{

                                padding: '0.5rem',
                            }}>
                                <LazyLoadImage src={imgURL} align="center"
                                    width={112} height={200}
                                    style={{
                                        paddingTop: '1rem',
                                        paddingLeft: '0rem',
                                        paddingRight: '1rem',
                                        paddingBottom: '0rem',
                                    }}
                                    alt="Image Alt"
                                />
                            </div>





                            <div
                                style={{

                                    height: '100%',
                                    //minHeight: '1300px',
                                    width: '100%',//'60rem',
                                }}>
                                <h3>{wineDetails.wineName}</h3>
                                <p><span style={{ fontWeight: 'bold', }}>TYPE:</span> {wineDetails.wineType}</p>
                                <p><span style={{ fontWeight: 'bold', }}>GRAPE:</span> {wineDetails.wineGrape}</p>
                                

                            </div>
                        </div>
                        <p><span style={{ fontWeight: 'bold', }}>LOCATION:</span> {wineDetails.wineInfo}</p>
                        <p><span style={{ fontWeight: 'bold', }}>DESCRIPTION:</span> {wineDetails.wineDescription} </p>
                        <p><span style={{ fontWeight: 'bold', }}>FLAVORS:</span>  {wineDetails.wineFlavors}  </p>
                        <p><span style={{ fontWeight: 'bold', }}>PAIRS WITH:</span>  {wineDetails.winePairs} </p>
                        <p><span style={{ fontWeight: 'bold', }}>PRICE:</span> {wineDetails.winePrice}</p>


                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                            <button style={{
                                color: 'maroon', fontSize: '1rem', padding: '0.5rem', margin: '0rem', fontWeight: 'bold',
                            }}>REVIEWS</button>

                            <button onClick={toggleModal} style={{
                                color: 'maroon', fontSize: '1rem', padding: '0.5rem', margin: '0rem', fontWeight: 'bold',
                            }}>CLOSE DETAILS</button>
                        </div>



                    </div>

                </Modal>
            </div>

        )


    } else {
        return (
            <div></div>
        )
    }

};

export default ModalComponent;





/*




                        <p>ALLERGEN: {wineDetails.wineAllergen}</p>




import React, { useState } from "react";
import Modal from "react-modal";

import '../css/modalStyles.css'
import bottle_image from '../images/bottles/dalla_valle.png'



const ModalComponent = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    console.log("MODAL PROPS: ", props.data)
    const wineDetails = props.data;
    console.log("MODAL PROPS2: ", wineDetails.wineName)
    //  { wineDetails.wineName }
    
    if (props.render == true) {

        return (

            <div className="modalApp" style={{ width: '100%', height: '2rem', opacity: '0.8', }}>


                <h1 style={{ color: 'maroon', fontSize: '1rem', width: '100%', }} onClick={toggleModal}>VIEW DETAILS</h1>




                <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModal}
                    contentLabel="My dialog"
                    className="mymodal"
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                >
                    <div style={{
                        //margin: '0rem', padding: '3rem',
                        width: '15rem', height: '35rem', padding: '2rem',
                        ////backgroundImage: `url(${importedImage7})`,
                        backgroundColor: 'silver',
                        //// backgroundRepeat: 'no-repeat',
                        //// backgroundSize: 'cover',
                    }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: 'row',
                                height: '45%',
                            }}


                        // style={{
                        //     backgroundImage: `url(${bottle_image})`,
                        //     ///backgroundColor: 'red',
                        //     backgroundRepeat: 'no-repeat',
                        //     backgroundSize: 'contain',
                        //     height: '50%',
                        //     //minHeight: '1300px',
                        //     width: '30%',//'60rem',
                        // }}
                        >
                            <div
                                style={{
                                    backgroundImage: `url(${bottle_image})`,
                                    //backgroundColor: 'black',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    height: '100%',
                                    //minHeight: '1300px',
                                    width: '40%',//'60rem',
                                }}
                            >

                            </div>
                            <div
                                style={{

                                    height: '100%',
                                    //minHeight: '1300px',
                                    width: '80%',//'60rem',
                                }}>
                                <h3>Dalla Valle { wineDetails.wineName } </h3>
                                <p>RED: Cabernet Sauvignon</p>
                                <p>Napa Valley, CA, USA</p>
                                <p>Contains Sulfites</p>
                                <p>LB:5 ST:4 DS:1 SA:3 GF:n/a</p>
                                <p>PRICE: $399 </p>
                            </div>
                        </div>
                        <p>DESCRIPTION: Medium-full body wine with aromas of forest fruits, currant, tobacco, pepper and vanilla. Hard tannins with a long and powerful finish.</p>
                        <p>FLAVORS: Blackberry, Dark Fruit, Oak, Tobacco, Vanilla, Leather, Earthy, Smoke </p>
                        <p>PAIRS WITH: Beef, Lamb, Deer, Hard Cheese, Poultry</p>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                            <button style={{
                                color: 'maroon', fontSize: '1rem', padding: '0.5rem', margin: '0rem'
                            }}>REVIEWS</button>

                            <button onClick={toggleModal} style={{
                                color: 'maroon', fontSize: '1rem', padding: '0.5rem', margin: '0rem'
                            }}>CLOSE DETAILS</button>
                        </div>



                    </div>

                </Modal>
            </div>

        )


    } else {
        return (
            <div></div>
        )
    }

};

export default ModalComponent;
































 <button onClick={toggleModal} style={{ color: 'maroon', fontSize: '1rem', padding: '0.5rem', margin: '1rem' }}>RETURN TO BOOK</button>


*/













