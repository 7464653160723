import React from 'react';
import ReactDOM from 'react-dom';

import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
////import { store } from './store/store';
import App from './App';
////import reportWebVitals from './reportWebVitals';
import './index.css';


import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer'; // import the root reducer

import { BrowserRouter, Route, Routes } from 'react-router-dom';


import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import Modal from 'react-modal';
Modal.setAppElement('#root');

// const store = createStore(
//   rootReducer,
//   applyMiddleware(thunkMiddleware)
// );


// Create the Redux store
// const store = configureStore({
//   reducer: rootReducer, // Pass the root reducer
// });


// https://www.npmjs.com/package/redux-thunk
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: thunkMiddleware
      }
    })
})

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
////reportWebVitals();
