import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import PickFoodItem from "./PickFoodItem"
import PickWineItem from "./PickWineItem"


const ScrollPage = (props) => {

    // Extract data from props
    const { data } = props;

    return (
        <div style={{width:'80%', maxWidth: '400px', height:'100%', display:'flex', flexDirection:'column', justifyContent:'space-between'}}>

            <p style={{fontWeight:'bold', fontSize:'1.7rem'}}>{data.name}</p>
            <p style={{fontSize:'1.3rem', margin:'0px'}}>Favorite: {data.food}</p>
            <PickFoodItem foodInfo={data.foodInfo}></PickFoodItem>
            <p style={{fontSize:'1.3rem', margin:'0px'}}>Wine Pairing: {data.wine}</p>
            <PickWineItem wineInfo={data.wineInfo}></PickWineItem>
            
        </div >
    );
};

export default ScrollPage;

