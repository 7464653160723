

import React, { useState } from "react";
import Modal from "react-modal";
import '../../css/modalStyles.css'

import { LazyLoadImage } from "react-lazy-load-image-component";

import ImageScroll from './ImageScroll'

import { forwardRef } from 'react';


const DetailsModal = forwardRef((props, ref) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
      /////  console.log("clicked")
        setIsOpen(!isOpen);
    }

    React.useImperativeHandle(ref, () => ({
        childFunction
    }));

    function childFunction() {
        // Function logic in the child component
       ////// console.log("Child function called from parent");
        toggleModal()
    }
    // let imageURLArray = props.data.imageURL
    // let imageURL = imageURLArray[0]
    // console.log("imageURL", imageURL, typeof (imageURL))


    // if (typeof (imageURLArray) != "object") {
    //     console.log("not a object")
    //     imageURL = 'rouge_logo.png'
    // } else {
    //     console.log("hotdog")
    // }

    const itemDetails = {
        name: props.data.dish_name,
        price: props.data.price,
        images: props.data.images,
        description: props.data.short_description,
        detail: props.data.details,
        //imgURL: 'https://www.simpletech.dev/images/images/' + imageURL,
        allergens: props.data.allergen,


    }

    // const layoutStyles = {

    //     layout_1: {
    //         display: "flex",
    //         flexDirection: 'row',
    //         height: '45%',
    //         backgroundColor: 'white',
    //     }


    // }

    const allergenMap = {
        DFO: 'Dairy',
        GFO: 'Gluten Free Option',
        EO: 'Egg Free Option',
        EFO: 'Egg Free Option',
        G: 'Gluten',
        D: 'Dairy',
        DO: 'Dairy Free Option',
        Soy_O: 'Soy Free Option',
        E: 'Eggs',
        C: 'Shellfish',
        S: 'Seafood',
        T: 'Tree Nut',
        SEO: 'Sesame Free Option',
        Spro: 'Spro',
        none: 'None',
    }

    // G= Gluten, D=Dairy, S=Seafood, SE=Sesame, P=Peanut, T=Tree Nut, C-Shellfish, E=Eggs, Soy = Soy
    /*
    
    DFO
    GFO
    EO
    EFO
    G
    D
    DO
    Soy O
    E
    C
    S
    C
    T
    SEO
    Spro
    none
    
    */


    function parseInputString(inputString) {
        // Check if the input string is not empty or null
        if (!inputString || inputString.trim() === '') {
            return [];
        }

        // Split the input string using the comma as a delimiter
        const array = inputString.split(',');

        // Trim the white spaces from each element and create a new array
        const resultArray = array.map((element) => element.trim());

        return resultArray;
    }


    let allergenArray = parseInputString(itemDetails.allergens)
    ////console.log('allergenArray', allergenArray)


    let allergenArray2 = []
    allergenArray.map((element, index, array) => {
        allergenArray2.push(allergenMap[element])
    });

   //// console.log('allergenArray2', allergenArray2)


    // allergenArray.map(
    // )




    if (props.render == true) {

        return (

            <div className="modalApp" style={{ width: '100%', height: '2rem', }} onClick={toggleModal}>

                <button
                    style={{
                        paddingBottom: '12rem',
                        backgroundColor: 'rgb(50,50,50)',
                        border: '0px',
                        color: 'white',
                        fontSize: '1rem',
                        fontWeight: 'italic',
                        width: '100%',
                        height: '100%',
                        padding: '0.3rem',
                        opacity: '0.0',
                    }}
                // onClick={toggleModal}
                >
                    DETAILS
                </button>



                <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModal}
                    contentLabel="My dialog"
                    className="mymodal"
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                    style={{ backgroundColor: 'rgb(70,50,50)', }}

                >
                    <div id="modalDiv0" style={{
                        //margin: '0rem', padding: '3rem',
                        borderColor: 'black',
                        borderWidth: '1rem',
                        width: '18rem',
                        //height: '35rem',
                        minHeight: '30rem',
                        padding: '0.5rem',
                        margin: '0px',
                        ////backgroundImage: `url(${importedImage7})`,
                        backgroundColor: 'rgb(30,0,0)',
                        //// backgroundRepeat: 'no-repeat',
                        //// backgroundSize: 'cover',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        color: 'gray',
                        //borderRadius: "50px",
                    }}>




                        <div id="modalDiv1" style={{
                            height: 'auto',
                            /// backgroundColor: 'gray',
                            padding: '0px',
                            ////backgroundColor: 'gray',
                            margin: '0px',
                            border: '0px',
                        }}>

                            <div style={{ textAlign: 'center' }}>
                                <h3>{itemDetails.name}</h3>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <h4>${itemDetails.price}.00</h4>
                            </div>

                        </div>



                        <div id="modalDiv2"
                            style={{
                                display: "flex",
                                flexDirection: 'row',
                                height: 'auto',
                                // backgroundColor: 'gray',
                            }}
                        >







                            {/* <div id="modalDiv3" style={{

                                /////backgroundColor: 'gray',

                                //minHeight: '1300px',
                                ///// width: '100%',//'60rem',



                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                ////backgroundColor: 'gray',
                                width: '100%',


                            }}>


                            </div> */}


                            <div style={{ height: "8rem" }}>
                                <ImageScroll images={itemDetails.images}></ImageScroll>
                            </div>


                            {/* <div id="modalDiv4"
                                style={{

                                    height: '100%',
                                    //minHeight: '1300px',     <span>TEST3<p>TEST3</p></span>
                                    width: '0%',//'60rem',
                                    ////backgroundColor: 'slategray',
                                }}>
                            </div> */}



                        </div>


                        <div id="modalDiv5"
                            style={{

                                height: 'auto',
                                //minHeight: '1300px',
                                width: 'auto',//'60rem',
                                /////backgroundColor: 'slategray',
                            }}>

                            <div style={{ textAlign: 'center' }}>
                                <h4>{itemDetails.detail}</h4>
                            </div>

                            {/* <div style={{ textAlign: 'center' }}>
                                <p>{itemDetails.allergens}</p>
                            </div> */}

                            <div style={{ textAlign: 'center' }}>
                                <p>ALLERGENS:</p>
                            </div>

                            {allergenArray2.map((element, index) => (
                                <div key={index} style={{ textAlign: 'center' }}>
                                    <p>{element}</p>
                                </div>
                            ))}

                        </div>



                        <div id="modalDiv6"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>


                            <button onClick={toggleModal}
                                style={{
                                    opacity: '70%',
                                    backgroundColor: 'black',
                                    color: 'maroon',
                                    fontSize: '1rem',
                                    padding: '0.5rem',
                                    padding: '1rem',
                                    margin: '0rem',
                                    border: '0rem',
                                    fontWeight: 'bold',
                                }}
                            >CLOSE DETAILS</button>
                        </div>


                    </div>

                </Modal>
            </div>

        )


    } else {
        return (
            <div></div>
        )
    }

});

export default DetailsModal;


/*

                            <button style={{
                                color: 'maroon', fontSize: '1rem', padding: '0.5rem', margin: '0rem', fontWeight: 'bold',
                            }}>REVIEWS</button>








*/
