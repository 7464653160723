import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
//import SearchPanel from '../SearchPanel'
import { connect } from 'react-redux';
import ResultItem from '../common/ResultItem'

const Bottles = (props) => {

    // Extract data from props
    // const { data } = props;
    let renderDisplayItems = props.reducerData.data

    if (renderDisplayItems === undefined) { renderDisplayItems = [] } 

    return (
        <div>

            {renderDisplayItems.map((item, index) => (
                <ResultItem
                    key={`displayItem_${index}`}
                    id={index + 1}
                    data={item}
                ></ResultItem>
            ))}
        </div >
    );
};

//export default Bottles;

const mapStateToProps = (state) => {
    const reducerData = {
        data: state.wineData.wineSearchResults
    }
    return {
        reducerData,
    };
};

export default connect(mapStateToProps)(Bottles);

// <h1 style={{ color: 'white' , display: 'flex', justifyContent: 'center'}} >SEARCH RESULTS:</h1>
            