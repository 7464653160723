import React, { useState, useEffect, useRef } from 'react';
import { useTransition, animated } from 'react-spring';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

const FadingComponent = ({ inputProp, value, activeMenu }) => {
    const [visible, setVisible] = useState(false);
    //const childrenRef = useRef(null);
    //const [hasChildrenRef, setHasChildrenRef] = useState(false);
    //console.log('parentElementOffset ',parentElementOffset)

    // Handle the fade-in and fade-out transitions

    ///// console.log("activeMenu", activeMenu)
    const transition = useTransition(visible, {
        from: { opacity: 0 },
        enter: { opacity: 0.9 },
        leave: { opacity: 0 },
        config: { duration: 300 }, // Adjust duration as needed
    });

    useEffect(() => {
        if (activeMenu != 'FLIGHTS') {

            // When the inputProp changes to true, start the fade-in effect
            if (inputProp) {
                setVisible(true);
            } else {
                // When the outputProp changes to false, start the fade-out effect
                const timeout = setTimeout(() => {
                    setVisible(false);
                }, 150); // Duration of fade-out in milliseconds (adjust as needed)

                return () => clearTimeout(timeout);
            }

        }

    }, [inputProp]);



    // const childrenRef = React.createRef();

    // useEffect(() => {
    //     const handleScroll = () => {
    //       const scrollPosition = window.scrollY;
    //       const windowHeight = window.innerHeight;
    //       const elementOffset = childrenRef.current.offsetTop;

    //       // Calculate the distance from the top of the viewport to the element
    //       const distanceFromViewportTop = elementOffset - scrollPosition;
    //       const isVisible = distanceFromViewportTop < windowHeight * 0.8; // Adjust the threshold as needed

    //       setVisible(isVisible);
    //     };



    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);



    // useEffect(() => {
    //     const handleScroll = () => {
    //       const scrollPosition = window.scrollY;
    //       const windowHeight = window.innerHeight;

    //     //   if (hasChildrenRef && childrenRef.current) {
    //     //     const elementOffset = childrenRef.current.offsetTop;
    //     //     const distanceFromViewportTop = elementOffset - scrollPosition;
    //     //     const isVisible = distanceFromViewportTop < windowHeight * 0.8; // Adjust the threshold as needed
    //     //     setVisible(isVisible);
    //     //   }


    //     // if (hasChildrenRef && childrenRef.current) {
    //     //     const elementOffset = parentElementOffset // childrenRef.current.getBoundingClientRect().top;
    //     //     const distanceFromViewportBottom = windowHeight - elementOffset;
    //     //     const isVisible = distanceFromViewportBottom > windowHeight * 0.4; // Adjust the threshold as needed
    //     //     setVisible(isVisible);
    //     //   } 

    //       if (inputProp == true ){

    //         setVisible(true);
    //       } else {
    //         setVisible(false);
    //       }



    //     };

    //     setHasChildrenRef(true);
    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, [hasChildrenRef]);

    //      <div  ref={childrenRef}>

    return (
        <div >
            {transition((style, item) => item &&
                <animated.div style={{
                    ...style,
                    backgroundColor: '#696969',//'#A2A2A2', // '#977171',//'rgb(52, 0, 0)',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    width: '100vw',
                    padding: '0.2rem',
                }}>

                    <Typography align="center" fontSize='1.3rem' color='black' fontWeight='bold'
                        style={{ padding: '0px', margin: '0px', }}
                    >
                        AVAILABLE MATCHES
                    </Typography>
                    <Typography align="center" fontSize='1.3rem' color='black' fontWeight='bold'
                        style={{ padding: '0px', margin: '0px', }}
                    >
                        {value}
                    </Typography>
                </animated.div>)}
        </div>
    );
};

export default FadingComponent;
