import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import ModalComponent from './ModalComponent'


const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            padding: '0.5rem',
            gap: '1rem',
            width: 'auto',
            backgroundColor: 'black',
            width: '85%',
            padding: '5px',
        }}
    >
        {props.children}
    </Box>
);

const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            backgroundColor: 'gray',
            justifyContent: "space-between",
            paddingBottom: '0.5rem',
            paddingLeft: '15px',
            paddingRight: '15px',
            
        }}
    >
        {props.children}
    </Box>
);


const StyledBox3 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "center",
            width: '100%',

            backgroundColor: 'silver',
        }}
    >
        {props.children}
    </Box>
);






const ResultItem = (props) => {
    // Extract data from props
    const { data } = props;

    console.log("data: ", data)

    //   // Render data using map
    //   const renderedList = data.map((item) => (
    //     // JSX markup for rendering each item in the list
    //     <div key={item.id}>
    //       {/* Render item data */}
    //       <p>{item.name}</p>
    //       {/* ... */}
    //     </div>
    //   ));

    return (
        <div align="center">
            <Box backgroundColor='black'>
                <StyledBox >
                    <Paper >

                        <StyledBox3>

                            <Grid item xs={6} sm={6} md={6} >
                                <Typography align="center" fontSize='1.3rem' fontWeight='bold' backgroundColor='silver' color='black' >
                                    {data.name}
                                </Typography>
                            </Grid>
                        </StyledBox3>



                        <StyledBox2 >
                            <Grid item xs={6} sm={6} md={6} >
                                <Typography align="left" fontSize='1.3rem' fontWeight='bold' backgroundColor='gray' color='black' >
                                    Type: {data.category}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} >
                                <Typography align="left" fontSize='1.3rem' fontWeight='bold' backgroundColor='gray' color='black' >
                                    Match # {data.resultId}
                                </Typography>

                            </Grid>
                        </StyledBox2>



                        <StyledBox2  >
                            <Grid item xs={3} sm={3} md={3} backgroundColor='silver' padding="3px" borderRadius="5px" width="140px" >
                                <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                    Boldness
                                </Typography>
                                <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%'  >
                                    {data.lightBold}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} sm={3} md={3} backgroundColor='silver' padding="3px" borderRadius="5px" width="140px" >
                                <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                    Smoothness
                                </Typography>
                                <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.smoothTannic}
                                </Typography>
                            </Grid>

                        </StyledBox2>

                        <StyledBox2  >
                            <Grid item xs={3} sm={3} md={3} backgroundColor='silver' padding="3px" borderRadius="5px" width="140px" >
                                <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                Dry/Sweet
                                </Typography>
                                <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.drySweet}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} sm={3} md={3} backgroundColor='silver' padding="3px" borderRadius="5px" width="140px" >
                                <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                Acidity
                                </Typography>
                                <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.softAcidic}
                                </Typography>
                            </Grid>

                        </StyledBox2>


                    




                        <StyledBox2 >
                            <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                Price: {data.price}
                            </Typography>

                        </StyledBox2>


                        <StyledBox2>
                            <ModalComponent
                                style={{ paddingLeft: '0rem', color: 'blue' }}
                                render={true}
                                data={data.wineDetailObject}
                            />
                        </StyledBox2>

                    </Paper>


                </StyledBox>



            </Box>

        </div >
    );
};

export default ResultItem;


{/*













        <div align="center">
            <Box backgroundColor='black'>
                <StyledBox>
                    <Box>
                        <Paper >

                            <StyledBox3>

                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography align="center" fontSize='1.3rem' fontWeight='bold' backgroundColor='silver' color='black' >
                                        {data.name}
                                    </Typography>
                                </Grid>
                            </StyledBox3>



                            <StyledBox2 justifyContent="flex-start">
                                <Grid item xs={6} sm={6} md={6} >
                                    <Box width='200px' >
                                        <Typography align="left" fontSize='1.3rem' fontWeight='bold' backgroundColor='gray' color='black' >
                                            Type: {data.category}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6} sm={6} md={6} >
                                    <Box width='150px' >
                                        <Typography align="right" fontSize='1.3rem' fontWeight='bold' backgroundColor='gray' color='black' >
                                            Match # {data.resultId}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </StyledBox2>



                            <StyledBox2 >
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography align="center" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                        Light/Bold: {data.lightBold}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography align="center" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                        Soft/Acidic: {data.softAcidic}
                                    </Typography>
                                </Grid>
                            </StyledBox2>



                            <StyledBox2>
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography align="center" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                        Smooth/Tannic: {data.smoothTannic}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography align="center" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                        Gentle/Fizzy: {data.gentleFizzy}
                                    </Typography>
                                </Grid>
                            </StyledBox2>



                            <StyledBox2>
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography align="center" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                        Dry/Sweet: {data.drySweet}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography align="center" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                        Price: {data.price}
                                    </Typography>
                                </Grid>
                            </StyledBox2>


                        </Paper>
                    </Box>

                </StyledBox>
            </Box>

        </div >























                        <Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
                            Boldness: {data.lightBold} ( {data.lightBoldScore} )
                        </Typography>
                        <Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
                            Smoothness: {data.smoothTannic}
                        </Typography>




                        <Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
                            Dryness: {data.drySweet}
                        </Typography>
                        <Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
                            Softness: {data.softAcidic}
                        </Typography>


                        <Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
                            Fizziness: {data.gentleFizzy}
                        </Typography>
                        <Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
                            Price: {data.price}
                        </Typography>













<Typography align="center" fontSize='1.1rem' backgroundColor='silver' color='black' >
item number {data.name}
</Typography>
 <Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
Wine Type {data[1]}
</Typography>
<Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
Boldness {data[2]}
</Typography>
<Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
Smoothness {data[3]}
</Typography>
<Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
Dryness {data[4]}
</Typography>
<Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
Softness {data[5]}
</Typography>
<Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
Fizziness {data[6]}
</Typography>
<Typography align="center" fontSize='1.1rem' backgroundColor='gray' color='black' >
Price {data[7]}
</Typography> */}