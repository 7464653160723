// THIS WOULD BE FROM REDUCERS
// import exportedFunction from './reducers/reducers.js'

const pageStatus = [{
    centerDisplay: 'root',
    rightDisplay: 'root',
    currentRoot: 'root',
    currentPath: 'home',
    queueId: null,
}];

const displayItems = [];
const playQueueItems = [];
const defaultCommand = { 'pending': false, 'commandType': null, 'value': null };
const initialState = { 'route': '/home'}
// {id: 0, fileName: "test", filePath: "test"}

export default function exportedFunction(state = {
    route: initialState.route,
    status: pageStatus,
    display: displayItems,
    playQueue: playQueueItems,
    command: defaultCommand,
    counter: 0,
}, action) {

    if (action.type === 'TEST_ACTION') {
        return {
            route: state.route,
            status: state.status,
            display: state.display,
            playQueue: state.playQueue,
            command: state.command,
            counter: action.payload.text
        };
    }

    if (action.type === 'UPDATE_ROUTE') {
        return {
            route: action.payload,
            status: state.status,
            display: state.display,
            playQueue: state.playQueue,
            command: state.command,
            counter: state.counter,
        };
    }



    return state;

}