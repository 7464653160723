import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import ModalComponent from './ModalComponent'


// import testImg from "../../../images/test/test.jpg"
//import testImg from "../../../images/test/logo.png"


const GLOBAL_OPACITY = '100%'

import CONFIG from '../../../config/config.js'
const WINE_IMAGE_SERVER_IP = CONFIG.WINE_IMAGE_SERVER_IP
const WINE_IMAGE_SERVER_URL = CONFIG.WINE_IMAGE_SERVER_URL


import { useRef } from 'react';

import ResultItemPrice from './ResultItemPrice'

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            padding: '0.5rem',
            gap: '1rem',
            width: 'auto',
            backgroundColor: 'black',
            width: '85%',
            padding: '5px',
            //opacity: "85%",



        }}
    >
        {props.children}
    </Box>
);

const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            //backgroundColor: 'gray',
            justifyContent: "space-between",
            paddingBottom: '0.5rem',
            paddingLeft: '15px',
            paddingRight: '15px',
            //opacity: '90%',

        }}
    >
        {props.children}
    </Box>
);


const StyledBox3 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "center",
            width: '100%',
            backgroundColor: 'gray',
        }}
    >
        {props.children}
    </Box>
);





// import { useRef } from 'react';
// const childRef = useRef(null);
// function handleParentClick() {
//     // Call a function in the child component
//     childRef.current.childFunction();
//   }

// let buttonOpacity = '0%'

// buttonOpacity={buttonOpacity}
// ref={childRef}




const ResultItem = (props) => {
    // Extract data from props
    const { data } = props;
    const { id } = props;

    /////console.log("data: ", data)




    //   // Render data using map
    //   const renderedList = data.map((item) => (
    //     // JSX markup for rendering each item in the list
    //     <div key={item.id}>
    //       {/* Render item data */}
    //       <p>{item.name}</p>
    //       {/* ... */}
    //     </div>
    //   ));





    // backgroundSize: '100% 100%',
    //sx={{ backgroundImage: `url(${testImg})`, 

    const imgURL = WINE_IMAGE_SERVER_IP + WINE_IMAGE_SERVER_URL + 'label_' + data.image



    const childRef = useRef(null);
    function handleParentClick() {
        // Call a function in the child component
        childRef.current.childFunction();
    }

    let buttonOpacity = '100%'

    return (


        <div onClick={handleParentClick} style={{ width: 'auto', display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem' }} >
            <Box backgroundColor='black'

                sx={{

                    opacity: GLOBAL_OPACITY,
                    width: '23rem',
                    minWidth: '40%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0px',
                    margin: '0px',
                    border: '0px',

                }} >

                <StyledBox >

                    <Paper sx={{

                        backgroundImage: `url(${imgURL})`,
                        backgroundSize: 'auto',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%',
                        backgroundColor: '#696969',
                        width: '100%',


                    }}>

                        <StyledBox3>

                            <Grid item xs={6} sm={6} md={6} >
                                <Typography
                                    align="center"
                                    fontSize='1.3rem'
                                    fontWeight='bold'
                                    // backgroundColor='gray'
                                    color='black'
                                    margin='0.2rem'
                                >
                                    {data.name}
                                </Typography>
                            </Grid>
                        </StyledBox3>



                        <StyledBox2  >
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'

                                >
                                    Type: {data.type}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%'>
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'
                                >
                                    Match # {id}
                                </Typography>

                            </Grid>
                        </StyledBox2>
                        <StyledBox2 >
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >

                                    Grape: {data.grape}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >
                                    Region: {data.region}
                                </Typography>

                            </Grid>
                        </StyledBox2>


                        <StyledBox2  >
                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >
                                <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                    Body: Light - Full
                                </Typography>
                                <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%'  >
                                    {data.body_light_full}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >
                                <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                    Tannin: Low - High
                                </Typography>
                                <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.tannin_low_high}
                                </Typography>
                            </Grid>

                        </StyledBox2>

                        <StyledBox2  >

                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >

                                <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                    Dry - Sweet
                                </Typography>
                                <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.dry_sweet}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >

                                <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                    Acid: Low - High
                                </Typography>
                                <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.acid_low_high}
                                </Typography>
                            </Grid>

                        </StyledBox2>







                        {/* <StyledBox2 display='flex' justifyContent='center' >
                            <Box backgroundColor='gray' display='flex' justifyContent='space-evenly' width='100%' padding='0.2rem' >
                                <Box display='flex' flexDirection='column' >
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        Glass 5oz
                                    </Typography>
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        {data.price_glass_5_oz}
                                    </Typography>
                                </Box>

                                <Box display='flex' flexDirection='column' >
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        Glass 8oz
                                    </Typography>
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        {data.price_glass_8_oz}
                                    </Typography>
                                </Box>

                                <Box display='flex' flexDirection='column' >

                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        Bottle
                                    </Typography>
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        {data.price_bottle}
                                    </Typography>
                                </Box>

                            </Box>
                        </StyledBox2> */}



                        <ResultItemPrice data={data} ></ResultItemPrice>



                        <StyledBox2>
                            <ModalComponent
                                style={{ paddingLeft: '0rem', color: 'blue' }}
                                render={true}
                                data={data}
                                buttonOpacity={buttonOpacity}
                                ref={childRef}
                            />
                        </StyledBox2>

                    </Paper>


                </StyledBox>



            </Box>

        </div >



    );
};

export default ResultItem;











/*




























        <div onClick={handleParentClick} style={{ width: '90%', display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem' }} >
            <Box backgroundColor='black' sx={{ opacity: GLOBAL_OPACITY, width: '25rem', minWidth: '40%', display: 'flex', justifyContent: 'center' }} >
                <StyledBox >

                    <Paper sx={{

                        backgroundImage: `url(${imgURL})`,
                        backgroundSize: 'auto',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%',
                        backgroundColor: '#696969',
                        width: '100%',


                    }}>

                        <StyledBox3>

                            <Grid item xs={6} sm={6} md={6} >
                                <Typography
                                    align="center"
                                    fontSize='1.3rem'
                                    fontWeight='bold'
                                    // backgroundColor='gray'
                                    color='black'
                                    margin='0.2rem'
                                >
                                    {data.name}
                                </Typography>
                            </Grid>
                        </StyledBox3>



                        <StyledBox2  >
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'

                                >
                                    Type: {data.type}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%'>
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'
                                >
                                    Match # {id}
                                </Typography>

                            </Grid>
                        </StyledBox2>
                        <StyledBox2 >
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >

                                    Grape: {data.grape}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='gray'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >
                                    Region: {data.region}
                                </Typography>

                            </Grid>
                        </StyledBox2>


                        <StyledBox2  >
                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >
                                <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                    Body: Light - Full
                                </Typography>
                                <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%'  >
                                    {data.body_light_full}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >
                                <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                    Tannin: Low - High
                                </Typography>
                                <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.tannin_low_high}
                                </Typography>
                            </Grid>

                        </StyledBox2>

                        <StyledBox2  >

                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >

                                <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                    Dry - Sweet
                                </Typography>
                                <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.dry_sweet}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} sm={3} md={3}

                                width='45%'
                                backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                            >

                                <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                    Acid: Low - High
                                </Typography>
                                <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                    {data.acid_low_high}
                                </Typography>
                            </Grid>

                        </StyledBox2>







                        <StyledBox2 display='flex' justifyContent='center' >
                            <Box backgroundColor='gray' display='flex' justifyContent='space-evenly' width='100%' padding='0.2rem' >
                                <Box display='flex' flexDirection='column' >
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        Glass 5oz
                                    </Typography>
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        {data.price_glass_5_oz}
                                    </Typography>
                                </Box>

                                <Box display='flex' flexDirection='column' >
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        Glass 8oz
                                    </Typography>
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        {data.price_glass_8_oz}
                                    </Typography>
                                </Box>

                                <Box display='flex' flexDirection='column' >

                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        Bottle
                                    </Typography>
                                    <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                        {data.price_bottle}
                                    </Typography>
                                </Box>

                            </Box>
                        </StyledBox2>



                        <ResultItemPrice data={data} ></ResultItemPrice>



                        <StyledBox2>
                            <ModalComponent
                                style={{ paddingLeft: '0rem', color: 'blue' }}
                                render={true}
                                data={data}
                                buttonOpacity={buttonOpacity}
                                ref={childRef}
                            />
                        </StyledBox2>

                    </Paper>


                </StyledBox>



            </Box>

        </div >












*/