


export const GenerateCurrentResults = (argument) => ({
    type: 'GENERATE_CURRENT_RESULTS',
    payload: {
      toggleValue: argument,
    },
  });


