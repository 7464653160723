
const searchGlasses = [{
    active: false,


}]

const queryResultArray = []

const randomArray = [
    [0, 0, 2, 5, 1, 4, 4, 3],
    [1, 0, 3, 4, 5, 2, 5, 3],
    [2, 0, 4, 0, 2, 5, 3, 3],
    [3, 0, 1, 5, 4, 4, 4, 3],
    [4, 2, 5, 2, 1, 0, 2, 3],
    [5, 2, 2, 3, 0, 3, 4, 3],
    [6, 1, 0, 1, 2, 5, 0, 3],
    [7, 1, 4, 4, 5, 4, 0, 3],
    [8, 1, 2, 0, 1, 1, 0, 3],
    [9, 3, 3, 5, 4, 0, 0, 3],
    [10, 3, 1, 2, 2, 3, 0, 3],
    [11, 3, 5, 1, 5, 1, 0, 3],
    [12, 3, 0, 4, 0, 4, 0, 3],
    [13, 3, 3, 0, 3, 5, 0, 3],
    [14, 3, 4, 2, 1, 2, 0, 3],
    [15, 3, 1, 3, 4, 4, 0, 3],
    [16, 3, 2, 5, 2, 3, 0, 3],
    [17, 3, 0, 1, 5, 0, 0, 3],
    [18, 2, 5, 0, 0, 1, 0, 3],
    [19, 2, 4, 4, 3, 2, 0, 3],
    [20, 2, 3, 2, 4, 5, 0, 3],
    [21, 3, 1, 0, 1, 0, 0, 3],
    [22, 3, 2, 5, 0, 4, 0, 3],
    [23, 3, 4, 1, 2, 1, 0, 3],
    [24, 3, 0, 3, 3, 3, 0, 3],
    [25, 3, 3, 4, 4, 1, 0, 3],
    [26, 3, 5, 1, 0, 5, 0, 3],
    [27, 3, 3, 2, 4, 5, 0, 3],
    [28, 3, 1, 0, 1, 0, 0, 3],
    [29, 3, 2, 5, 0, 4, 0, 3],
    [30, 3, 4, 1, 2, 1, 0, 3],
    [31, 3, 0, 3, 3, 3, 0, 3],
    [32, 3, 3, 4, 4, 1, 0, 3],
    [33, 3, 5, 1, 0, 5, 0, 5],

]


const randomArray2 = [
    [0, 0, 2, 5, 1, 4, 4, 3],
    [1, 0, 3, 4, 5, 2, 5, 3],
    [2, 0, 4, 0, 2, 5, 3, 3],
    [3, 0, 1, 5, 4, 4, 4, 3],
    [4, 0, 5, 2, 1, 0, 2, 3],
    [5, 0, 2, 3, 0, 3, 4, 3],
    [6, 1, 0, 1, 2, 5, 0, 3],
    [7, 1, 4, 4, 5, 4, 0, 3],
    [8, 1, 2, 0, 1, 1, 0, 3],
    [9, 1, 3, 5, 4, 0, 0, 3],
    [10, 1, 1, 2, 2, 3, 0, 3],
    [11, 1, 5, 1, 5, 1, 0, 3],
    [12, 2, 0, 4, 0, 4, 0, 3],
    [13, 2, 3, 0, 3, 5, 0, 3],
    [14, 2, 4, 2, 1, 2, 0, 3],
    [15, 2, 1, 3, 4, 4, 0, 3],
    [16, 2, 2, 5, 2, 3, 0, 3],
    [17, 2, 0, 1, 5, 0, 0, 3],
    [18, 2, 5, 0, 0, 1, 0, 3],
    [19, 2, 4, 4, 3, 2, 0, 3],
    [20, 2, 3, 2, 4, 5, 0, 3],
    [21, 3, 1, 0, 1, 0, 0, 3],
    [22, 3, 2, 5, 0, 4, 0, 3],
    [23, 3, 4, 1, 2, 1, 0, 3],
    [24, 3, 0, 3, 3, 3, 0, 3],
    [25, 3, 3, 4, 4, 1, 0, 3],
    [26, 3, 5, 1, 0, 5, 0, 3],
    [27, 3, 3, 2, 4, 5, 0, 3],
    [28, 3, 1, 0, 1, 0, 0, 3],
    [29, 3, 2, 5, 0, 4, 0, 3],
    [30, 3, 4, 1, 2, 1, 0, 3],
    [31, 3, 0, 3, 3, 3, 0, 3],
    [32, 3, 3, 4, 4, 1, 0, 3],
    [33, 3, 5, 1, 0, 5, 0, 5],

]



let wineNames = [
    "Chardonnay",
    "Cabernet Sauvignon",
    "Merlot",
    "Pinot Noir",
    "Sauvignon Blanc",
    "Zinfandel",
    "Syrah",
    "Riesling",
    "Malbec",
    "Grenache",
    "Tempranillo",
    "Shiraz",
    "Viognier",
    "Champagne",
    "Prosecco",
    "Port",
    "Moscato",
    "Rosé",
    "Sangiovese",
    "Chianti",
    "Barolo",
    "Barbaresco",
    "Rioja",
    "Malvasia",
    "Verdejo",
    "Gewürztraminer",
    "Nebbiolo",
    "Barolo",
    "Barbaresco",
    "Rioja",
    "Malvasia",
    "Verdejo",
    "Gewürztraminer",
    "Nebbiolo",
]

let wineNames2 = [
    ["Bisol Jeio", 'Prosecco, Valdobbiedene, Veneto, IT'],
    ["Brand Bros", "Pet-Nat Rosé of Pinot Noir, Pfalz, GR"],
    ["Lucien Albrecht Crémant d'Alsace", "Brut Rosé, Alsace, FR"],
    //["Ceretto", "Moscato d'Asti, Piedmont, IT"],
    ["alain Couvreur Remi", "Champagne Brut, France"],
    //["Benedicte & Stephane Tissot", "Cremant du Jura Rosé, FR "],
    //["Storm Cellar", "Rosé of Grenache, Paso Robles, CA"],
    // ["Scribe Una Lou", "Rosé of Pinot Noir, Carneros, CA"],
    // ["Domaine des Tourelles", "Rosé of Syrah, Bekaa Valley, Lebanon"],
    // ["Mas de Cadenet", "Provence Rosé, Sainte Victoire, FR "],
    ["Tablas Creek", "Patelin de Tablas Rosé, Paso Robles, CA"],
    ["Wonderland Project", "Pinot Noir/Chardonnay Rosé, Sonoma County, CA"],
    ["Southern Right", "Sauvignon Blanc, Walker Bay, SA "],
    ["Robert Mondavi", "Sauvignon Blanc, Napa Valley, CA"],
    // ["Whitehaven ", "Sauvignon Blanc, Marlborough, NZ "],
    // ["Ponzi Reserve", "Chardonnay, Willamette Valley, OR "],
    // ["MGV 131", "Koshu, Japan"],
    // ["Do Re Mi", "Mtsvache, Georgia"],
    ["Pazo das Bruxas", "Albarino, Rias Baixas, ES"],
    // ["Nik Weis", "St. Urbans-Hoff Riesling, Mosel, GR"],
    //["Hartford Court", "Chardonnay, Russian River, CA"],
    ["Villiera", "Pinotage, SA"],
    // ["Siduri", "Pinot Noir, Santa Barbara, CA"],
    // ["Jax Y3", "Pinot Noir, Russian River, CA"],
    // ["Emeritus Hallberg Ranch", "Pinot Noir, Russian River, CA"],
    // ["Foillard", "Gamay, Beaujolais Villages, FR"],
    // ["MGV", "Muscat, Japan"],
    // ["Do Re Mi", "Saperavi, Georgia"],
    ["Wightman & Son's", "Syrah, Swartland, SA"],
    // ["ii Molino di Grace Il Volano", "Super Tuscan, Tuscany, IT"],
    // ["Justin", "Cabernet Sauvignon, Paso Robles, CA"],
    ["Luigi Bosca", "Malbec, Mendoza, AR"],
    ["Termes", "Tinto de Toro, Toro, ES"],
    // ["Orin Swift Eight Years in the Desert", "CA"],
]

//     ["NAME", 'INFO', 'ID', 'TYPE', 'GRAPE', 'LOCATION', 'ALLERGEN', 'PRICE', 'DESCRIPTION', 'FLAVORS', 'PAIRS', "IMAGE" ],
let wineNames3 = [
    ["Bisol Jeio", 'Prosecco, Valdobbiedene, Veneto, IT',
        '001_bisol_jeio.jpg', 'Bubbles', 'Glera', 'Italy', 'Contains Sulfites',
        "$12 / $48", "A wave of bubbles will stimulate the palette, ", "Pear, Citrus, Thyme", "empty", 'ABV'],

    ["Brand Bros", "Pet-Nat Rosé of Pinot Noir, Pfalz, GR",
        '002_band_bros.jpg', 'Bubbles', 'Pinot Noir, Blauer Portugieser', 'Germany', 'Contains Sulfites',
        "$17 / $68", "Tasty a bit like raspberry lambic, very yeasty, earthy notes.", "Strawberry, Cranberry, Rhubarb, Earthy, Creamy", "empty", 'ABV'],

    ["Lucien Albrecht Crémant d'Alsace", "Brut Rosé, Alsace, FR",
        '003_lucien_albrecht.jpg', 'Bubbles', 'Pinot Noir', 'France', 'ALLERGEN',
        '$18 / $72', 'Light, effervescent, strawberry, orange zest, hint of yeast. Easy drinking and delicious', 'Strawberry, Raspberry, Cream, Yeast, Apple, Pear', 'Shellfish, Poultry, Lean Fish, Aperitif', 'ABV'],

    // ["Ceretto", "Moscato d'Asti, Piedmont, IT", 
    // '004.jpg', 'Bubbles', 'Moscato', 'Piedmont, Italy', 'Contains Sulfites', 
    // '$13 / $52', "Sparkling monovarietal sweet white wine made from Moscato Bianco. Ceretto Moscato d'Asti 2021 is a natural sweet sparkling white wine. It has a fresh, green and fruity aroma and a very fine bubble.", 'Fresh, Green, Fruity', 'Aperitif, Sweet Desserts', '5.5% ABV'],

    ["alain Couvreur Remi", "Champagne Brut, France",
        '005_remi_couvreur.jpg', 'TYPE', 'GRAPE', 'LOCATION', 'ALLERGEN', 'PRICE', 'DESCRIPTION', 'FLAVORS', 'PAIRS'],

    // ["Benedicte & Stephane Tissot", "Cremant du Jura Rosé, FR ", 
    // '006.jpg', 'TYPE', 'GRAPE', 'LOCATION', 'ALLERGEN', 'PRICE', 'DESCRIPTION', 'FLAVORS', 'PAIRS'],

    // ["Storm Cellar", "Rosé of Grenache, Paso Robles, CA"],
    // ["Scribe Una Lou", "Rosé of Pinot Noir, Carneros, CA"],
    // ["Domaine des Tourelles", "Rosé of Syrah, Bekaa Valley, Lebanon"],
    // ["Mas de Cadenet", "Provence Rosé, Sainte Victoire, FR "],

    ["Tablas Creek", "Patelin de Tablas Rosé, Paso Robles, CA",
        '009_tablas_creek_2.jpg'],
    ["Wonderland Project", "Pinot Noir/Chardonnay Rosé, Sonoma County, CA",
        '010_wonderland_ranch.jpg'],
    ["Southern Right", "Sauvignon Blanc, Walker Bay, SA ",
        '011_southern_right.jpg'],
    ["Robert Mondavi", "Sauvignon Blanc, Napa Valley, CA",
        '012_robert_mondavi.jpg'],

    // ["Whitehaven ", "Sauvignon Blanc, Marlborough, NZ "],
    // ["Ponzi Reserve", "Chardonnay, Willamette Valley, OR "],
    // ["MGV 131", "Koshu, Japan"],
    // ["Do Re Mi", "Mtsvache, Georgia"],


    ["Pazo das Bruxas", "Albarino, Rias Baixas, ES",
        '017_pazo_das_bruxas.jpg'],


    // ["Nik Weis", "St. Urbans-Hoff Riesling, Mosel, GR"],
    // ["Hartford Court", "Chardonnay, Russian River, CA"],


    ["Villiera", "Pinotage, SA",
        '020_villiera.jpg'],


    // ["Siduri", "Pinot Noir, Santa Barbara, CA"],
    // ["Jax Y3", "Pinot Noir, Russian River, CA"],
    // ["Emeritus Hallberg Ranch", "Pinot Noir, Russian River, CA"],
    // ["Foillard", "Gamay, Beaujolais Villages, FR"],
    // ["MGV", "Muscat, Japan"],
    // ["Do Re Mi", "Saperavi, Georgia"],


    ["Wightman & Son's", "Syrah, Swartland, SA",
        '027_wightman_and_sons.jpg'],


    // ["ii Molino di Grace Il Volano", "Super Tuscan, Tuscany, IT"],
    // ["Justin", "Cabernet Sauvignon, Paso Robles, CA"],


    ["Luigi Bosca", "Malbec, Mendoza, AR",
        '030_luigi_bosca_2.jpg'],
    ["Termes", "Tinto de Toro, Toro, ES",
        '031_termes.jpg'],


    // ["Orin Swift Eight Years in the Desert", "CA"],
]

const filterData = (query) => {
    ////console.log(query)
    // determine which wines (bubbles, white, etc.) are set to true
    // maybe do bubbles=1, white=2 rose=4 red=8, do sum, so every permutation sum is unique, 
    // so suppose bub and white are true, 1+2=3 so thats the check number
    // if it sees 1, it checks 3 against a boolean table to see if 1 is there 
    // 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15  
    // maybe this isnt gaining any computational efficiency

    // just iterate over each and make a separate object corresponding to each wine type
    // then concatenate them if they are true, appending a "true', at the end of each row
    // then need to make sure each param is in range, so make an array of each high and low, maybe 2 6 item arrays, 1 for high and 1 for low
    // determine which property (dry, sweet, bold, etc.) has the narrowest range, and order them by how small range is
    // for each wine, compare the smallest range param first , then sequential to the largest range
    // if ever a failure, mark that item as false and move on to next one



    // make subset of only true wines and save as object

    // 


    // Create matrices for low and high limits
    //var lowLimitMatrix = math.matrix(Array(6).fill(0)); // Initialize with zeros
    //var highLimitMatrix = math.matrix(Array(6).fill(0)); // Initialize with zeros
    //console.log(lowLimitMatrix, highLimitMatrix)
    //console.log(Math)

    // lightBold: value1,
    // smoothTannic: value2,
    // drySweet: value3,
    // softAcidic: value4,
    // gentleFizzy: value5,
    // price: value6,


    // bubbleCheckedStatus: valueCheckBox1,
    // whiteCheckedStatus: valueCheckBox2,
    // roseCheckedStatus: valueCheckBox3,
    // redCheckedStatus: valueCheckBox4,

    //console.log("query: ",query)


    let wineCategorySequence = ['bubbleCheckedStatus', 'whiteCheckedStatus', 'roseCheckedStatus', 'redCheckedStatus']
    let wineCategorySequenceName = ['Bubbles', 'White', 'Rosé', 'Red']
    let wineCategoryMatrix = []
    let numberWineCategories = 3
    for (let i = 0; i <= numberWineCategories; i++) {
        ////console.log(query[wineCategorySequence[i]])
        let wineCategoryStatus = query[wineCategorySequence[i]]
        wineCategoryMatrix.push(wineCategoryStatus)
    }

    let sliderSequence = ['lightBold', "smoothTannic", 'drySweet', 'softAcidic', 'gentleFizzy', 'price']
    let lowLimitMatrix = []
    let highLimitMatrix = []
    let numberSliders = 5
    for (let i = 0; i <= numberSliders; i++) {
        ////console.log(query[sliderSequence[i]])
        let lowHighArray = query[sliderSequence[i]]
        lowLimitMatrix.push(lowHighArray[0])
        highLimitMatrix.push(lowHighArray[1])
    }


    console.log(wineCategoryMatrix, lowLimitMatrix, highLimitMatrix)





























    // iterate over array ONCE
    // first check that 
    let wineParamArrayLength = 12 //30
    let queryResultArray = []
    for (let i = 0; i <= wineParamArrayLength; i++) {
        //console.log(randomArray[i])
        let testItem = randomArray[i]
        // check if wine type is true
        let wineCategoryIndex = testItem[1]
        if (wineCategoryMatrix[wineCategoryIndex] == true) {
            ////console.log(i, " true")
            queryResultArray.push(true)
        } else {
            ////console.log(i, " false")
            queryResultArray.push(false)
        }
    }
    ////console.log(queryResultArray)

    for (let i = 0; i <= wineParamArrayLength; i++) {

        if (queryResultArray[i] == true) {
            ////console.log(i, " true")
            let testItem = randomArray[i]

            for (let k = 2; k <= 7; k++) {
                let testItemParamValue = testItem[k]
                if (testItemParamValue < lowLimitMatrix[k - 2]) {
                    ////console.log(i, " low false")
                    queryResultArray[i] = false
                    break
                }
                if (testItemParamValue > highLimitMatrix[k - 2]) {
                    ////console.log(i, " high false")
                    queryResultArray[i] = false
                    break
                }
            }
        }

    }

    // console.log("------------------------------")
    // console.log(queryResultArray)
    // console.log(queryResultArray.length)

    let returnArray = []
    let returnObjectArray = []

    // 2/3 - slightly   1/4- moderately   0/5 - heavily
    let adverbArray3 = ['very', 'moderately', 'slightly', 'slightly', 'moderately', 'very']
    let adverbArray = ['Very', '', 'Slightly', 'Slightly', '', 'Very']
    let adverbArray4 = ['very', 'mid', 'a bit', 'a bit', 'mid', 'very']
    let leftAdjectiveArray = ['Light', 'Smooth', 'Dry', 'Soft', 'gentle', 'low-priced']
    let rightAdjectiveArray = ['Bold', 'Tannic', 'Sweet', 'Acidic', 'fizzy', 'high-priced']

    let resultId = 0
    for (let i = 0; i <= wineParamArrayLength; i++) {

        let returnObject = {}

        if (queryResultArray[i] == true) {

            resultId = resultId + 1
            //console.log("resultId ", resultId)

            let tempArray = randomArray[i]
            returnArray.push(tempArray)

            returnObject.resultId = resultId
            returnObject.wineId = tempArray[0]
            returnObject.category = wineCategorySequenceName[tempArray[1]]
            let nameWinery = wineNames2[tempArray[0]]
            returnObject.name = nameWinery[0]
            returnObject.winery = nameWinery[1]


            returnObject.lightBoldScore = tempArray[2]
            let descriptorString
            if (returnObject.lightBoldScore <= 2) {
                descriptorString = adverbArray[returnObject.lightBoldScore] + " " + leftAdjectiveArray[0]
            } else {
                descriptorString = adverbArray[returnObject.lightBoldScore] + " " + rightAdjectiveArray[0]
            }
            returnObject.lightBold = descriptorString


            const makestring = (score, itemNumber) => {
                //console.log("makestring")
                let descriptorString
                if (score <= 2) {
                    descriptorString = adverbArray[score] + " " + leftAdjectiveArray[itemNumber]
                } else {
                    descriptorString = adverbArray[score] + " " + rightAdjectiveArray[itemNumber]
                }
                return descriptorString
            }

            returnObject.smoothTannicScore = tempArray[3]
            returnObject.smoothTannic = makestring(returnObject.smoothTannicScore, 1)
            //returnObject.smoothTannic = tempArray[3]



            returnObject.drySweetScore = tempArray[4]
            returnObject.drySweet = makestring(returnObject.drySweetScore, 2)

            returnObject.softAcidicScore = tempArray[5]
            returnObject.softAcidic = makestring(returnObject.softAcidicScore, 3)

            returnObject.gentleFizzyScore = tempArray[6]

            let descriptorString2
            if (returnObject.gentleFizzyScore <= 2) {
                descriptorString2 = leftAdjectiveArray[4]
            } else {
                descriptorString2 = rightAdjectiveArray[4]
            }
            returnObject.gentleFizzy = descriptorString2




            const makePricestring = (score, itemNumber) => {
                //console.log("makestring")
                let descriptorString
                if (score <= 1) {
                    descriptorString = "$"
                } else if (score <= 3) {
                    descriptorString = "$ $"
                } else {
                    descriptorString = "$ $ $"
                }
                return descriptorString
            }
            returnObject.priceScore = tempArray[7]
            returnObject.price = makePricestring(returnObject.priceScore, 5)

            // INSERT ALL DETAILS PARAMS HERE AS NEW KEY VALUE PAIRS, BEFORE THE PUSH OPERATION BELOW

            // TEST FOR UNDEFINED
            // https://www.freecodecamp.org/news/javascript-check-if-undefined-how-to-test-for-undefined-in-js/
            // if(myStr === undefined){}
            // if(typeof myArr[7] === "undefined"){}
            // if(user.hobby === void 0){}

            try {

                //      ["NAME", 'INFO', 'ID', 'TYPE', 'GRAPE', 'LOCATION', 'ALLERGEN', 'PRICE', 'DESCRIPTION', 'FLAVORS', 'PAIRS'],

                console.log("try", i)

                let wineName = wineNames3[i][0]
                let wineInfo = wineNames3[i][1]
                let wineID = wineNames3[i][2]
                let wineType = wineNames3[i][3]
                let wineGrape = wineNames3[i][4]
                let wineLocation = wineNames3[i][5]
                let wineAllergen = wineNames3[i][6]
                let winePrice = wineNames3[i][7]
                let wineDescription = wineNames3[i][8]
                let wineFlavors = wineNames3[i][9]
                let winePairs = wineNames3[i][10]
                let wineImage = wineNames3[i][11]

                let wineObject = {
                    wineName: wineName,
                    wineInfo: wineInfo,
                    wineID: wineID,
                    wineType: wineType,
                    wineGrape: wineGrape,
                    wineLocation: wineLocation,
                    wineAllergen: wineAllergen,
                    winePrice: winePrice,
                    wineDescription: wineDescription,
                    wineFlavors: wineFlavors,
                    winePairs: winePairs,
                    wineImage: wineImage,
                }

                let wineObjArray = ['wineName', 'wineInfo', 'wineID', 'wineType', 'wineGrape', 'wineLocation', 'wineAllergen', 'winePrice', 'wineDescription', 'wineFlavors', 'winePairs']

                let m = 0;
                while (m < wineObjArray.length) {
                    if (wineObject[wineObjArray[m]] === undefined) {

                        wineObject[wineObjArray[m]] = "empty"
                        if (m == 2) {
                            wineObject[wineObjArray[m]] = "dalla_valle.png"
                        }

                        //console.log("error") 
                    }
                    // else{
                    //     console.log( wineObject[wineObjArray[m]] );
                    // }


                    m++;
                }


                returnObject.wineDetailObject = wineObject

                // console.log("wineNames3[", i, "][2] ", wineID)
                // if (wineID === undefined) { console.log("wineID IS UNDEFINED") }

            } catch (error) {
                //console.log("catch: ", i )
                console.log("catch ERROR: ", error); // TypeError: Cannot read property '0' of undefined
            }

            /////////////////////////////////////////////////////////////////////

            //console.log("return object: ", returnObject)
            returnObjectArray.push(returnObject)

        }


    }

    /*
    
    
            if (queryResultArray[i] == true) {
                let tempArray = randomArray[i]
                let transferArray = []
                //transferArray.push(temparray[0])
                returnArray.push(tempArray[tempArray[0]])
                transferArray.push(wineCategorySequence[tempArray[1]])
                transferArray.push(tempArray[2])
                transferArray.push(tempArray[3])
                transferArray.push(tempArray[4])
                transferArray.push(tempArray[5])
                transferArray.push(tempArray[6])
                transferArray.push(tempArray[7])
                returnArray.push(tempArray)
    
    
    */



    console.log("------------------------------")
    ////console.log(returnArray)
    console.log(returnArray.length)
    console.log("returnObjectArray: ", returnObjectArray)

    let finalReturnObject = {
        retArray: returnArray,
        retObject: returnObjectArray,
    }

    return finalReturnObject

}




export default function exportedFunction(state = {
    query: searchGlasses,
    queryResult: queryResultArray,
    queryObjectArray: [],
}, action) {

    if (action.type === 'UPDATE_QUERY') {

        console.log('updating query')
        ///filterData(action.payload)

        return {
            query: action.payload,
            queryResult: state.queryResult,
            queryObjectArray: state.queryObjectArray
        };
    }

    if (action.type === 'UPDATE_RESULTS') {

        console.log('updating result')
        let queryResultData = filterData(action.payload)

        return {
            query: state.query,
            queryResult: queryResultData.retArray,
            queryObjectArray: queryResultData.retObject
        };
    }



    return state;

}