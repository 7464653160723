
// rootReducer.js
import { combineReducers } from 'redux';
import reducer1 from './reducers';
import reducer2 from './QueryStatus.js';
import reducer3 from './QueryMenu.js';
import reducer4 from './QueryWine.js';
import wineData from "./WineDataPopulate.js"
import foodMenu from "./FoodMenu.js"
//////import wineSearchResults from "./WineSearchResults.js"
///////import searchPanel from "./SearchPanelFields.js"
////import reducer2 from './reducer2';
// ... import other reducers as needed

const rootReducer = combineReducers({
  reducer1, 
  reducer2, // shorthand for reducer1: reducer1
  reducer3,
  reducer4,
  wineData,
  foodMenu,
  
  //wineSearchResults,

  //////searchPanel,
  
  ////reducer2, // shorthand for reducer2: reducer2
  // ... add other reducers as needed
});

export default rootReducer;