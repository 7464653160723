import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import { connect } from 'react-redux';

////import SearchPanel from '../SearchPanel'
import FlightMenuHeading from './FlightMenuHeading'


const devModeBool = false

// if (devModeBool == true) {

// }



const Flights = (props) => {

    let renderDisplayItems = props.reducerData.data
    if (renderDisplayItems === undefined) { renderDisplayItems = [] }

    if (devModeBool == true) {
        console.log("flights: ", renderDisplayItems)
    }



    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',

        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#424242',
            }}>



                {renderDisplayItems.map((item, index) => (
                    <FlightMenuHeading key={`flightItem_${index}`} item={item} />
                ))}

            </div>

        </div >
    );
};

//export default Flights;

const mapStateToProps = (state) => {
    const reducerData = {
        data: state.wineData.wineFlightResults
    }
    return {
        reducerData,
    };
};

export default connect(mapStateToProps)(Flights);


//  key={item.id} item={item}
//             <h1 style={{ color: 'white' , display: 'flex', justifyContent: 'center'}} >FLIGHTS</h1>
