
// export const ToggleAll = (argument) => ({
//     type: 'TOGGLE_ALL',
//     payload: {
//       toggleValue: argument,
//     },
//   });




export const ToggleAll = (argument) => async (dispatch) => {


  try {
    ////console.log("======================================argument", argument)
    let toggleBool = argument.toggleBool
    dispatch(ToggleAll2(toggleBool));

    let searchPanelData = argument.actionObject
    dispatch(UpdateWineSearchResults(searchPanelData));

  } catch (error) {
    // Handle any error if necessary
  }
};

const ToggleAll2 = (payloadObject) => ({
  type: 'TOGGLE_ALL',
  payload: {
    toggleValue: payloadObject,
  },
});

const UpdateWineSearchResults = (payloadObject) => ({
  type: 'UPDATE_WINE_SEARCH_RESULTS',
  payload: payloadObject,
});



  // const UpdateWineSearchResults = (payloadObject) => ({
  //   type: 'UPDATE_WINE_SEARCH_RESULTS',
  //   payload: payloadObject,
  // });