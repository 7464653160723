
import React, { useState } from 'react';

import CONFIG from '../../config/config.js'
const FEEDBACK_IP = CONFIG.FEEDBACK_IP
const FEEDBACK_URL = CONFIG.FEEDBACK_URL

let fetchEndpointURL = FEEDBACK_IP + FEEDBACK_URL

function FeedbackForm() {

    const [submitted, setSubmitted] = useState(false); // Track form submission
    const [feedback, setFeedback] = useState('');


    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value)
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            feedback
        };

        //let fetchEndpointURL = 'http://localhost:5000/submitfeedback' 

        try {
            const response = await fetch(fetchEndpointURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                console.log('Data submitted successfully.');
                setSubmitted(true);
                // You can reset the form fields or display a success message here.
            } else {
                console.error('Error submitting data.');
                setSubmitted(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setSubmitted(true);
        }
    };






    return (
        <div style={{ textAlign: 'center', padding: '0px', color: 'white' }}>



            {submitted ? ( // If the form has been submitted, show the thank-you message
                <div>
                    <p style={{ color: 'rgb(150, 18, 57)', fontSize: '1.3rem', fontWeight: 'bold' }} >Thanks for providing your feedback!</p>
                </div>
            ) : ( // If the form has not been submitted, show the form elements

                <form onSubmit={handleSubmit} style={{ width:'90%', display: 'inline-block', textAlign: 'center' }}>


                    <div style={{ marginBottom: '15px' }}>
                        <textarea
                            placeholder="Enter your feedback"
                            value={feedback}
                            onChange={handleFeedbackChange}
                            required
                            style={{
                                width: '80%',
                                padding: '5px',
                                fontSize: '16px',
                                minHeight: '200px', // You can adjust the height as needed
                                resize: 'vertical', // Allow vertical resizing if necessary
                            }}
                        />
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'center' }} >
                        <button type="submit" style={{ marginTop: '15px', padding: '10px 20px', fontSize: '18px' }}>
                            Submit
                        </button>
                    </div>

                </form>
            )}

        </div>

    );
}

export default FeedbackForm;
