import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import { useState, useEffect } from "react";

import ModalComponent from '../common/ModalComponent'


import CONFIG from '../../../config/config.js'
const WINE_IMAGE_SERVER_IP = CONFIG.WINE_IMAGE_SERVER_IP
const WINE_IMAGE_SERVER_URL = CONFIG.WINE_IMAGE_SERVER_URL

import { useRef } from 'react';

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "wrap",
            justifyContent: "center",
            padding: '0rem',
            //width: 'auto',
            //backgroundColor: 'gray',
            ///width: '85%',
            width: '100%',
            paddingBottom: '12px',
            //margin: '0rem',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "wrap",
            justifyContent: "flex-start",
            //padding: '1rem',
            width: '100%',
            height: '10rem',
            backgroundColor: 'black',
            padding: '3px',
            ///padding: '0px',
            //margin: '4px',
            marginBottom: '12px',
            borderRadius: '12px',
        }}
    >
        {props.children}
    </Box>
);

const StyledBox3 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "wrap",
            justifyContent: "center",
            padding: '0px',
            margin: '0.5rem',
            width: 'auto',
            padding: '0px',
            backgroundColor: '#424242',
            borderRadius: '5px',
        }}
    >
        {props.children}
    </Box>
);


const FlightWine = (props) => {


    // Extract data from props
    const data = props.item;
    const imgURL = WINE_IMAGE_SERVER_IP + WINE_IMAGE_SERVER_URL + 'label_' + data.image



    const childRef = useRef(null);
    function handleParentClick() {
        // Call a function in the child component
        childRef.current.childFunction();
      }

      let buttonOpacity = '0%'
// onClick={handleParentClick}
    return (
        <div onClick={handleParentClick} >
            <StyledBox2 >
                <Paper   sx={{
                    backgroundImage: `url(${imgURL})`,
                    backgroundSize: 'auto',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% auto',
                    minHeight: '100%',
                    minWidth: '100%',
                    backgroundPosition: 'center', // Add this line to center the image
                    backgroundColor: '#696969',
                    //backgroundColor: 'rgba(105, 105, 105, 0.5)', // Dimmer background color (50% dimmer)
                    //width: '100%',
                    //filter: 'brightness(60%)',
                }}>
                    <StyledBox>



                        <StyledBox3>
                            <Typography align="center" color='white' fontSize='1.2rem' fontWeight='bold' padding='0.5rem' >{data.name}</Typography>
                        </StyledBox3>

                        <ModalComponent
                            style={{ paddingLeft: '0rem', color: 'blue' }}
                            render={true}
                            data={data}
                            ref={childRef}
                            buttonOpacity={buttonOpacity}
                        />

                    </StyledBox>
                </Paper>
            </StyledBox2>

        </div >
    );
};

export default FlightWine;