import React from 'react';
import { connect } from 'react-redux';


import { styled } from '@mui/system';

import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import { TextField } from '@mui/material';

// https://mui.com/material-ui/react-text-field/

////import shareablesImg from '../../images/shareables.webp'
//import entreesImg from '../../images/entrees.webp'
////import pizzasImg from '../../images/pizzas.webp'
////import dessertsImg from '../../images/desserts.webp'
//import bottomBannerImg from '../../images/bottom_banner.webp'
//import wineImg from '../../images/wine.jpg'
//import cocktailsImg from '../images/cocktails.jpg'
////import cocktailsImg2 from '../../images/cocktails_2.png'

import Button from '@mui/material/Button'

// used to detect changes in navigated url
import { useNavigate } from 'react-router-dom';
// used to dispatch action 
import { useSelector, useDispatch } from 'react-redux';
// import of action
// import testAction from "../actions/actions.js"
// import UpdateRoute from "../actions/UpdateRoute.js"

import { useState, useEffect } from "react";




import MenuBar from "../../components/layout/MenuBarDev"
import Footer from "../../components/layout/Footer"

import entreesImg from '../../images/staffpicks.webp'
import bottomBannerImg from '../../images/bottom_banner.jpg'
import wineImg from '../../images/winebook.jpg'

import { Link as RouterLink } from 'react-router-dom';



import SignupForm from "./SignupForm"




const StyledBox = (props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      width: '100%',
      backgroundColor: 'black',
      //height: '50rem',   //height: '69rem',
    }}
  >
    {props.children}
  </Box>
);

const StyledBox2 = (props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '1rem',
      width: '100%',
      backgroundColor: 'black',
      justifyContent: 'center',
      //height: '50rem',   //height: '69rem',
    }}
  >
    {props.children}
  </Box>
);


const StyledPaper = (props) => (
  <Paper
    sx={{
      background: 'black',
      color: 'white',
      align: "center"
    }}
  >
    {props.children}
  </Paper>
);


const StyledTypography = (props) => (
  <Typography
    sx={{
      padding: '0.5rem',
      backgroundColor: 'silver',
      align: "center",
      width: '100%'
    }}
  >
    {props.children}
  </Typography>
);
const StyledTypography2 = (props) => (
  <Typography
    sx={{
      padding: '0.5rem',
      backgroundColor: 'black',
      align: "center",
      width: '100%'
    }}
  >
    {props.children}
  </Typography>
);





















const spacing = '8rem'

const Home = ({ data }) => {


  let navigate = useNavigate();

  const dispatch = useDispatch();






  // function redirectUrl() {
  //   console.log("route change")
  //   //dispatch(UpdateRoute("hello"))
  //   navigate(urlValue);
  // }

  // useEffect(() => {
  //   console.log("effect")
  //   navigate(urlValue)
  // }, [urlValue]);

  // function handleClick(url) {
  //   console.log("route change")
  //   const [urlValue, setUrlValue] = useState(url);
  //   //setUrlValue(url)
  // }

  function handleClick1() {
    ///// console.log("route change")
    //dispatch(UpdateRoute("hello"))
    ////navigate('/searchglasses');
    //navigate('/winelist');
  }
  function handleClick2() {
    ///// console.log("route change")
    //dispatch(UpdateRoute("hello"))
    navigate('/selector');
  }
  function handleClick3() {
    ///// console.log("route change")
    //dispatch(UpdateRoute("hello"))
    navigate('/demo/staffpicks');
  }


  function handleClick4() {
    /////  console.log("route change")
    //dispatch(UpdateRoute("hello"))
    navigate('/demo/winebook');
  }

  function handleClick5() {
    /////  console.log("route change")
    //dispatch(UpdateRoute("hello"))
    navigate('/');
  }

  return (
    <div >
      <StyledBox>


        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MenuBar ></MenuBar>
          </Grid>

          <Paper
           
        //    onClick={handleClick5}

            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
              margin: '2rem',
              fontSize: '4rem',
              backgroundColor: 'rgb(211, 188, 141)'
            }}>

            <Typography style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              fontSize: '1.2rem',
            }}
            >Rouge Wine Selector - Demo Page</Typography>

            <Typography style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              fontSize: '1.2rem',
              paddingTop: '1rem',
            }}
            >Links below and in upper right menu are for pages still under development. </Typography>


            <Typography style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              fontSize: '1.2rem',
              paddingTop: '2rem',
            }}
            >Select 'Main App' to leave development section. </Typography>


            <Typography style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              fontSize: '1rem',
              paddingTop: '1rem',
            }}
            >November 05, 2023</Typography>

          </Paper>

          <StyledBox2>
            <Grid item xs={12} sm={8} md={8} >

              {/* 
              <RouterLink to={{ pathname: "/winelist" }} state={{ displayItem: "GLASSES" }}  >
 */}


              <Card onClick={handleClick3} >
                <Paper
                  style={{
                    margin: '0rem',
                    padding: '10%',
                    border: '0rem',
                    backgroundColor: 'black',
                    backgroundImage: `url(${entreesImg})`,
                    repeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: "80%",
                    height: "auto",
                  }} square
                />
              </Card>

              {/*                 
                <Button component="div" fullWidth style={{ fontSize: '1.3rem', color: 'white' }}>WINE</Button>
                <Typography  align="center" color='white' fontSize='1.3rem' textDecoration='none' >WINE</Typography>
                */}

              {/*                 
                <Button component="div" fullWidth style={{ fontSize: '1.3rem', color: 'white' ,  textDecoration: 'none'}}>WINE</Button>
                 */}

              <Button onClick={handleClick3} component="div" fullWidth style={{ fontSize: '1.3rem', color: 'white', textDecoration: 'none' }}>STAFF PICKS</Button>

              {/* 
              </RouterLink> */}


            </Grid>
          </StyledBox2>




          <StyledBox2>
            <Grid item xs={12} sm={8} md={8}  >
              <Card onClick={handleClick4} >
                <Paper
                  style={{
                    margin: '0rem',
                    padding: '10%',
                    border: '0rem',
                    backgroundColor: 'black',
                    backgroundImage: `url(${wineImg})`,
                    repeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: "80%",
                    height: "auto",
                  }} square
                />
              </Card>

              {/* <Typography onClick={handleClick3} align="center" color='white' fontSize='1.3rem'>FOOD MENU</Typography> */}

              <Button onClick={handleClick4} component="div" fullWidth style={{ fontSize: '1.3rem', color: 'white', textDecoration: 'none' }}>WINE BOOK</Button>

            </Grid>
          </StyledBox2>





          <StyledTypography2></StyledTypography2>
          <StyledTypography></StyledTypography>



          <StyledBox2>
            <Grid item xs={12} sm={8} md={8} >
              {/* <Card >
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${entreesImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card> */}



              {/* 

            <Typography align="center" color='white' fontSize='1.3rem'>JOIN OUR WINE CLUB</Typography>
            <Typography align="center" color='white' fontSize='1.2rem'>And get a free appetizer today!</Typography>
            <Typography align="center" color='white' fontSize='1rem'>Wine Tastings, Exclusive Offers</Typography>
            <Card >
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  paddingTop: '1rem',
                  border: '0rem',
                  backgroundColor: 'black',
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              >
                <TextField id="outlined-basic" label="ENTER PHONE NUMBER OR EMAIL" variant="standard" align="center"
                  style={{
                    backgroundColor: 'gray',
                    width: '100%'
                  }}
                />

                <Button variant="contained"
                  style={{
                    marginTop: '1rem',
                    backgroundColor: 'gray',
                    width: '100%'
                  }}>SEND CONFIRMATION</Button>


              </Paper>
            </Card>

 */}

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '1rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', }}>

                  {/* <Typography align="center" color='white' fontSize='1.2rem' style={{ paddingTop: '3rem', }}>COMING SOON: ROUGE WINE CLUB</Typography>
                  <Typography align="center" color='white' fontSize='1.1rem'>Check back for more details!</Typography>
                  <Typography align="center" color='white' fontSize='1rem' style={{ paddingBottom: '3rem', }}>Launch Date October 2023</Typography> */}

{/* 
                  <SignupForm /> */}


                </div>
              </div>



            </Grid>
          </StyledBox2>



          <StyledBox2>
            <Grid item xs={12} sm={8} md={8} >
              <Card>
                <Paper
                onClick={handleClick5}
                  style={{
                    margin: '0rem',
                    padding: '10%',
                    border: '0rem',
                    backgroundColor: 'black',
                    backgroundImage: `url(${bottomBannerImg})`,
                    repeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                    width: "auto",
                    height: "7rem",
                  }} square
                />
              </Card>

              <Button onClick={handleClick5} component="div" fullWidth style={{ fontSize: '1.3rem', color: 'white', textDecoration: 'none' }}>RETURN TO MAIN APP</Button>

              {/* 

              <Typography onClick={handleClick1} align="center" color='white' fontSize='1.3rem'>WINE CLUB DETAILS</Typography>

               */}

            </Grid>
          </StyledBox2>







        </Grid>
      </StyledBox>
      <Footer></Footer>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data,
  };
};

export default connect(mapStateToProps)(Home);


/*

<Typography style={{textDecoration: 'none'}} align="center" color='white' fontSize='1.3rem'>WINE</Typography>




          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${bottomBannerImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
          </Grid>





















          <Grid item xs={12} sm={8} md={8} >
            <Typography align="center" variant="h6" backgroundColor='black' color='white' >
              COPYRIGHT 2023
            </Typography>
            <Typography align="center" backgroundColor='black' color='white' >
              jdtechdevs.com
            </Typography>
          </Grid>

















          <Grid item xs={12} sm={8} md={8} >
            <Card >
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${cocktailsImg2})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <Typography align="center" color='white' fontSize='1.3rem'>COCKTAILS</Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${shareablesImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <Typography align="center" color='white' fontSize='1rem'>SHAREABLES</Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${entreesImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <Typography align="center" color='white' fontSize='1rem'>ENTREES</Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${pizzasImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <Typography align="center" color='white' fontSize='1rem'>PIZZAS</Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card >
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${dessertsImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <Typography align="center" color='white' fontSize='1rem'>DESSERTS</Typography>
          </Grid>



























































            <StyledPaper >ENTREES</StyledPaper>


            <StyledPaper >PIZZAS</StyledPaper>


            <StyledPaper >DESSERTS</StyledPaper>





            

          <Grid item xs={12} sm={8} md={8} >
            <Button onClick={handleClick} >
              testing
            </Button>
            <Typography variant="h5" component="h2" backgroundColor='black' color='white' >
              2023 JDTECHDEVS.COM
            </Typography>;
          </Grid>















    <div>
      <Grid container justify="center" spacing={spacing}>

      <Grid item xs={6} sm={3}>
        <Box><Menubar ></Menubar></Box>
          
        </Grid>
        <Grid item xs={6} sm={3}>
        <Paper>hello</Paper>
          
        </Grid>

      </Grid>
    </div>





<div >
<Grid container spacing={3}>
  <Grid item xs={12}>
    <Paper >xs=12</Paper>
  </Grid>
  <Grid item xs={12} sm={6}>
    <Paper >xs=12 sm=6</Paper>
  </Grid>
  <Grid item xs={12} sm={6}>
    <Paper >xs=12 sm=6</Paper>
  </Grid>
  <Grid item xs={6} sm={3}>
    <Paper >xs=6 sm=3</Paper>
  </Grid>
  <Grid item xs={6} sm={3}>
    <Paper >xs=6 sm=3</Paper>
  </Grid>

</Grid>
</div>















  return (
    <div >
      <StyledBox>


        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Menubar ></Menubar>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >

            <Paper
              style={{
                margin: '1rem',
                backgroundColor: 'black',
                backgroundImage: `url(${shareablesImg})`,
                repeat: 'no-repeat',
                backgroundSize: 'cover',
                width: "90%",
                height: "10rem"
              }} square>
            </Paper>

            <StyledPaper >SHAREABLES</StyledPaper>

          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${entreesImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <StyledPaper >ENTREES</StyledPaper>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${pizzasImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <StyledPaper >PIZZAS</StyledPaper>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${dessertsImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <StyledPaper >DESSERTS</StyledPaper>
          </Grid>

        </Grid>
      </StyledBox>
    </div >
  );













*/
