import React, { useState } from "react";
import { useTransition, useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
//import ModalComponent from './ModalComponent'


const InfoPage = (props) => {

   //// console.log("props", props)
    // if (props.render == true) {
    // <InfoPage style={{ color: 'blue' , margin: '0rem' , padding: '0rem' }} render={bookPages[page.currentPageId].renderModal} />
    let render = true
    if (render == true) {
        if (props.render.pageNumber == 0) {

            return (

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '3rem',
                    paddingTop: '2rem',

                    opacity: '0.8',
                    width: '15rem',
                    height: '10rem',
                }}>

                </div>

            )


        }
        if (props.render.pageNumber == 1) {

            return (

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '3rem',
                    paddingTop: '2rem',

                    opacity: '0.8',
                    width: '15rem',
                    height: '10rem',
                }}>

                    <h3>This book explains some common wine terms to help you select the perfect wine. Swipe left to learn about:</h3>
                    <p>Light vs Full-Bodied</p>
                    <p>Low vs High Tannin</p>
                    <p>Dry vs Sweet</p>
                    <p>Low vs High Acidity</p>

                </div>

            )


        }
        if (props.render.pageNumber == 2) {

            return (

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '3rem',
                    paddingTop: '2rem',

                    opacity: '0.8',
                    width: '15rem',
                    height: '10rem',
                }}>

                    <p style={{fontSize:'1rem', padding:'0px', margin:'0px', marginTop:'10px', fontWeight: 'bold'}}>Light vs Full-Bodied</p>
                    <p style={{fontSize:'0.8rem'}}>"Light-bodied" refers to wines that are delicate and have a lower alcohol content, showcasing subtle flavors and a refreshing mouthfeel.</p>
                    <p style={{fontSize:'0.8rem'}}>"Full-bodied" wines are more robust, with a higher alcohol content, richer flavors, and a more substantial mouthfeel.</p>
                    <p style={{fontSize:'0.8rem'}}>The distinction between light and full-bodied wines depends on factors such as grape variety, winemaking techniques, and aging.</p>

                </div>

            )


        }
        if (props.render.pageNumber == 3) {

            return (

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '3rem',
                    paddingTop: '2rem',

                    opacity: '0.8',
                    width: '15rem',
                    height: '10rem',
                }}>


                    <p style={{fontSize:'1rem', padding:'0px', margin:'0px', marginTop:'10px', fontWeight: 'bold'}}>Low vs High Tannin</p>
                    <p style={{fontSize:'0.8rem'}}>Tannins are natural compounds found in grape skins, seeds, and stems, which contribute to a wine's structure and texture.</p>
                    <p style={{fontSize:'0.8rem'}}>"Low tannin" wines have smoother and softer characteristics, with less astringency.</p>
                    <p style={{fontSize:'0.8rem'}}>"High tannin" wines exhibit a firm and gripping sensation on the palate.</p>
                    <p style={{fontSize:'0.8rem'}}>Red wines typically have higher tannin levels compared to white wines, and tannin levels can vary based on grape variety and winemaking methods.</p>

                </div>

            )


        }
        if (props.render.pageNumber == 4) {

            return (

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '3rem',
                    paddingTop: '2rem',

                    opacity: '0.8',
                    width: '15rem',
                    height: '10rem',
                }}>

                    <p style={{fontSize:'1rem', padding:'0px', margin:'0px', marginTop:'10px', fontWeight: 'bold'}}>Dry vs Sweet</p>
                    <p style={{fontSize:'0.8rem'}}>The terms "dry" and "sweet" in wine refer to the residual sugar content. </p>
                    <p style={{fontSize:'0.8rem'}}>A "dry" wine contains minimal residual sugar, leaving little sweetness on the palate, while a "sweet" wine has a higher level of residual sugar, resulting in a noticeable sweetness.</p>
                    <p style={{fontSize:'0.8rem'}}>The perception of sweetness can vary based on personal taste preferences.</p>
                    <p style={{fontSize:'0.8rem'}}>Wine styles can range from bone-dry to lusciously sweet, providing diverse options for different occasions.</p>


                </div>

            )


        }
        else {

            return (

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '3rem',
                    paddingTop: '2rem',

                    opacity: '0.8',
                    width: '15rem',
                    height: '10rem',
                }}>








                    <p style={{fontSize:'1rem', padding:'0px', margin:'0px', marginTop:'10px', fontWeight: 'bold'}}>Low vs High Acidity</p>
                    <p style={{fontSize:'0.8rem'}}>Acidity is a key component of wine that contributes to its freshness, crispness, and balance.</p>
                    <p style={{fontSize:'0.8rem'}}>"Low acidity" wines have a softer and smoother taste, with a more rounded mouthfeel.</p>
                    <p style={{fontSize:'0.8rem'}}>"High acidity" wines exhibit a bright and zesty character, often leaving a refreshing tartness on the palate.</p>
                    <p style={{fontSize:'0.8rem'}}>The level of acidity in wine is influenced by factors like climate, grape variety, and winemaking techniques, and it plays a vital role in food pairing and aging potential.</p>



                </div>

            )

        }




    } else {
        return (
            <div></div>
        )
    }





    // <button onClick={toggleModal} style={{ fontSize: '3rem', padding: '2rem', margin: '2rem' }}>BACK</button>
    //  <button style={{ fontSize: '3rem', padding: '2rem', }} onClick={toggleModal}>Open modal</button>








};

export default InfoPage;





/*





                    <h3>This book Explains some common wine terms to help you pick the right one, the terms are as follows:</h3>
                    <p>Light vs Bold</p>
                    <p>Smooth vs Tannic</p>
                    <p>Dry vs Sweet</p>
                    <p>Soft vs Acidic</p>




                    <h3>Light vs Bold</h3>
                    <p>----------------</p>
                    <p>----------------</p>
                    <p>----------------</p>
                    <p>----------------</p>


                    <h3>Smooth vs Tannic</h3>
                    <p>----------------</p>
                    <p>----------------</p>
                    <p>----------------</p>
                    <p>----------------</p>


                    <h3>Dry vs Sweet</h3>
                    <p>----------------</p>
                    <p>----------------</p>
                    <p>----------------</p>
                    <p>----------------</p>

                    <h3>Soft vs Acidic</h3>
                    <p>----------------</p>
                    <p>----------------</p>
                    <p>----------------</p>
                    <p>----------------</p>




const InfoPage = (props) => {

    if (props.render == true) {


        return (

            <div>
               


                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '2rem',
                    paddingTop: '2rem',

                    opacity: '0.8',
                    width:'10rem',
                    height:'10rem',
                }}>
                    <h1 style={{
                        fontSize: '1.5rem', paddingTop: '1rem',paddingBottom: '0rem', margin: '0rem',

                    }}>CHAMPAGNE</h1>






                    <h3 style={{ paddingLeft: '0rem', }} >
                        ___________________________________________________
                    </h3>

                    <h2 style={{
                        fontSize: '1rem',
                        width: '10rem',
                        paddingLeft: '1rem',
                        margin: '0rem',
                        paddingBottom: '1rem',

                    }}>"COME QUICKLY, <br></br>I AM TASTING STARS"</h2>
                    <h3 style={{
                        fontSize: '1rem',
                        width: '5rem',
                        paddingLeft: '1rem',
                        paddingTop: '0rem',
                        margin: '0rem',

                    }}>DOM PERIGNON <br></br> On first tasting bubbly</h3>

                    <h3 style={{ paddingLeft: '5rem', }} >
                        ___________________________________________________
                    </h3>


                    <div style={{
                        fontSize: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '0rem',
                        margin: '0rem',
                    }}>


                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>


                            <h3 style={{ width: '10rem', paddingLeft: '1rem', }} >
                                CONFUSED LIKE MOST OF US AS TO WHICH KIND TO ORDER?
                            </h3>
                            <h2 style={{ width: '10rem', paddingLeft: '1rem', }} >
                                QUICK!
                            </h2>
                            <h3 style={{ width: '10rem', paddingLeft: '1rem', }} >
                                CHECK OUR HANDY CHAMPAGNE CHEAT SHEET BEFORE YOUR DATE REALIZES YOU'RE CLUELESS!
                            </h3>


                        </div>




                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0rem',
                            margin: '0rem',
                        }}>


                            <h2 style={{
                                paddingBottom: '0rem',
                                marginBottom: '0rem',
                            }} >
                                EXTRA BRUT
                            </h2>
                            <h3 style={{
                                padding: '0rem',
                                margin: '0rem',
                                paddingBottom: '2rem',
                            }}  >
                                bone dry, without <br></br> a hint of sweetness
                            </h3>


                            <h2 style={{
                                padding: '0rem',
                                margin: '0rem',
                            }} >
                                BRUT
                            </h2>
                            <h3 style={{
                                padding: '0rem',
                                margin: '0rem',
                                paddingBottom: '2rem',
                            }}  >
                                dry <br></br>(most popular, food-friendly)
                            </h3>



                            <h2 style={{
                                padding: '0rem',
                                margin: '0rem',
                            }} >
                                EXTRA DRY
                            </h2>
                            <h3 style={{
                                padding: '0rem',
                                margin: '0rem',
                                paddingBottom: '2rem',
                            }}  >
                                middle of the road dry, <br></br>(great as an aperitif)
                            </h3>



                            <h2 style={{
                                padding: '0rem',
                                margin: '0rem',
                            }} >
                                DEMI-SEC
                            </h2>
                            <h3 style={{
                                padding: '0rem',
                                margin: '0rem',
                                paddingBottom: '2rem',
                            }}  >
                                pretty sweet, <br></br>(pair with fruit and dessert)
                            </h3>




                        </div>



                    </div>



                    <h3 style={{ paddingLeft: '5rem',   margin : '0rem' }} >
                        ___________________________________________________
                    </h3>


                    {/* <h2 style={{
                        width: '25rem',
                        paddingLeft: '0rem',
                        margin: '0rem',
                        paddingBottom: '0rem',

                    }}>"NO MATTER WHICH YOU PICK, <br></br>YOU CAN'T GO WRONG"</h2> }


                    





                    </div>

            
                    </div>
        
        
        
        
        
        
        
                )
        
        
            } else {
                return (
                    <div></div>
                )
            }
        
        
        
        
        
            // <button onClick={toggleModal} style={{ fontSize: '3rem', padding: '2rem', margin: '2rem' }}>BACK</button>
            //  <button style={{ fontSize: '3rem', padding: '2rem', }} onClick={toggleModal}>Open modal</button>
        
        
        
        
        
        
        
        
        };
        
        export default InfoPage;









*/
















