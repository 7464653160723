import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import { connect } from 'react-redux';
import { useState, useEffect } from "react";

import Glasses from './glasses/Glasses'
import Bottles from './bottles/Bottles'
import Flights from './flights/Flights'




const devModeBool = false




const RenderSelector = (props) => {

    // Extract data from props
    ////const { data } = props;
    const { stillLoading } = props;
    const { activeMenu } = props;


    let renderDisplayItems = [{ name: 'empty' }]

    if (devModeBool == true) {
        console.log("still loading ", stillLoading)
    }



    renderDisplayItems = props.reducerData.data

    // useEffect(() => {
    //     renderDisplayItems = props.reducerData.displayData.wineSearchResults
    // }, []);

    // try {
    //     renderDisplayItems = props.reducerData.displayData.wineSearchResults
    // } catch {
    //     renderDisplayItems = [{name: 'empty'}]
    // }


    //console.log("RENDERSELECTOR props: ", displayItems)
    if (stillLoading == true) {
        return (
            <div></div>
        )
    } else {

        if (activeMenu.toLowerCase() == 'glasses') {
            return (
                <div>
                    <Glasses></Glasses>
                </div >
            );
        }
        if (activeMenu.toLowerCase() == 'flights') {
            return (
                <div>
                    <Flights></Flights>
                </div >
            );
        }
        if (activeMenu.toLowerCase() == 'bottles') {
            return (
                <div>
                    <Bottles></Bottles>
                </div >
            );
        }
        else {
            return (
                <div>ERROR</div >
            );
        }


        //return (
        //   <div>


        {/* 
                <h1 style={{ backgroundColor: 'white' }}>SEARCH RESULTS { activeMenu }</h1>
                */}


        {/* {renderDisplayItems.map((item, index) => (
                    <Typography
                        key={`item_${index}`}
                        style={{
                            color: 'gray',
                        }}
                    >{item.name}</Typography>
                ))} */}

        //   </div >
        //);
    }

};

//export default RenderSelector;


const mapStateToProps = (state) => {
    const displayMode = state.reducer4
    const displayData = state.wineData
    const reducerData = {
        data: state.wineData.wineSearchResults
        // displayMode: displayMode,
        // displayData: displayData,
    }
    return {
        reducerData,
    };
};

export default connect(mapStateToProps)(RenderSelector);
