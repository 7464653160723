
import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            //backgroundColor: 'gray',
            justifyContent: "space-between",
            paddingBottom: '0.5rem',
            paddingLeft: '15px',
            paddingRight: '15px',
            //opacity: '90%',

        }}
    >
        {props.children}
    </Box>
);

const ModalComponentPrice = (props) => {

    // Extract data from props
    const { wineDetails } = props

    ///// console.log('ResultItemPrice ', wineDetails)

    if (wineDetails.price_glass_8_oz == null) {


        return (
            <div>

                <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold', paddingTop: '1rem', paddingBottom: '1rem'}}>BOTTLE: ${wineDetails.price_bottle}</Typography>

            </div >
        );



    } else {


        return (
            <div>

                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', }}>GLASS 5 OZ: ${wineDetails.price_glass_5_oz}</Typography>
                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', }}>GLASS 8 OZ: ${wineDetails.price_glass_8_oz}</Typography>
                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', }}>BOTTLE: ${wineDetails.price_bottle}</Typography>
            </div >
        );


    }

};

export default ModalComponentPrice;
