



// export const UpdateSearchPanelFields = (argument) => ({
//     type: 'UPDATE_SEARCH_PANEL_FIELDS',
//     payload: argument,
//   });



export const UpdateSearchPanelFields = (argument) => async (dispatch) => {


  const devModeBool = false

  // if (devModeBool == true) {

  // }



  try {

    if (devModeBool == true) {
      console.log("argument", argument)
    }



    let searchPanelData = argument

    if (argument.mode == "slider") {

      if (devModeBool == true) {
        console.log("UpdateSearchPanelFields argument ", argument)
      }

      
      dispatch(UpdateSliderValues(searchPanelData));
    } else {
      dispatch(UpdateSearchPanelFields2(searchPanelData));
    }


    /////let searchResultsData = argument.searchResults
    dispatch(UpdateWineSearchResults(searchPanelData));

  } catch (error) {
    // Handle any error if necessary
    if (devModeBool == true) {
      console.log("ERROR")
    }

    
  }
};

const UpdateSearchPanelFields2 = (payloadObject) => ({
  type: 'UPDATE_SEARCH_PANEL_FIELDS',
  payload: payloadObject,
});

const UpdateSliderValues = (payloadObject) => ({
  type: 'UPDATE_SLIDER_VALUES',
  payload: payloadObject,
});


const UpdateWineSearchResults = (payloadObject) => ({
  type: 'UPDATE_WINE_SEARCH_RESULTS',
  payload: payloadObject,
});

