
import { fetchItems } from '../api/MenuData';

export const UpdateMenu = (argument) => async (dispatch) => {
  try {

    const fetchedItems = await fetchItems(argument);
    // Call the fetchItems function

  /////  console.log("UPDATE_MENU: ", argument, " ", fetchedItems)

    const payloadObject = {
      display: argument,
      items: fetchedItems,
    }

    dispatch(fetchItemsSuccess(payloadObject));
  } catch (error) {
    // Handle any error if necessary
  }
};

const fetchItemsSuccess = (payloadObject) => ({
  type: 'UPDATE_MENU_RESULTS',
  payload: payloadObject,
});