import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';
import { Checkbox } from '@mui/material';
//import { createTheme, ThemeProvider, Button } from '@mui/material';

import { styled } from '@mui/material/styles';

import Add from
    "@mui/icons-material/Add";
import Remove from
    "@mui/icons-material/Remove";

import IconButton from "@mui/material/IconButton";

import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";

import { useEffect } from 'react';








const devModeBool = false

// if (devModeBool == true) {

// }




const StyledPaper = (props) => (
    <Paper
        sx={{
            backgroundColor: 'gray', color: 'black', height: '6rem' // slategray / dimgray / gray / silver
        }}
    >
        {props.children}
    </Paper>
);

const StyledBoxCheck = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: 'center',
            width: '100%',
        }}
    >
        {props.children}
    </Box>
);

const LargeCheckBox = styled(Checkbox)(({ theme }) => ({
    transform: "scale(1.5)",
    color: "rgb(171,18,57)",
    // "&checked": {
    //             color: "rgb(171,18,57)"
    //           }
    //backgroundColor: "#3c44b1"
}));















const CheckboxComponent = (props) => {

    // Extract data from props
    const { item } = props;
    const { onData } = props;
    const { activeMenu } = props

    const labelNumberCharacters = item.property[0].length
    let selectedFontSize = '1.1rem'
    ////console.log("===================rendering checkbox====================", item, item.property[0], item.property[1])
    ///console.log("===================rendering checkbox====================", onData)


    const toggleStatusInitial = item.property[1]

    useEffect(() => {
        // This code will run when the value of `yourProp` changes
        // Perform any side effects or logic you want here
        //// setOpen2(toggleStatusInitial)
        // Delayed function execution



        if (open2 == true) {
            setTimeout(function () {
                // Code to be executed after the delay

                if (devModeBool == true) {
                    console.log('Delayed function executed!');
                }



                setOpen2(toggleStatusInitial)
            }, 250); // Delay of 2000 milliseconds (2 seconds)
        }
        else {
            setOpen2(toggleStatusInitial)
        }



        // if (toggleStatusInitial == true) {
        //     setTimeout(function () {
        //         // Code to be executed after the delay
        //         console.log('Delayed function executed!');
        //         setOpen2(false)
        //     }, 1500); // Delay of 2000 milliseconds (2 seconds)
        // }


        return () => {
            // This cleanup code will run before the component is unmounted or when `yourProp` changes again
            // Perform any necessary cleanup here
        };
    }, [toggleStatusInitial]);


    const [open2, setOpen2] = useState(toggleStatusInitial);














    const { triggerUpdateCheckbox } = props

    useEffect(() => {

        if (triggerUpdateCheckbox == true) {
            if (devModeBool == true) {
                console.log("triggerUpdateCheckbox true")
            }


            if (open2 == true) {
                setTimeout(function () {
                    // Code to be executed after the delay

                    if (devModeBool == true) {
                        console.log('Delayed function executed!');
                    }

                    setOpen2(false)
                }, 250); // Delay of 2000 milliseconds (2 seconds)
            }


        }

    }, [triggerUpdateCheckbox]);













    if (labelNumberCharacters > 12) {
        selectedFontSize = '1.0rem'
    }
    // else if (labelNumberCharacters > 10) {
    //     selectedFontSize = '0.8rem'
    // }



    //console.log("item ", item)
    const tastingNotes = item.tastingNotes
    const tastingNotesLength = item.tastingNotes.length

    let noteTextColor = []
    let fontTextDecoration = []
    // text-decoration: line-through;
    tastingNotes.map((note, index) => {
        if (note[1] == false) {
            noteTextColor.push('gray')
            fontTextDecoration.push('line-through')
        } else {
            noteTextColor.push('rgb(171,18,57)')
            fontTextDecoration.push('initial')
        }
    })

    function toggleTastingNote(argument) {
        if (devModeBool == true) {
            console.log("taste toggle ", argument[0])
            console.log(argument)
        }


        onData(argument);
    }

    //const [noteTextColor, setNoteTextColor] = useState('gray');
    // if (item){

    // }
    // const noteTextColor = 'gray'



    const [expanded, setExpanded] = useState(false);

    //const [expandable, setExpandable] = useState(false);
    let expandableValue = true
    if (tastingNotesLength <= 4) {
        expandableValue = false
    }

    //setExpandable(expandableValue)
    ///   const [expandable, setExpandable] = useState(expandableValue);

    //console.log('tastingNotesLength', tastingNotesLength, 'expandableValue', expandableValue, 'expandable', expandable)

    if (activeMenu != 'BOTTLES'){
        return (

            <Grid item xs={3} sm={3}>
                <StyledPaper >
                    <StyledBoxCheck >
                        <Typography style={{ textAlign: 'center', fontSize: '0.8rem' }} height='3rem' fontWeight='bold' fontSize={selectedFontSize}>{item.property[0]}</Typography>
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                            }}
                        >
    
                            <LargeCheckBox
                                checked={item.property[1]}
                                onClick={() => {
                                    toggleTastingNote(item.property)
                                    setExpanded(false)
                                }}
                            >
                            </LargeCheckBox>
    
    
    
                            <IconButton
                                onClick={() => {
                                    setOpen2(!open2)
                                    setExpanded(false)
                                }}
    
                                aria-label="expand"
                                size="small"
                            ///// backgroundColor= "rgba(211,211,211,0.4)"
                            >
                                {open2 ? <Remove />
                                    : <Add />}
                            </IconButton>
    
    
    
    
    
    
    
    
    
    
    
                        </Grid>
    
    
                    </StyledBoxCheck>
                </StyledPaper>
                <div style={{
                    ///// backgroundColor: "rgba(211,211,211,0.4)"
                }}>
    
    
                    <Collapse in={open2} timeout="auto"
                        unmountOnExit>
                        <CardContent
                            sx={{
                                paddingTop: '5px',
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                margin: '0px',
                                border: '0px',
                            }}
                        >
                            <Container sx={{
                                height: 'auto',
                                padding: '0px',
                                margin: '0px',
                                border: '0px',
                                lineHeight: 1,
                                width: 'auto',
                                backgroundColor: 'silver',
                                display: 'flex',
                                flexDirection: 'column',
                                //flexWrap: "wrap",
                                justifyContent: "center",
                            }}>
    
    
    
                                {tastingNotes.slice(0, expanded ? tastingNotes.length : 4).map((note, index) => (
                                    <Button
                                        key={`button_${index}`}
                                        onClick={() => { toggleTastingNote(note) }}
                                        style={{
                                            color: noteTextColor[index],
                                            textDecoration: fontTextDecoration[index],
                                            display: 'block',
                                        }}
                                    >{note[0]}</Button>
                                ))}
    
                                <IconButton
                                    onClick={() => setExpanded(!expanded)}
                                    aria-label="expand"
                                    size="small"
                                    style={{ display: expandableValue ? 'block' : 'none' }}
                                ///// backgroundColor= "rgba(211,211,211,0.4)"
                                >
                                    {expanded ? <Remove />
                                        : <div style={{display: 'flex' , justifyContent: 'space-around' }}><Typography>{tastingNotesLength}</Typography> <Add /></div>}
                                </IconButton>
    
    
    
    
    
                                {/* {tastingNotes.slice(0, expanded ? data.length : 4).map((note, index) => (
                                    <Button
                                        key={`button_${index}`}
                                        onClick={() => { toggleTastingNote(note) }}
                                        style={{
                                            color: noteTextColor[index],
                                            textDecoration: fontTextDecoration[index],
                                            display: 'block',
                                        }}
                                    >{note[0]}</Button>
                                ))} */}
    
    
    
    
                            </Container>
                        </CardContent>
                    </Collapse>
    
    
                </div>
            </Grid >
    
        );
    } else {
        return (

            <Grid item xs={3} sm={3}>
                <StyledPaper >
                    <StyledBoxCheck >
                        <Typography style={{ textAlign: 'center', fontSize: '0.8rem' }} height='3rem' fontWeight='bold' fontSize={selectedFontSize}>{item.property[0]}</Typography>
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                            }}
                        >
    
                            <LargeCheckBox
                                checked={item.property[1]}
                                onClick={() => {
                                    toggleTastingNote(item.property)
                                    setExpanded(false)
                                }}
                            >
                            </LargeCheckBox>
    
    
    

    
    
    
    
    
    
    
    
    
    
                        </Grid>
    
    
                    </StyledBoxCheck>
                </StyledPaper>

            </Grid >
    
        );
    }

};

export default CheckboxComponent;



{/* <Button>Apple</Button>
<Button>Pear</Button>
<Button>Citrus</Button>
<Button>Peach</Button>
<Button>Floral</Button>
<Button>Blackberry</Button> */}



// <Grid item xs={3} sm={3}>
// <StyledPaper >
//     <StyledBoxCheck >
//         <Typography fontWeight='bold' fontSize='1.1rem'>White</Typography>
//         <LargeCheckBox
//             checked={valueCheckBox2}

//             onChange={(e) => {
//                 let checkedStatusReverse = valueCheckBox2
//                 ////console.log("checkedStatus", valueCheckBox2, checkedStatusReverse)

//                 if (valueCheckBox2 == true) { checkedStatusReverse = false }
//                 else { checkedStatusReverse = true }
//                 ////console.log("value", value)
//                 ////console.log("checkedStatus", valueCheckBox2, checkedStatusReverse)
//                 setValueCheckBox2(checkedStatusReverse)
//                 //updateReduxQueryStatus()
//             }}
//         >
//         </LargeCheckBox>
//     </StyledBoxCheck>
// </StyledPaper>
// </Grid>








