

// export const fetchItems = () => {
//     // Perform the data retrieval here, either from a hardcoded array or a database fetch
//     // Return the retrieved items as a promise
//     return Promise.resolve([
//       { id: 1, type: 'title', title: 'Section 1' },
//       { id: 2, type: 'item', content: 'Item 1' },
//       { id: 3, type: 'item', content: 'Item 2' },
//       { id: 4, type: 'title', title: 'Section 2' },
//       { id: 5, type: 'item', content: 'Item 3' },
//       { id: 6, type: 'item', content: 'Item 4' },
//     ]);
//   };


// export const fetchItems = () => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve([
//         { id: 1, type: 'title', title: 'Section 1' },
//         { id: 2, type: 'item', content: 'Item 1' },
//         { id: 3, type: 'item', content: 'Item 2' },
//         { id: 4, type: 'title', title: 'Section 2' },
//         { id: 5, type: 'item', content: 'Item 3' },
//         { id: 6, type: 'item', content: 'Item 4' },
//         { id: 7, type: 'title', title: 'Section 3' },
//         { id: 8, type: 'item', content: 'Item 5' },
//         { id: 9, type: 'item', content: 'Item 6' },
//       ]);
//     }, 10); // 1 second timeout
//   });
// };



export const fetchItems = (argument) => {
  return new Promise((resolve) => {
    if (argument == 'BRUNCH') {
      resolve([

        {
          id: 1, type: 'title', title: 'BRUNCH SHAREABLES', content: [

            {
              id: 2, type: 'item', content: 'Cheese Board',
              imageURL: ['rouge_logo.png'],
              description: 'Honeycomb, Marcona Almonds, Fig Jam, Crackers'
            },
            {
              id: 3, type: 'item', content: 'Charcuterie Board',
              imageURL: ['rouge_logo.png'],
              description: 'Cheese and Salumi, Honeycomb, fig jam, grilled bread, rosemary olives, apricots, fresh berries'
            },
            {
              id: 4, type: 'item', content: 'Roasted Olives',
              imageURL: ['rouge_logo.png'],
              description: ''
            },

          ]
        },







        {
          id: 5, type: 'title', title: 'BRUNCH SMALL PLATES', content: [

            {
              id: 6, type: 'item', content: '7x Farms Steak Tartare*',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 7, type: 'item', content: 'French Onion Soup',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 8, type: 'item', content: 'Smoked Beet Salad',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 9, type: 'item', content: 'Truffle Fries',
              imageURL: ['rouge_logo.png'],
              description: ''
            },


          ]
        },



        {
          id: 18, type: 'title', title: 'BRUNCH ENTREES', content: [

            {
              id: 19, type: 'item', content: 'Biscuits & Gravy',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 20, type: 'item', content: 'Fried Chicken',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 21, type: 'item', content: 'Balsamic Glazed Salmon',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 22, type: 'item', content: 'Brunch burger',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 23, type: 'item', content: 'Daily omelet',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 24, type: 'item', content: 'Prime rib hash',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 25, type: 'item', content: 'Rouge breakfast',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 26, type: 'item', content: 'Croque madame',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 27, type: 'item', content: 'Peri Peri Wings & waffles',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 28, type: 'item', content: 'Classic Benedict',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 29, type: 'item', content: '7x farms wagyu strip and eggs',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 30, type: 'item', content: 'Smoked Salmon Avocado Toast',
              imageURL: ['rouge_logo.png'],
              description: ''
            },


          ]
        },



        {
          id: 31, type: 'title', title: 'BRUNCH DRINKS', content: [



            {
              id: 32, type: 'item', content: 'Bottomless Mimosas, Bloody Marys, & Micheladas',
              imageURL: ['rouge_logo.png'],
              description: ''
            },


          ]
        },

      ]);
    }
    else if (argument == 'HAPPY HOUR') {
      resolve([

        {
          id: 1, type: 'title', title: '$10 Small Bites', content: [



            {
              id: 2, type: 'item', content: 'Chili Shrimp Hushpuppies',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 3, type: 'item', content: 'Mini Cheese Board',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 4, type: 'item', content: 'Peri Peri Wings',
              imageURL: ['rouge_logo.png'],
              description: ''
            },


          ]
        },



        {
          id: 5, type: 'title', title: '$10 Signature Cocktails', description: '', content: [


            {
              id: 6, type: 'item', content: 'Sazerac',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 7, type: 'item', content: 'Paloma',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 8, type: 'item', content: 'Negroni',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 9, type: 'item', content: 'Aviation',
              imageURL: ['rouge_logo.png'],
              description: ''
            },


          ]
        },



        {
          id: 10, type: 'title', title: '$10 Mini Martini', content: [

            {
              id: 11, type: 'item', content: 'Dry',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 12, type: 'item', content: 'Dirty',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 13, type: 'item', content: 'Espresso',
              imageURL: ['rouge_logo.png'],
              description: ''
            },


          ]
        },



        {
          id: 14, type: 'title', title: '$10 Wines', description: '', content: [



            {
              id: 15, type: 'item', content: 'Arca Nova',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 16, type: 'item', content: 'Bisol “Jeio”',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 17, type: 'item', content: 'Wildhurst',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 18, type: 'item', content: 'Barricas',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 19, type: 'item', content: 'Rouge Sangria',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 20, type: 'item', content: 'Honoro Vero',
              imageURL: ['rouge_logo.png'],
              description: ''
            },


          ]
        },




      ]);
    }
    else {
      resolve([

        {
          id: 200, type: 'title', title: 'NEW ITEMS', content: [


            {
              id: 201, type: 'item', content: 'NEW Grilled Artichoke (Shared Plate)', price: '$12.00',
              imageURL: [
                "001_grilled_artichoke_01.jpg",
                "001_grilled_artichoke_02.jpg",
              ],
              allergens: "Allergens: EO (EGGS?), SEO (SESAME?)",
              description: '3 Grilled artichokes halves served on a stone slab',
              detail: "3 grilled half artichokes served on harissa aioli. Artichokes are tossed with garlic oil, seasoned with salt and pepper and grilled until charred. Harissa aioli is a smooth and slightly spicey sauce used in north African and Mediterranean cooking and is made of mayonnaise, harissa, garlic, and salt."
              // "3 Grilled artichokes halves served on a stone slab with lemon sauce"
            },
            {
              id: 202, type: 'item', content: 'NEW Foie Gras Toast PBJ (Small Plate)', price: '$12.00',
              imageURL: [
                "002_foie_gras_pbj_01.jpg",
                "002_foie_gras_pbj_02.jpg",
                "002_foie_gras_pbj_03.jpg",
              ],
              allergens: "Allergens: GFO (GLUTEN?), EO (EGGS?)",
              description: 'Fois Gras PBJ sandwich served with potato chips',
              detail: "Toasted brioche bread topped with onion jam, mixed berry gastrique, and pan seared foie gras served with house fried chips. Foie gras is fatty duck liver and has a buttery texture. Onion jam consists of finely diced white onion, extra virgin olive oil, parsley, rosemary, and bay leaf, sugar, sherry vinegar. Berry gastrique contains blue and black berries, balsamic vinegar, and sugar. Prosciutto is thinly sliced and baked until crispy."
              //"Fois Gras PBJ sandwich served with potato chips"
            },
            {
              id: 203, type: 'item', content: 'NEW Carpaccio (Small Plate)', price: '$15.00',
              imageURL: [
                "003_caprese_01.jpg",
                "003_caprese_02.jpg",
              ],
              description: 'Thinly sliced and seasoned eye of Round beef served with grilled bread.',
              allergens: "Allergens: DO (DAIRY?), GFO (GLUTEN?)",
              detail: "Thinly sliced eye of Round beef seasoned with salt, pepper, garlic seared to brown the edges. Layered flat on a plate and topped with anchovy aioli, capers, shaved parmesan, maldon salt, fresh black pepper, and watercress. Served with grilled bread. Anchovy aioli: anchovies, mayonnaise, black garlic, lemon peel, extra virgin olive oil. Named after painter Vittore Carpaccio famous for his use of whites and reds."
            },
            {
              id: 204, type: 'item', content: 'NEW Black Trumpet Mushroom Strudel (Small Plate)', price: '$11.00',
              imageURL: [
                "004_mushroom_strudel_01.jpg",
                "004_mushroom_strudel_02.jpg",
              ],
              description: 'Mushroom Strudel with taleggio fondue',
              allergens: "Allergens: Gluten (G), Dairy (D)",
              detail: "Roasted Hazel Dell Mushrooms from Longmont, CO mixed with mascarpone cheese, parmesan, cream, parsley, salt, and pepper. Wrapped in phyllo dough and baked until golden brown. Served open faced on a taleggio fondue with a ramekin of fondue on the side. Taleggio fondue white wine, salt, pepper, shallots, taleggio cheese. "
              //"Mushroom Strudel with white sauce"
            },
            {
              id: 205, type: 'item', content: 'NEW Caprese Pasta (Small Plate)', price: '$10.00',
              imageURL: [
                "005_pesto_basil_pasta_01.jpg",
                "005_pesto_basil_pasta_02.jpg",
                "005_pesto_basil_pasta_03.jpg",
              ],
              allergens: "Allergens: Gluten (G), Dairy (D), Tree Nut (T)",
              description: 'Spaghetti with pesto basil sauce, mozarella and tomato',
              detail: "Sauteed tomatoes tossed with house made basil, pine nuts, spaghetti, and mozzarella balls. Basil pesto: basil, garlic, parmesan, salt, pepper, and extra virgin olive oil. Garnished with whole basil leaves and maldon salt."
            },
            {
              id: 206, type: 'item', content: 'NEW Grilled Watermelon Salad (Salads & Soups)', price: '$12.00',
              imageURL: [
                "006_grilled_watermelon_salad_01.jpg",
                "006_grilled_watermelon_salad_02.jpg",
                "006_grilled_watermelon_salad_03.jpg",
              ],
              allergens: "Allergens: Dairy (D)",
              description: 'Hard grilled watermelon slice on top of a bed of arugula',
              detail: "Hard grilled watermelon slice on top of a bed of arugula tossed in house poppyseed vinaigrette with goat cheese crumbs and pickled watermelon rind. Poppyseed vinaigrette: champagne vinegar, sugar, shallot, poppyseed, salt, ground mustard, extra virgin olive oil. Pickled watermelon rind: peeled white watermelon rind, apple cider vinegar, water, sugar, ginger, salt, allspice, star anise."
            },
            {
              id: 207, type: 'item', content: 'NEW Togarashi Seared Tuna (Entree)', price: '$28.00',
              imageURL: [
                "007_seared_ahi_01.jpg",
                "007_seared_ahi_02.jpg",
                "007_seared_ahi_03.jpg",
              ],
              description: '6oz ahi tuna cut served with sauteed napa cabbage and kimchi',
              allergens: "Allergens: GFO (GLUTEN?), Dairy (D) ",
              detail: "6oz ahi tuna cut into rectangles, coated in togarashi spice and pan seared served rare on top of sauteed napa cabbage, water chestnuts, soy sauce, sake, sesame oil, and red dragon sauce. Garnished with a togarashi seasoned hard grilled half bok choy, with a side of kimchi."
            },
            {
              id: 208, type: 'item', content: 'NEW Seared Mango Salmon (Entree)', price: '$25.00',
              imageURL: [
                "008_mango_salmon_01.jpg",
                "008_mango_salmon_02.jpg",
                "008_mango_salmon_03.jpg",
              ],
              description: '8oz Verlasso Salmon pan seared and topped with mango ginger curry sauce',
              allergens: "Allergens: Seafood (S)",
              detail: "8oz Verlasso Salmon pan seared on each side, topped with mango ginger curry sauce, and baked. Served on sauteed quinoa chopped rainbow chard, red onions. Green curry ginger: ginger, sugar, green curry paste, soda water, mango, blended to chunky. Verlasso salmon is a sea farmed raised salmon from the southern coast of Chile. This salmon is heavy in fish oil and stays moist even when cooked well done."
            },
            {
              id: 209, type: 'item', content: 'NEW Fig & Prosciutto Pizza (Pizza)', price: '$18.00', imageURL: ['grilled_artichoke.jpeg'],
              imageURL: [
                "009_fig_arugula_pizza_01.jpg",
                "009_fig_arugula_pizza_02.jpg",
                "009_fig_arugula_pizza_03.jpg",
                "009_fig_arugula_pizza_04.jpg",
              ],
              description: "10 inch pizza with roasted garlic, fig, prosciutto, arugula",
              allergens: "Allergens: GFO (GLUTEN?), DFO (DAIRY?), Seafood (S)",
              detail: "Roasted garlic oil base topped with fontina, roasted garlic. Baked until golden brown. Garnished with arugula and prociutto, finished with fig honey and maldon salt."
              //"Fig and Arugula Pizza"
            },

          ]
        },


        {
          id: 1, type: 'title', title: 'SHAREABLES', content: [


            {
              id: 2, type: 'item', content: 'Cheese Board', price: '$16.00',
              imageURL: ['rouge_logo.png'],
              //imageURL: ['cheese_board.jpg'],
              allergens: "Allergens: Gluten, Dairy",
              description: '3 Chef Selected Cheeses, Honeycomb, Marcona Almonds, Fig Jam, Crackers',
              detail: "Three chef selected cheeses served on a slate tile with honeycomb, marcona almonds, fig jam, crackers, fresh berries, balsamic vinegar, honey, salt and pepper."
            },
            {
              id: 3, type: 'item', content: 'Charcuterie Board', price: '$24.00',
              imageURL: ['rouge_logo.png'],
              //imageURL: ['charcuterie_board.jpeg'],
              allergens: "Allergens: Gluten, Dairy",
              description: 'Chef Selection cheese and Salami, Honeycomb, fig jam, grilled bread, rosemary olives, apricots, fresh berries',
              detail: "Chef selected cheese and salami served on a slate tile. Served with honeycomb, marcona almonds, fig jam, crackers, fresh berries, rosemary olives, dried apricots."
            },
            {
              id: 4, type: 'item', content: 'Roasted Olives', price: '$6.00',
              imageURL: ['rouge_logo.png'],
              //imageURL: ['roasted_olives.jpeg'],
              description: 'Olives with Ciabatta bread',
              allergens: "Allergens: Gluten",
              detail: "Pitted country mix olives tossed with olive oil, roasted garlic, rosemary, orange peel, and crushed red pepper flake. Heated in a cast iron pan and served with grilled ciabatta bread."
            },
            {
              id: 105, type: 'item', content: 'Grilled Artichoke', price: '$12.00',
              imageURL: ['rouge_logo.png'],
              //imageURL: ['grilled_artichoke.jpeg'],
              description: '3 grilled half artichokes served on harissa aioli',
              allergens: "Allergens: EO, SEO",
              detail: "3 grilled half artichokes served on harissa aioli. Artichokes are tossed with garlic oil and seasoned with salt and pepper and grilled until charred. Harissa aioli is mayonnaise, harissa, garlic, and salt all blended until smooth. Harissa is a slightly spicey paste that comes from north African and Mediterranean cooking."
            },
          ]
        },

        {
          id: 5, type: 'title', title: 'SMALL PLATES', content: [


            {
              id: 6, type: 'item', content: 'Roasted Brussel Sprouts',
              imageURL: ['rouge_logo.png'],
              description: 'Chili Threads, Maple Glaze, Crispy Pancetta'
            },
            {
              id: 7, type: 'item', content: 'Spam & Scallops',
              imageURL: ['rouge_logo.png'],
              description: 'Foie gras mousse, Blood Orange Gastrique, Crispy Spam'
            },
            {
              id: 8, type: 'item', content: 'Blistered Shishito Peppers',
              imageURL: ['rouge_logo.png'],
              description: 'Sea Salt, Black Garlic Aioli'
            },
            {
              id: 9, type: 'item', content: 'Truffle Fries',
              imageURL: ['rouge_logo.png'],
              description: 'Roasted Garlic Aioli, Parmesan'
            },
            {
              id: 10, type: 'item', content: 'Steak Tartare',
              imageURL: ['rouge_logo.png'],
              description: 'Dijon Mustard, Cornichons, Fried Capers, Quail Egg Yolk,'
            },
            {
              id: 11, type: 'item', content: 'Burrata',
              imageURL: ['rouge_logo.png'],
              description: 'House Smoked Tomatoes, Roasted Butternut Squash, Arugula, Evoo, Balsamic, Served with Crostini'
            },
            {
              id: 12, type: 'item', content: 'Smoked Beet Salad',
              imageURL: ['rouge_logo.png'],
              description: 'Gold and Red Beets, Pomegranate vin, Roasted Pepitas, Herbed Goat Cheese'
            },
            {
              id: 13, type: 'item', content: 'Chili Shrimp Hushpuppies',
              imageURL: ['rouge_logo.png'],
              description: 'Spiced Honey'
            },
            {
              id: 14, type: 'item', content: 'French Onion Soup',
              imageURL: ['rouge_logo.png'],
              description: 'Gruyere Cheese, Garlic Crouton'
            },
            {
              id: 15, type: 'item', content: 'Peri Peri Wings',
              imageURL: ['rouge_logo.png'],
              description: 'Blueberry Habanero Sauce'
            },
            {
              id: 16, type: 'item', content: 'Truffle Mac and Cheese',
              imageURL: ['rouge_logo.png'],
              description: ''
            },
            {
              id: 17, type: 'item', content: 'Bone Marrow',
              imageURL: ['rouge_logo.png'],
              description: '7x Farms Canoe Cut Beef Bone, Roasted with Evoo, Thyme, Maldon, Garnished with Black Truffle, and served with Ciabatta'
            },

          ]
        },

        {
          id: 18, type: 'title', title: 'ENTREES', content: [

            {
              id: 19, type: 'item', content: 'Fried Chicken',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 20, type: 'item', content: 'Balsamic Glazed Salmon',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 21, type: 'item', content: 'Bolognese',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 22, type: 'item', content: 'Rouge Burger',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 23, type: 'item', content: 'Coffee Braised Short Rib',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 24, type: 'item', content: '7X Farms NY Strip Steak Frites',
              imageURL: ['rouge_logo.png'],
              description: '',
            },

          ]
        },


        {
          id: 25, type: 'title', title: 'PIZZAS', content: [


            {
              id: 26, type: 'item', content: 'Fungi Pizza',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 27, type: 'item', content: 'Steak Pizza',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 28, type: 'item', content: 'Margarita Pizza',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 29, type: 'item', content: 'Pear Gorgonzola',
              imageURL: ['rouge_logo.png'],
              description: '',
            },


          ]
        },

        {
          id: 30, type: 'title', title: 'DESSERTS', content: [


            {
              id: 31, type: 'item', content: 'Creme Brule',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 32, type: 'item', content: 'Tiramisu',
              imageURL: ['rouge_logo.png'],
              description: '',
            },
            {
              id: 33, type: 'item', content: 'Chocolate Cake',
              imageURL: ['rouge_logo.png'],
              description: '',
            },

          ]
        },

      ]);
    }

  });
};



















/*



price: 

below 80
80-140
140-180
180-250
250-500
400-2500






SAVE FOR LATER

advanced search
type of grape
region










flavor notes search feature:
  top 





        { id: 1, type: 'title', title: 'BRUNCH SHAREABLES' },
        { id: 2, type: 'item', content: 'Cheese Board', description: 'Honeycomb, Marcona Almonds, Fig Jam, Crackers' },
        { id: 3, type: 'item', content: 'Charcuterie Board' , description: 'Cheese and Salumi, Honeycomb, fig jam, grilled bread, rosemary olives, apricots, fresh berries' },
        { id: 4, type: 'item', content: 'Roasted Olives' , description: '' },

        { id: 5, type: 'title', title: 'BRUNCH SMALL PLATES' },
        { id: 6, type: 'item', content: '7x Farms Steak Tartare*'  , description: '' },
        { id: 7, type: 'item', content: 'French Onion Soup'  , description: '' },
        { id: 8, type: 'item', content: 'Smoked Beet Salad'  , description: '' },
        { id: 9, type: 'item', content: 'Truffle Fries'  , description: '' },

        { id: 18, type: 'title', title: 'BRUNCH ENTREES' },
        { id: 19, type: 'item', content: 'Biscuits & Gravy'  , description: '' },
        { id: 20, type: 'item', content: 'Fried Chicken'  , description: '' },
        { id: 21, type: 'item', content: 'Balsamic Glazed Salmon'  , description: '' },
        { id: 22, type: 'item', content: 'Brunch burger'  , description: '' },
        { id: 23, type: 'item', content: 'Daily omelet'  , description: '' },
        { id: 24, type: 'item', content: 'Prime rib hash'  , description: '' },
        { id: 25, type: 'item', content: 'Rouge breakfast'  , description: '' },
        { id: 26, type: 'item', content: 'Croque madame'  , description: '' },
        { id: 27, type: 'item', content: 'Peri Peri Wings & waffles'  , description: '' },
        { id: 28, type: 'item', content: 'Classic Benedict'  , description: '' },
        { id: 29, type: 'item', content: '7x farms wagyu strip and eggs'  , description: '' },
        { id: 30, type: 'item', content: 'Smoked Salmon Avocado Toast'  , description: '' },

        { id: 31, type: 'title', title: 'BRUNCH DRINKS' },
        { id: 32, type: 'item', content: 'Bottomless Mimosas, Bloody Marys, & Micheladas'  , description: '' },














        { id: 1, type: 'title', title: '$10 Small Bites' },
        { id: 2, type: 'item', content: 'Chili Shrimp Hushpuppies'  , description: '' },
        { id: 3, type: 'item', content: 'Mini Cheese Board'  , description: '' },
        { id: 4, type: 'item', content: 'Peri Peri Wings'  , description: '' },

        { id: 5, type: 'title', title: '$10 Signature Cocktails'  , description: '' },
        { id: 6, type: 'item', content: 'Sazerac'  , description: '' },
        { id: 7, type: 'item', content: 'Paloma'  , description: '' },
        { id: 8, type: 'item', content: 'Negroni'  , description: '' },
        { id: 9, type: 'item', content: 'Aviation'  , description: '' },

        { id: 10, type: 'title', title: '$10 Mini Martini' },
        { id: 11, type: 'item', content: 'Dry'  , description: '' },
        { id: 12, type: 'item', content: 'Dirty'  , description: '' },
        { id: 13, type: 'item', content: 'Espresso'  , description: '' },
        
        { id: 14, type: 'title', title: '$10 Wines'  , description: '' },
        { id: 15, type: 'item', content: 'Arca Nova'  , description: '' },
        { id: 16, type: 'item', content: 'Bisol “Jeio”'  , description: '' },
        { id: 17, type: 'item', content: 'Wildhurst'  , description: '' },
        { id: 18, type: 'item', content: 'Barricas' , description: ''  },
        { id: 19, type: 'item', content: 'Rouge Sangria'  , description: '' },
        { id: 20, type: 'item', content: 'Honoro Vero'  , description: '' },



























        { id: 2, type: 'item', content: 'Cheese Board', price: '$16.00', imageURL: 'cheese_board.jpg' , allergens: "Gluten, Dairy", description: '3 Chef Selected Cheeses, Honeycomb, Marcona Almonds, Fig Jam, Crackers' , detail: "Three chef selected cheeses served on a slate tile with honeycomb, marcona almonds, fig jam, crackers, fresh berries, balsamic vinegar, honey, salt and pepper."},
        { id: 3, type: 'item', content: 'Charcuterie Board'  , price: '$24.00', imageURL: 'charcuterie_board.jpeg' , allergens: "Gluten, Dairy",  description: 'Chef Selection cheese and Salami, Honeycomb, fig jam, grilled bread, rosemary olives, apricots, fresh berries' , detail: "Chef selected cheese and salami served on a slate tile. Served with honeycomb, marcona almonds, fig jam, crackers, fresh berries, rosemary olives, dried apricots."},
        { id: 4, type: 'item', content: 'Roasted Olives'  , price: '$6.00', imageURL: 'roasted_olives.jpeg' , description: 'Olives with Ciabatta bread' , allergens: "Gluten", detail: "Pitted country mix olives tossed with olive oil, roasted garlic, rosemary, orange peel, and crushed red pepper flake. Heated in a cast iron pan and served with grilled ciabatta bread."},

        { id: 5, type: 'title', title: 'SMALL PLATES' },
        { id: 6, type: 'item', content: 'Roasted Brussel Sprouts'  , imageURL: 'rouge_logo.png' , description: 'Chili Threads, Maple Glaze, Crispy Pancetta' },
        { id: 7, type: 'item', content: 'Spam & Scallops'  , description: 'Foie gras mousse, Blood Orange Gastrique, Crispy Spam' },
        { id: 8, type: 'item', content: 'Blistered Shishito Peppers'  , description: 'Sea Salt, Black Garlic Aioli' },
        { id: 9, type: 'item', content: 'Truffle Fries'  , description: 'Roasted Garlic Aioli, Parmesan' },
        { id: 10, type: 'item', content: 'Steak Tartare'  , description: 'Dijon Mustard, Cornichons, Fried Capers, Quail Egg Yolk,' },
        { id: 11, type: 'item', content: 'Burrata'  , description: 'House Smoked Tomatoes, Roasted Butternut Squash, Arugula, Evoo, Balsamic, Served with Crostini' },
        { id: 12, type: 'item', content: 'Smoked Beet Salad'  , description: 'Gold and Red Beets, Pomegranate vin, Roasted Pepitas, Herbed Goat Cheese' },
        { id: 13, type: 'item', content: 'Chili Shrimp Hushpuppies'  , description: 'Spiced Honey' },
        { id: 14, type: 'item', content: 'French Onion Soup'  , description: 'Gruyere Cheese, Garlic Crouton' },
        { id: 15, type: 'item', content: 'Peri Peri Wings'  , description: 'Blueberry Habanero Sauce' },
        { id: 16, type: 'item', content: 'Truffle Mac and Cheese'  , description: '' },
        { id: 17, type: 'item', content: 'Bone Marrow'  , description: '7x Farms Canoe Cut Beef Bone, Roasted with Evoo, Thyme, Maldon, Garnished with Black Truffle, and served with Ciabatta' },

        { id: 18, type: 'title', title: 'ENTREES' },
        { id: 19, type: 'item', content: 'Fried Chicken'  , description: '' },
        { id: 20, type: 'item', content: 'Balsamic Glazed Salmon'  , description: '' },
        { id: 21, type: 'item', content: 'Bolognese'  , description: '' },
        { id: 22, type: 'item', content: 'Rouge Burger'  , description: '' },
        { id: 23, type: 'item', content: 'Coffee Braised Short Rib'  , description: '' },
        { id: 24, type: 'item', content: '7X Farms NY Strip Steak Frites'  , description: '' },

        { id: 25, type: 'title', title: 'PIZZAS' },
        { id: 26, type: 'item', content: 'Fungi Pizza'  , description: '' },
        { id: 27, type: 'item', content: 'Steak Pizza'  , description: '' },
        { id: 28, type: 'item', content: 'Margarita Pizza'  , description: '' },
        { id: 29, type: 'item', content: 'Pear Gorgonzola'  , description: '' },

        { id: 30, type: 'title', title: 'DESSERTS' },
        { id: 31, type: 'item', content: 'Creme Brule'  , description: '' },
        { id: 32, type: 'item', content: 'Tiramisu'  , description: '' },
        { id: 33, type: 'item', content: 'Chocolate Cake'  , description: '' },








*/