import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            //backgroundColor: 'gray',
            justifyContent: "space-between",
            paddingBottom: '0.5rem',
            paddingLeft: '15px',
            paddingRight: '15px',
            //opacity: '90%',

        }}
    >
        {props.children}
    </Box>
);

const ResultItemPrice = (props) => {

    // Extract data from props
    const { data } = props;
    const { elementOpacity } = props;
    //// console.log('ResultItemPrice ', data)

    if (data.price_glass_8_oz == null) {


        return (
            <div>

                <Box display='flex' justifyContent='center'
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: "no-wrap",
                        //backgroundColor: 'gray',
                        justifyContent: "space-between",
                        paddingBottom: '0.5rem',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        opacity: elementOpacity,
                        transition: 'opacity 0.8s',

                    }}
                >
                    <Box backgroundColor='gray' display='flex' justifyContent='space-evenly' width='100%' padding='0.2rem' >

                        <Box display='flex' flexDirection='column' >

                            <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                Bottle
                            </Typography>
                            <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                ${data.price_bottle}
                            </Typography>
                        </Box>

                    </Box>
                </Box>

            </div >
        );



    } else {


        return (
            <div>

                <Box display='flex' justifyContent='center'

                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: "no-wrap",
                        //backgroundColor: 'gray',
                        justifyContent: "space-between",
                        paddingBottom: '0.5rem',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        opacity: elementOpacity,
                        transition: 'opacity 0.8s',

                    }}

                >
                    <Box backgroundColor='gray' display='flex' justifyContent='space-evenly' width='100%' padding='0.2rem' >
                        <Box display='flex' flexDirection='column' >
                            <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                Glass 5oz
                            </Typography>
                            <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                {data.price_glass_5_oz}
                            </Typography>
                        </Box>

                        <Box display='flex' flexDirection='column' >
                            <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                Glass 8oz
                            </Typography>
                            <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                {data.price_glass_8_oz}
                            </Typography>
                        </Box>

                        <Box display='flex' flexDirection='column' >

                            <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                Bottle
                            </Typography>
                            <Typography align="center" fontSize='1rem' color='black' width='100%' fontWeight='bold' >
                                {data.price_bottle}
                            </Typography>
                        </Box>

                    </Box>
                </Box>

            </div >
        );


    }

};

export default ResultItemPrice;
