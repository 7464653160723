import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';




import { useState } from "react";
////import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";



import Add from
    "@mui/icons-material/Add";
import Remove from
    "@mui/icons-material/Remove";

import IconButton from "@mui/material/IconButton";

import FlightMenuItem from './FlightMenuItem'



const FlightMenuHeading = (props) => {


    const [open2, setOpen2] = useState(false);




    // Extract data from props
    const data = props.item;
    //console.log("props.item ", props.item)
    ///console.log("-------------PROPS PROPS----------------------", props.item.content)
    const displayItems = data.flightList


    // [{ id: '1', type: 'item', name: '1', description: '1' },
    // { id: '2', type: 'item', name: '2', description: '2' },
    // { id: '3', type: 'item', name: '3', description: '3' }]///props.reducerData.queryObjectArray


    return (
        <div >
            {/* 
            <Typography align="center" color='black' fontSize='1.5rem' backgroundColor='silver' >{data.title}</Typography>

            sx={{ opacity: GLOBAL_OPACITY , width: '25rem', minWidth: '40%', display: 'flex', justifyContent: 'center' }}


 */}






            <Card sx={{

                width: '25rem',
                minWidth: "40%",
                border: "6px solid black", //"4px solid rgba(211,211,211,0.6)",

                //backgroundColor: 'gray',
                backgroundColor: '#424242',
                borderRadius: '0px',
                //opacity: '90%'
            }}>
                <CardHeader

                    onClick={() => setOpen2(!open2)}
                    aria-label="expand"
                    size="small"

                    title={data.category}
                    action={
                        <IconButton
                            onClick={() => setOpen2(!open2)}
                            aria-label="expand"
                            size="small"
                        ///// backgroundColor= "rgba(211,211,211,0.4)"
                        >
                            {open2 ? <Remove />
                                : <Add />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                    ///// backgroundColor: "rgba(211,211,211,0.4)"
                }}>


                    <Collapse in={open2} timeout="auto"
                        unmountOnExit>
                        <CardContent>
                            <Container sx={{
                                height: 'auto',
                                lineHeight: 2,
                                width: '100%',
                                ////backgroundColor: 'yellow',
                            }}>


                                {displayItems.map((item) => (
                                    <FlightMenuItem key={`flightMenuHeading_${item.flight_index}`} item={item}></FlightMenuItem>
                                ))}

                            </Container>
                        </CardContent>
                    </Collapse>


                </div>
            </Card>




        </div>
    );
};

export default FlightMenuHeading;



// {displayItems.map((item) => (
//     <MenuDisplay key={item.id} item={item}></MenuDisplay>
// ))}

//<h1 style={{ color: 'white', display: 'flex', justifyContent: 'center' }} >category</h1>
                                