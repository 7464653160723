import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import { Link } from 'react-router-dom';



const StyledTypography = (props) => (
    <Typography
        sx={{
            padding: '0.4rem',
            backgroundColor: 'silver',
            align: "center",
        }}
    >
        {props.children}
    </Typography>
);
const StyledTypography2 = (props) => (
    <Typography
        sx={{
            padding: '1rem',
            backgroundColor: 'black',
            align: "center",
        }}
    >
        {props.children}
    </Typography>
);




const TemplateItem = (props) => {

    // Extract data from props
    const { data } = props;

    return (
        <div>

            <Grid item xs={12} sm={8} md={8} style={{ width:'100%'}}>
                <Box  style={{paddingTop: '0rem', backgroundColor: 'black' }}></Box>
                <StyledTypography2></StyledTypography2>
                <StyledTypography></StyledTypography>
                <Typography align="center" color='white' backgroundColor='black' style={{paddingTop: '4rem', paddingBottom: '0.5rem',}}>
                    THE ROUGE WINE & BOURBON BISTRO
                </Typography>
                <Typography align="center" color='white' backgroundColor='black' style={{paddingTop: '0rem', paddingBottom: '0.5rem',}}>
                    7939 East Arapahoe Road Suite 190
                </Typography>
                <Typography align="center" color='white' backgroundColor='black' style={{paddingTop: '0rem', paddingBottom: '2rem',}}>
                    Greenwood Village, CO 80112
                </Typography>
                <Typography align="center" color='white' backgroundColor='black' style={{paddingTop: '2rem', paddingBottom: '1rem',}}>
                    Call for Reservations:  (720) 864 - 9463
                </Typography>

                <Typography align="center" color='white' backgroundColor='black' style={{paddingTop: '4rem', paddingBottom: '0.5rem',}}>
                    COPYRIGHT 2023 jdtechdevs llc
                </Typography>
                <Typography align="center" color='white' backgroundColor='black' style={{paddingTop: '0rem', paddingBottom: '1rem',}}>
                    ALL RIGHTS RESERVED
                </Typography>
                <Link  to="https://www.jdtechdevs.com" style={{ textDecoration: 'none' }}>
                    <Typography align="center" color='white' backgroundColor='black' style={{paddingTop: '1rem', paddingBottom: '4rem',}}>
                        jdtechdevs.com
                    </Typography>
                </Link>

            </Grid>

        </div >
    );
};

export default TemplateItem;

