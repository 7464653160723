import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

// import ModalComponent from './ModalComponent'


// import testImg from "../../../images/test/test.jpg"
//import testImg from "../../../images/test/logo.png"


const GLOBAL_OPACITY = '100%'

import CONFIG from '../../config/config.js'
const WINE_IMAGE_SERVER_IP = CONFIG.WINE_IMAGE_SERVER_IP
const WINE_IMAGE_SERVER_URL = CONFIG.WINE_IMAGE_SERVER_URL


import { useRef } from 'react';

// import ResultItemPrice from './ResultItemPrice'

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            padding: '0.5rem',
            gap: '1rem',
            width: 'auto',
            backgroundColor: 'black',
            width: '85%',
            padding: '5px',
            //opacity: "85%",



        }}
    >
        {props.children}
    </Box>
);

const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            //backgroundColor: 'gray',
            justifyContent: "space-between",
            paddingBottom: '0.5rem',
            paddingLeft: '15px',
            paddingRight: '15px',
            //opacity: '90%',




        }}
    >
        {props.children}
    </Box>
);


const StyledBox3 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "center",
            width: '100%',
            backgroundColor: 'gray',
        }}
    >
        {props.children}
    </Box>
);





const StyledBox4 = (props) => (
    <Box
        sx={{


            opacity: 1,

            animation: 'fade-in2 2s forwards',
            animationDelay: '0s', // Adjust the delay as needed


        }}
    >
        {props.children}
    </Box>
);






























// import { useRef } from 'react';
// const childRef = useRef(null);
// function handleParentClick() {
//     // Call a function in the child component
//     childRef.current.childFunction();
//   }

// let buttonOpacity = '0%'

// buttonOpacity={buttonOpacity}
// ref={childRef}
























const PickWineItem = (props) => {
    // Extract data from props
    const { data } = props;
    const { id } = props;

    const { wineInfo } = props






    //   // Render data using map
    //   const renderedList = data.map((item) => (
    //     // JSX markup for rendering each item in the list
    //     <div key={item.id}>
    //       {/* Render item data */}
    //       <p>{item.name}</p>
    //       {/* ... */}
    //     </div>
    //   ));





    // backgroundSize: '100% 100%',
    //sx={{ backgroundImage: `url(${testImg})`, 

    const imgURL = WINE_IMAGE_SERVER_IP + WINE_IMAGE_SERVER_URL + 'label_' + wineInfo.image // + data.image



    const childRef = useRef(null);
    function handleParentClick() {
        // Call a function in the child component
        ///// childRef.current.childFunction();

        console.log ('handleclick')


    }

    let buttonOpacity = '100%'

    return (

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '0rem', paddingBottom: '1rem' }}>

            <StyledBox4>
                <style>
                    {
                        `@keyframes fade-in {
                                    from {
                                    opacity: 0;
                                    }
                                    to {
                                    opacity: 1.0; // Adjust the final opacity to control the intensity of the background
                                    }
                                }

                                @keyframes fade-in2 {
                                    from {
                                      opacity: 0;
                                    }
                                    to {
                                      opacity: 1; // Adjust the final opacity to control the intensity of the background
                                    }
                                  }`
                    }
                </style>


                <Box backgroundColor='black'

                    sx={{

                        opacity: GLOBAL_OPACITY,
                        width: '100%',
                        minWidth: '40%',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '0px',
                        margin: '0px',
                        border: '0px',
                        borderRadius: '8px',


                    }} >
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }} >

                        {/* <div onClick={handleParentClick} style={{ width: 'auto', display: 'flex', justifyContent: 'center', paddingTop: '0rem', paddingBottom: '1rem' }} > */}

                        <div onClick={handleParentClick} style={{ width: 'auto', display: 'flex', justifyContent: 'center', paddingTop: '0rem', paddingBottom: '1rem' }} >













                            <StyledBox

                            // sx={{

                            //     opacity: '100%',


                            // }}

                            >

                                <Paper sx={{

                                    backgroundImage: `url(${imgURL})`,
                                    backgroundSize: 'auto',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100% 100%',
                                    backgroundColor: '#696969',
                                    width: '100%',
                                    // opacity: '100%',
                                    // animation: 'fade-in 0.3s forwards',

                                }}>

                                    <StyledBox3>

                                        <Grid item xs={6} sm={6} md={6} >
                                            <Typography
                                                align="center"
                                                fontSize='1.3rem'
                                                fontWeight='bold'
                                                // backgroundColor='gray'
                                                color='black'
                                                margin='0.2rem'
                                            >
                                                NAME
                                            </Typography>
                                        </Grid>
                                    </StyledBox3>



                                    <StyledBox2  >
                                        <Grid item xs={6} sm={6} md={6} width='50%' >
                                            <Typography
                                                borderRadius='5px'
                                                align="left"
                                                fontSize='1.1rem'
                                                fontWeight='bold'
                                                backgroundColor='gray'
                                                color='black'
                                                margin='0.3rem'
                                                marginTop='0.6rem'
                                                padding='5px'

                                            >
                                                Type:
                                            </Typography>

                                        </Grid>

                                        <Grid item xs={5} sm={5} md={5} width='50%'>
                                            <Typography
                                                borderRadius='5px'
                                                align="left"
                                                fontSize='1.1rem'
                                                fontWeight='bold'
                                                backgroundColor='gray'
                                                color='black'
                                                margin='0.3rem'
                                                marginTop='0.6rem'
                                                padding='5px'
                                            >
                                                Match #
                                            </Typography>

                                        </Grid>
                                    </StyledBox2>
                                    <StyledBox2 >
                                        <Grid item xs={6} sm={6} md={6} width='50%' >
                                            <Typography
                                                borderRadius='5px'
                                                align="left"
                                                fontSize='1.1rem'
                                                fontWeight='bold'
                                                backgroundColor='gray'
                                                color='black'
                                                margin='0.3rem'
                                                marginTop='0rem'
                                                padding='5px'
                                            >

                                                Grape:
                                            </Typography>

                                        </Grid>

                                        <Grid item xs={5} sm={5} md={5} width='50%' >
                                            <Typography
                                                borderRadius='5px'
                                                align="left"
                                                fontSize='1.1rem'
                                                fontWeight='bold'
                                                backgroundColor='gray'
                                                color='black'
                                                margin='0.3rem'
                                                marginTop='0rem'
                                                padding='5px'
                                            >
                                                Region:
                                            </Typography>

                                        </Grid>
                                    </StyledBox2>


                                    <StyledBox2  >
                                        <Grid item xs={3} sm={3} md={3}

                                            width='45%'
                                            backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                                        >
                                            <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                                Body: Light - Full
                                            </Typography>
                                            <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%'  >
                                                body_light_full
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={3} sm={3} md={3}

                                            width='45%'
                                            backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                                        >
                                            <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                                Tannin: Low - High
                                            </Typography>
                                            <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                                tannin_low_high
                                            </Typography>
                                        </Grid>

                                    </StyledBox2>

                                    <StyledBox2  >

                                        <Grid item xs={3} sm={3} md={3}

                                            width='45%'
                                            backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                                        >

                                            <Typography align="left" fontSize='0.9rem' color='black' width='100%' >
                                                Dry - Sweet
                                            </Typography>
                                            <Typography align="left" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                                dry_sweet
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={3} sm={3} md={3}

                                            width='45%'
                                            backgroundColor='silver' padding="3px" borderRadius="5px" margin='0.2rem'

                                        >

                                            <Typography align="right" fontSize='0.9rem' color='black' width='100%' >
                                                Acid: Low - High
                                            </Typography>
                                            <Typography align="right" fontSize='1rem' color='black' fontWeight='bold' width='100%' >
                                                acid_low_high
                                            </Typography>
                                        </Grid>
                                    </StyledBox2>



                                    {/* <StyledBox2>
                            <ModalComponent
                                style={{ paddingLeft: '0rem', color: 'blue' }}
                                render={true}
                                data={data}
                                buttonOpacity={buttonOpacity}
                                ref={childRef}
                            />
                        </StyledBox2> */}

                                </Paper>


                            </StyledBox>





                        </div >
                        {/* <div>
                        <p>Type: </p>
                        <p>Match: </p>
                        <p>Grape: </p>
                        <p>Region: </p>
                        <p>Body: </p>
                        <p>Tannin: </p>
                        <p>Dry: </p>
                        <p>Acid: </p>
                    </div> */}
                    </div>
                </Box>

            </StyledBox4>
        </div>


    );
};

export default PickWineItem;





