

export default function exportedFunction(state = {}, action) {

    const devModeBool = false

    // if (devModeBool == true) {

    // }










    /*
    
    
    // action number 1
        if (action.type === 'QUERY_WINE_DATA_SUCCESS') 
    
    // action number 2
        if (action.type === 'UPDATE_SEARCH_PANEL_FIELDS') 
    
    // action number 3
        if (action.type === 'UPDATE_WINE_SEARCH_RESULTS') 
    
    // action number 4
        if (action.type === 'INITIALIZE_WINE_SEARCH_RESULTS') 
    
    // action number 5
        if (action.type === 'TOGGLE_ALL')
    
    // action number 6
        if (action.type === 'GENERATE_CURRENT_RESULTS') 
    
    
    */








    /***********************
        
        
        
              1
             11
            1 1
           1  1
              1
              1
              1
              1
              1
              1
        111111111111
        
    
    
    ************************/









    // action number 1

    if (action.type === 'QUERY_WINE_DATA_SUCCESS') {

        const toggleBoolValue = false
        const toggleBoolValue2 = false
        const wineDataArray = action.payload
        let glassDataArray = []
        let bottleDataArray = []



        // acid_low_high: null
        // alcohol_by_volume_low_high: null
        // body_light_full: null
        // country_or_domestic_state: "Italy"
        // dry_sweet: null
        // enabled: 1
        // flight_category: "White Flights"
        // flight_name: "Tiny Bubbles"
        // grape: "Glera"
        // id: "Id_001"
        // image: "001-bisol_jeio_prosecco.jpg"
        // name: "Bisol 'Jeio’"
        // pairs_with_item_id: null
        // price_bottle: 30
        // price_flight: 22
        // price_glass_5_oz: 10
        // price_glass_8_oz: 16
        // region: "Valdobbiadene"
        // tannin_low_high: null
        // tasting_notes: null
        // type: "Bubbles"
        // varietal_descriptors: "pear, apple"

        wineDataArray.forEach((element, index) => {
            // console.log(`Element at index ${index}: ${element}`);
            ///// console.log("element", element);
            // console.log(element.price_bottle);
            // console.log(element.price_glass);

            let modifiedElement = {
                acid_low_high: element.acid_low_high,
                alcohol_by_volume_low_high: element.alcohol_by_volume_low_high,
                body_light_full: element.body_light_full,
                country_or_domestic_state: element.country_or_domestic_state,
                dry_sweet: element.dry_sweet,
                enabled: element.enabled,
                flight_category: element.flight_category,
                flight_name: element.flight_name,
                grape: element.grape,
                id: element.id,
                image: element.image,
                name: element.name,
                pairs_with_item_id: element.pairs_with_item_id,
                price_bottle: element.price_bottle,
                price_flight: element.price_flight,
                price_glass_5_oz: element.price_glass_5_oz,
                price_glass_8_oz: element.price_glass_8_oz,
                region: element.region,
                tannin_low_high: element.tannin_low_high,
                tasting_notes: element.tasting_notes,
                type: element.type,
                varietal_descriptors: element.varietal_descriptors,
            }

            /////  console.log("modifiedElement", modifiedElement);


            // let removeNullStringValuesArray = [
            //     'acid_low_high',
            //     'alcohol_by_volume_low_high',
            //     'body_light_full',
            //     'country_or_domestic_state',
            //     'dry_sweet',
            //     'enabled',
            //     'grape',
            //     'image',
            //     'region',
            //     'tannin_low_high',
            //     'tasting_notes',
            //     'type',
            //     'varietal_descriptors',
            // ]

            // SPECIAL CASE: enabled, image

            if (modifiedElement['enabled'] == null) {
                modifiedElement['enabled'] = 1
            }
            if (modifiedElement['image'] == null) {
                modifiedElement['image'] = 'default_image.jpg'
            }

            let removeNullStringValuesArray = [
                'country_or_domestic_state',
                'grape',
                'region',
                'tasting_notes',
                'type',
                'varietal_descriptors',
            ]

            removeNullStringValuesArray.forEach((keyElement) => {
                if (modifiedElement[keyElement] == null) {
                    modifiedElement[keyElement] = 'empty'
                }

            })


            let removeNullIntegerValuesArray = [
                'acid_low_high',
                'alcohol_by_volume_low_high',
                'body_light_full',
                'dry_sweet',
                'tannin_low_high',
            ]

            removeNullIntegerValuesArray.forEach((keyElement) => {
                if (modifiedElement[keyElement] == null) {
                    modifiedElement[keyElement] = 3
                }

            })



            if (element.price_glass_8_oz === null) {
                //console.log("this item is a BOTTLE")
                bottleDataArray.push(modifiedElement)
            } else {
                //console.log("NOT A BOTTLE")
                glassDataArray.push(modifiedElement)
            }
        });


        if (devModeBool == true) {
            console.log('glassDataArray ', glassDataArray)
            console.log('bottleDataArray ', bottleDataArray)
        }





        let glassDataSearchObject = {
            type: [],
            grape: [],
            region: [],
            country_or_domestic_state: [],
            sliderValues: {},
        }
        let bottleDataSearchObject = {
            type: [],
            grape: [],
            region: [],
            country_or_domestic_state: [],
            sliderValues: {},
        }

        // - iterate over this set and generate checkbox search categories based off of selection (type, grape, or location)



        let wineFlightResults = []

        glassDataArray.forEach((element, index) => {
            //console.log(element.type);
            //console.log(element.region);
            //console.log(element.grape);

            if (!glassDataSearchObject.type.includes(element.type)) {
                glassDataSearchObject.type.push(element.type);
            }

            /////console.log("glassDataArray element", element.flight_category);



            if (element.flight_name != null) {

                // for each wine check if that category exists yet
                // if category exists add to array
                // if category does not exist, create category, then add to array in appropriate location
                let currentFlightCategory = element.flight_category
                let categoryExists = false
                wineFlightResults.forEach((flightElement) => {
                    if (flightElement.category == currentFlightCategory) {
                        /////console.log("exists")
                        categoryExists = true
                    }
                })

                if (categoryExists == false) {
                    /////console.log("DOES NOT EXIST")
                    wineFlightResults.push(
                        {
                            category: currentFlightCategory,
                            flightList: [],
                        }
                    )
                }

                let flightCategoryHashMap = {}
                wineFlightResults.forEach((flightElement, flightElementIndex) => {
                    flightCategoryHashMap[flightElement.category] = flightElementIndex
                })
                /////console.log("flightCategoryHashMap", flightCategoryHashMap)



                // now category definitely exists, but need to check if flight exists, so will make variable that is 
                // the flightList array, which will copy by reference (not make a new copy of the array in memory)

                let currentFlightCategoryIndex = flightCategoryHashMap[currentFlightCategory]
                /////console.log("currentFlightCategoryIndex", currentFlightCategoryIndex)
                let currentCategoryFlightList = wineFlightResults[currentFlightCategoryIndex].flightList

                let currentFlightExists = false
                /////console.log("currentCategoryFlightList", currentCategoryFlightList)
                currentCategoryFlightList.forEach((currentCategoryFlightListElement) => {
                    if (currentCategoryFlightListElement.flight_name == element.flight_name) {
                        /////console.log("currentFlightExists")
                        currentFlightExists = true
                    }
                })
                /////console.log("currentFlightExists ", currentFlightExists)

                if (currentFlightExists == false) {
                    /////console.log("currentFlightExists DOES NOT EXIST")
                    let currentCategoryFlightListArrayLength = currentCategoryFlightList.length
                    currentCategoryFlightList.push(
                        {
                            flight_name: element.flight_name,
                            flight_index: currentCategoryFlightListArrayLength,
                            wine_glasses: [],
                        }
                    )
                }

                let flightNameHashMap = {}
                currentCategoryFlightList.forEach((flightListElement, flightListElementIndex) => {
                    flightNameHashMap[flightListElement.flight_name] = flightListElementIndex
                })
                /////console.log("flightNameHashMap", flightNameHashMap)

                let currentFlightName = element.flight_name
                let currentFlightListIndex = flightNameHashMap[currentFlightName]
                /////console.log("currentFlightListIndex", currentFlightListIndex)
                let currentFlightNameArray = currentCategoryFlightList[currentFlightListIndex].wine_glasses
                currentFlightNameArray.push(element)

            }

        });




        if (devModeBool == true) {
            console.log("wineFlightResults ", wineFlightResults)

            console.log('glassDataSearchObject ', glassDataSearchObject)
            console.log('bottleDataSearchObject ', bottleDataSearchObject)
        }




        // let glassDataArray = state.glassDataArray

        // glassDataArray.forEach((element, index) => {
        //     console.log("element", index, element)
        // });





        // iterate over entire wine object 
        // flight category - e.g. red and white
        // flight name - whimsical name
        // flight priority - integer for where flight should appear in list

        // for each wine check if that category exists yet
        // if category exists add to array
        // if category does not exist, create category, then add to array in appropriate location



        /*


        data object structure:

        [
            {
                category: name
                [
                    array of objects corrsponding to each flight
                    {
                        flight in category
                        priority: integer
                        [
                            {wine 1},
                            {wine 2},
                            {wine 3},
                        ]
                    }
                ]
            }

        ]


        */































        // pass all of the glasses data or bottles data along with the key to be populated: type, grape, or region
        const populateSearchObject = (dataArray, searchKey) => {

            let returnArray = []
            let returnArray2 = []
            const dataMap = {};


            // here the first level of the dataMap is populated with each unique ky in the dataarray, e.g. bubbles white rose red
            dataArray.forEach((element) => {

                if (!returnArray.includes(element[searchKey])) {
                    returnArray.push(element[searchKey]);
                    dataMap[element[searchKey]] = []
                }

                dataMap[element[searchKey]].push(element)

            });


            // console.log("returnArray", returnArray)
            // console.log("dataMap  ", dataMap)
            // console.log("Object.keys(dataMap)  ", Object.keys(dataMap))
            // console.log("Object.entries(dataMap)  ", Object.entries(dataMap))



            // ALL TASTING NOTE STUFF
            Object.keys(dataMap).forEach((key, keyIndex) => {

                let dataMapElementArray = dataMap[key]
                let tastingNotesArray = []
                let tastingNotesArray2 = []
                let tastingNoteIndex = 0
                //console.log("key, obj[key] ", key, dataMap[key]);

                dataMapElementArray.forEach((element) => {
                    //const parsedArray = JSON.parse(element.tasting_notes); now varietal_descriptors
                    let commaSeparatedString = element.varietal_descriptors

                    // console.log("element.varietal_descriptors" , element.varietal_descriptors)
                    // if (commaSeparatedString == null){
                    //     commaSeparatedString = '1,2'
                    // }

                    const elements = commaSeparatedString.split(',').map(item => item.trim());
                    const jsonString = JSON.stringify(elements);
                    //console.log(jsonString);
                    //console.log(typeof jsonString);
                    const parsedArray = JSON.parse(jsonString);

                    // [stringTastingNote, true, tastingNoteIndex]


                    parsedArray.forEach((stringTastingNote) => {
                        if (!tastingNotesArray.includes(stringTastingNote)) {
                            tastingNotesArray.push(stringTastingNote);
                            tastingNotesArray2.push([stringTastingNote, toggleBoolValue2, [keyIndex, tastingNoteIndex]]);
                            tastingNoteIndex += 1
                        }
                    });


                    //console.log("element.tasting_notes", element.tasting_notes)

                });

                /////console.log("FOR KEY OF : ", key, " TASTING NOTES ARE: ", tastingNotesArray2)


                // populates the returnarray 
                returnArray2.push({ property: [key, toggleBoolValue, [keyIndex]], tastingNotes: tastingNotesArray2 })

            });



            return returnArray2

        };




        glassDataSearchObject.type = populateSearchObject(glassDataArray, 'type')
        glassDataSearchObject.grape = populateSearchObject(glassDataArray, 'grape')
        glassDataSearchObject.region = populateSearchObject(glassDataArray, 'region')
        glassDataSearchObject.country_or_domestic_state = populateSearchObject(glassDataArray, 'country_or_domestic_state')

        //console.log('glassDataArray ',glassDataArray)
        // console.log('glassDataSearchObject ',glassDataSearchObject)

        bottleDataSearchObject.type = populateSearchObject(bottleDataArray, 'type')
        bottleDataSearchObject.grape = populateSearchObject(bottleDataArray, 'grape')
        bottleDataSearchObject.region = populateSearchObject(bottleDataArray, 'region')
        bottleDataSearchObject.country_or_domestic_state = populateSearchObject(bottleDataArray, 'country_or_domestic_state')


        if (devModeBool == true) {
            console.log('glassDataSearchObject 2 ', glassDataSearchObject)
            console.log('bottleDataSearchObject 2 ', bottleDataSearchObject)
        }




       //// console.log('QUERY_WINE_DATA_SUCCESS bottleDataArray: ', bottleDataArray)







        const bottleDataArrayItems = bottleDataArray.map(function (currentItem, index, array) {
          ////  console.log(currentItem.price_bottle)
            return currentItem.price_bottle /* transformed value based on the currentItem */;
        });


       //// console.log(bottleDataArrayItems)



        //   function quickSort(arr) {
        //     if (arr.length <= 1) {
        //       return arr; // Base case: an array with one or zero elements is already sorted
        //     }

        //     const pivotIndex = Math.floor(arr.length / 2);
        //     const pivot = arr[pivotIndex];
        //     const left = [];
        //     const right = [];

        //     // Partition the array into two subarrays: elements less than the pivot and elements greater than the pivot
        //     for (let i = 0; i < arr.length; i++) {
        //       if (i === pivotIndex) {
        //         continue; // Skip the pivot element
        //       }
        //       if (arr[i] < pivot) {
        //         left.push(arr[i]);
        //       } else {
        //         right.push(arr[i]);
        //       }
        //     }

        //     // Recursively sort the subarrays and combine them with the pivot to form the final sorted array
        //     return [...quickSort(left), pivot, ...quickSort(right)];
        //   }

        // Example usage:
        //   const unsortedArray = [3, 6, 8, 10, 1, 2, 1];
        //   const sortedArray = quickSort(unsortedArray);
        //   console.log(sortedArray); // Output: [1, 1, 2, 3, 6, 8, 10]




        // function quickSort(arr) {
        //     if (arr.length <= 1) {
        //       return arr; // Base case: an array with one or zero elements is already sorted
        //     }

        //     const pivotIndex = Math.floor(arr.length / 2);
        //     const pivot = arr[pivotIndex];
        //     const left = [];
        //     const right = [];

        //     // Partition the array into two subarrays: elements less than the pivot and elements greater than the pivot
        //     for (let i = 0; i < arr.length; i++) {
        //       if (i === pivotIndex) {
        //         continue; // Skip the pivot element
        //       }
        //       if (arr[i] < pivot) {
        //         left.push(arr[i]);
        //       } else {
        //         right.push(arr[i]);
        //       }
        //     }

        //     // Recursively sort the subarrays and combine them with the pivot to form the final sorted array
        //     return [...quickSort(left), pivot, ...quickSort(right)];
        //   }




        //   function quickSort(arr) {
        //     if (arr.length <= 1) {
        //       return arr; // Base case: an array with one or zero elements is already sorted
        //     }

        //     const pivotIndex = Math.floor(arr.length / 2);
        //     const pivot = arr[pivotIndex];
        //     const left = [];
        //     const right = [];

        //     // Partition the array into two subarrays: elements less than the pivot and elements greater than the pivot
        //     for (let i = 0; i < arr.length; i++) {
        //       if (i === pivotIndex) {
        //         continue; // Skip the pivot element
        //       }
        //       if (arr[i] < pivot) {
        //         left.push(arr[i]);
        //       } else {
        //         right.push(arr[i]);
        //       }
        //     }

        //     // Recursively sort the subarrays and combine them with the pivot to form the final sorted array
        //     return [...quickSort(left), pivot, ...quickSort(right)];
        //   }

        function quickSort(arr) {
            if (arr.length <= 1) {
                return arr; // Base case: an array with one or zero elements is already sorted
            }

            const pivotIndex = Math.floor(arr.length / 2);
            const pivot = parseInt(arr[pivotIndex]);
            const left = [];
            const right = [];

            // Partition the array into two subarrays: elements less than the pivot and elements greater than the pivot
            for (let i = 0; i < arr.length; i++) {
                if (i === pivotIndex) {
                    continue; // Skip the pivot element
                }
                if (parseInt(arr[i]) < pivot) {
                    left.push(arr[i]);
                } else {
                    right.push(arr[i]);
                }
            }

            // Recursively sort the subarrays and combine them with the pivot to form the final sorted array
            return [...quickSort(left), pivot.toString(), ...quickSort(right)];
        }


        const sortedArrayBottles = quickSort(bottleDataArrayItems);
      ////  console.log(sortedArrayBottles); // Output: [1, 1, 2, 3, 6, 8, 10]


        function divideIntoBuckets(arr) {
            // Calculate the size of each bucket (20% of the total items)
            const bucketSize = Math.ceil(arr.length / 5);

            // Create empty buckets to hold the items
            const buckets = [];

            // Sort the input array in ascending order
            /// arr.sort((a, b) => a - b);

            // Calculate bucket bounds based on the sorted array
            for (let i = 0; i < 5; i++) {
                const lowerBound = arr[i * bucketSize];
                const upperBound = arr[Math.min(((i + 1) * bucketSize), arr.length - 1)];
                buckets.push({ lower: lowerBound, upper: upperBound });
            }

            return buckets;
        }



        const sortedArrayBottlesBuckets = divideIntoBuckets(sortedArrayBottles);
      ////  console.log(sortedArrayBottlesBuckets); // Output: [1, 1, 2, 3, 6, 8, 10]



        bottleDataArray.map(function (currentItem, index, array) {

            let currentBucket = null
            let currentPrice = parseInt(currentItem.price_bottle)
            for (let i = 0; i < 5; i++){
                let currentLimit = parseInt(sortedArrayBottlesBuckets[i].upper)
                //console.log(currentPrice, currentLimit)
                if (currentPrice < currentLimit) {
                   //// console.log(currentPrice, currentLimit, 'less')
                    currentBucket = i + 1
                    break
                } 
                // else {
                //     console.log(currentPrice, currentLimit, 'more')
                // }

            }
           //// console.log(currentBucket, '\n\n')
            bottleDataArray[index].price = currentBucket
        });



        //console.log('QUERY_WINE_DATA_SUCCESS action.payload: ', action.payload)
        return {
            fullWineData: action.payload,
            glassDataArray: glassDataArray,
            bottleDataArray: bottleDataArray,
            glassDataSearchObject: glassDataSearchObject,
            bottleDataSearchObject: bottleDataSearchObject,
            wineSearchResults: state.wineSearchResults,
            wineSearchResultsLength: state.wineSearchResultsLength,
            wineFlightResults: wineFlightResults,
        };
    }














































    /******************* 
    
    
    
       2222
      2    2
           2
          2
         2
        2
       2
      2
     2
     2222222
    
    
    
    ********************/


    // action number 2

    if (action.type === 'UPDATE_SEARCH_PANEL_FIELDS') {

        if (devModeBool == true) {
            console.log('UPDATE_SEARCH_PANEL_FIELDS action.payload: ', action.payload)
            console.log("current state: ", state)
        }



        let indices = action.payload.toggleData[2]
        //console.log(indices)


        if (devModeBool == true) {
            console.log("2.0")
        }


        let actionPayload = action.payload
        //console.log("state.glassDataSearchObject['type'][indices[0]][indices[1]]  ", state.glassDataSearchObject['type'][indices[0]]["tastingNotes"][indices[1]])

        let selectedActiveMenu = null
        if (action.payload.activeMenu == 'glasses') {
            selectedActiveMenu = "glassDataSearchObject"

            if (devModeBool == true) {
                console.log("2.01")
            }



        }
        if (action.payload.activeMenu == 'bottles') {
            selectedActiveMenu = "bottleDataSearchObject"

        }
        let sortBy = action.payload.sortBy

        // determine whether checkbox for category (e.g. white red rose) or tasting note was toggled

        // 
        /* 
        
        the structure of the action.payload is an object with three keys:
        Object { sortBy: "type", activeMenu: "glasses", toggleData: (3) […] }
        the third key, toggleData, always has an array indicating what is to be toggled
        if array length = 1, toggle checkbox - this is the index of the checkbox, e.g. bubbles = 0, white = 1, etc
        if array length = 2, toggle tasting note - this is the index of the checkbox and the tasting note

        perhaps want to do this differently? this may be confusing, had to figure it out when re-reading code after 2 week break
        
        
        */
        /// this is if checkbox toggled

        if (devModeBool == true) {
            console.log("2.02")
            if (indices && indices.length == 1) {
                console.log("THIS IS THE ELEMENT YOU ACCESSED: ", state[selectedActiveMenu][sortBy][indices[0]])
            }

            /// this is if tasting note toggled
            if (indices && indices.length == 2) {
                console.log("THIS IS THE ELEMENT YOU ACCESSED: ", state[selectedActiveMenu][sortBy][indices[0]]["tastingNotes"][indices[1]])
                //let newBoolState = !state[selectedActiveMenu][sortBy][indices[0]]["tastingNotes"][indices[1]][1]
                //state[selectedActiveMenu][sortBy][indices[0]]["tastingNotes"][indices[1]][1] = newBoolState
            }

            console.log("2.03")
        }









        /*

        because javascript copies complex data (arrays, objects) by reference rather than value
        need to build a copy to modify, because working directly with the store data is not correct in redux

        */


        const buildMutableSearchObject = (dataArray, searchKey, referenceObject) => {

            let returnArray = []
            let returnArray2 = []
            const dataMap = {};


            // here the first level of the dataMap is populated with each unique key in the dataarray, e.g. bubbles white rose red
            dataArray.forEach((element) => {

                if (!returnArray.includes(element[searchKey])) {
                    returnArray.push(element[searchKey]);
                    dataMap[element[searchKey]] = []
                }

                dataMap[element[searchKey]].push(element)

            });




            if (devModeBool == true) {
                console.log("returnArray", returnArray)
                console.log("dataMap  ", dataMap)
                console.log("Object.keys(dataMap)  ", Object.keys(dataMap))
                console.log("Object.entries(dataMap)  ", Object.entries(dataMap))
            }








            // ALL TASTING NOTE STUFF
            Object.keys(dataMap).forEach((key, keyIndex) => {

                let dataMapElementArray = dataMap[key]
                let tastingNotesArray = []
                let tastingNotesArray2 = []
                let tastingNoteIndex = 0
                //console.log("key, obj[key] ", key, dataMap[key]);

                dataMapElementArray.forEach((element, elementIndex) => {
                    //const parsedArray = JSON.parse(element.tasting_notes);

                    // let commaSeparatedString = element.varietal_descriptors
                    // console.log("element.varietal_descriptors" , element.varietal_descriptors)
                    // if (commaSeparatedString == null){
                    //     commaSeparatedString = '1,2'
                    // }


                    const commaSeparatedString = element.varietal_descriptors
                    const elements = commaSeparatedString.split(',').map(item => item.trim());
                    const jsonString = JSON.stringify(elements);
                    //console.log(jsonString);
                    //console.log(typeof jsonString);
                    const parsedArray = JSON.parse(jsonString);

                    // [stringTastingNote, true, tastingNoteIndex]

                    //console.log("referenceObject[searchKey][keyIndex]['tastingNotes'][elementIndex] ", referenceObject[searchKey][keyIndex]["tastingNotes"][tastingNoteIndex])
                    //console.log("searchKey ", searchKey, "keyIndex", keyIndex, " elementIndex", tastingNoteIndex)
                    //console.log("referenceObject[searchKey][keyIndex]['tastingNotes'][elementIndex][1] ", referenceObject[searchKey][keyIndex]["tastingNotes"][elementIndex][0])
                    //let copiedItem = referenceObject[searchKey][keyIndex]["tastingNotes"][tastingNoteIndex]
                    //console.log("copiedItem", copiedItem)
                    parsedArray.forEach((stringTastingNote) => {
                        if (!tastingNotesArray.includes(stringTastingNote)) {
                            tastingNotesArray.push(stringTastingNote);
                            tastingNotesArray2.push([stringTastingNote, true, [keyIndex, tastingNoteIndex]]);
                            tastingNoteIndex += 1
                        }
                    });


                    //console.log("element.tasting_notes", element.tasting_notes)

                });

                /////console.log("FOR KEY OF : ", key, " TASTING NOTES ARE: ", tastingNotesArray2)
                tastingNotesArray2.forEach((element, elementIndex) => {

                    /////console.log("FOR elementIndex OF : ", elementIndex, " TASTING NOTES ARE: ", element)
                    /////console.log("searchKey keyIndex tastingNoteIndex", searchKey, keyIndex, elementIndex)

                    /////console.log("referenceObject[searchKey][keyIndex]['tastingNotes'][tastingNoteIndex] ", referenceObject[searchKey][keyIndex]["tastingNotes"][elementIndex])
                    /////console.log("referenceObject[searchKey][keyIndex]['tastingNotes'][tastingNoteIndex][1] ", referenceObject[searchKey][keyIndex]["tastingNotes"][elementIndex][1])
                    let currentBoolValueToCopy = referenceObject[searchKey][keyIndex]["tastingNotes"][elementIndex][1]
                    /////console.log( 'tastingNotesArray2[elementIndex][1]', tastingNotesArray2[elementIndex][1])
                    tastingNotesArray2[elementIndex][1] = currentBoolValueToCopy
                    //referenceObject[searchKey][keyIndex]["tastingNotes"][tastingNoteIndex]
                })





                /////console.log(  "dataArray  ", dataArray   )
                /////console.log(  "referenceObject[searchKey][keyIndex]['property'][1]  ", referenceObject[searchKey][keyIndex]["property"][1]   )
                let copiedToggleBoolProperty = referenceObject[searchKey][keyIndex]["property"][1]
                // populates the returnarray 
                returnArray2.push({ property: [key, copiedToggleBoolProperty, [keyIndex]], tastingNotes: tastingNotesArray2 })

            });



            return returnArray2

        };





        const buildMutableSliderObject = (activeMenu, payload) => {

            if (devModeBool == true) {
                console.log("buildMutableSliderObject ", activeMenu, payload)
            }



        }

        /************************************* 
            
               2222                 1
              2    2               11
                   2              1 1
                  2              1  1
                 2                  1  
                2                   1
               2                    1
              2                     1
             2          0000        1
             2222222    0000     1111111
            
        *************************************/

        // ==================================================================================
        // ==================================================================================
        // ==================================================================================
        // ==================================================================================

        if (devModeBool == true) {
            console.log("2.1")
        }



        let copiedDataObject = {}
        let refSelectedActiveMenu = state[selectedActiveMenu]

        if (devModeBool == true) {
            console.log("refSelectedActiveMenu ", refSelectedActiveMenu)
        }




        let selectedReferenceArray = null
        if (action.payload.activeMenu == 'glasses') {
            selectedReferenceArray = "glassDataArray"

        }
        if (action.payload.activeMenu == 'bottles') {
            selectedReferenceArray = "bottleDataArray"

        }


        let selectedReferenceObject = null
        if (action.payload.activeMenu == 'glasses') {
            selectedReferenceObject = "glassDataSearchObject"

        }
        if (action.payload.activeMenu == 'bottles') {
            selectedReferenceObject = "bottleDataSearchObject"

        }




        Object.entries(refSelectedActiveMenu).forEach(([key, value]) => {

            if (devModeBool == true) {
                console.log(key, " ", typeof key, " ", value, typeof value, Array.isArray(value)); // Output: a, b, c
            }




            if (key != sortBy) {

                if (devModeBool == true) {
                    console.log("key NOT sortby ", key)
                }



                copiedDataObject[key] = value
            }
            if (key == sortBy) {

                if (devModeBool == true) {
                    console.log("key IS sortby ", key)
                }


                //copiedDataObject[key] = value
                if (key != "sliderValues") {
                    copiedDataObject[key] = buildMutableSearchObject(state[selectedReferenceArray], key, state[selectedReferenceObject])

                } else {

                    if (devModeBool == true) {
                        console.log("sliderValues")
                    }


                    //copiedDataObject["sliderValues"] = "test"
                    //buildMutableSliderObject(selectedActiveMenu, actionPayload)
                }

            }

        });


        if (devModeBool == true) {
            console.log("copiedDataObject ", copiedDataObject)
            console.log("refSelectedActiveMenu ", refSelectedActiveMenu)
        }







        /// this is if checkbox toggled
        if (indices && indices.length == 1) {

            if (devModeBool == true) {
                console.log("2.1 THIS IS THE ELEMENT YOU ACCESSED: ", copiedDataObject[sortBy][indices[0]])
            }



            let newBoolState = !copiedDataObject[sortBy][indices[0]]["property"][1]
            copiedDataObject[sortBy][indices[0]]["property"][1] = newBoolState

            let toggleAllVarietalDescriptors = state[selectedReferenceObject][sortBy][indices[0]]['tastingNotes']

            let mutableVarietalDescriptorData = []


            if (devModeBool == true) {
                console.log("=================toggleAllVarietalDescriptors", toggleAllVarietalDescriptors)
            }



            toggleAllVarietalDescriptors.forEach((descriptor, descriptorIndex) => {


                if (devModeBool == true) {
                    console.log("varietal_descriptor", descriptor, descriptorIndex)
                }



                let descriptorArray = descriptor
                let emptyArray = []
                mutableVarietalDescriptorData.push(emptyArray)

                descriptorArray.forEach((descriptorItem, descriptorItemIndex) => {

                    if (devModeBool == true) {
                        console.log("descriptorItem", descriptorItem, descriptorItemIndex)
                    }



                    mutableVarietalDescriptorData[descriptorIndex].push(descriptorItem)

                })


            })

            // console.log("mutableVarietalDescriptorData", mutableVarietalDescriptorData)

            mutableVarietalDescriptorData.forEach((mutableDescriptor, mutableDescriptorIndex) => {
                mutableVarietalDescriptorData[mutableDescriptorIndex][1] = newBoolState
            })


            if (devModeBool == true) {
                console.log("mutableVarietalDescriptorData", mutableVarietalDescriptorData)
            }



            copiedDataObject[sortBy][indices[0]]["tastingNotes"] = mutableVarietalDescriptorData
        }

        /// this is if tasting note toggled
        if (indices && indices.length == 2) {

            if (devModeBool == true) {
                console.log("2.1 THIS IS THE ELEMENT YOU ACCESSED: ", copiedDataObject[sortBy][indices[0]]["tastingNotes"][indices[1]])
            }



            let newBoolState = !copiedDataObject[sortBy][indices[0]]["tastingNotes"][indices[1]][1]
            copiedDataObject[sortBy][indices[0]]["tastingNotes"][indices[1]][1] = newBoolState

            if (devModeBool == true) {
                console.log("THIS IS THE MODIFIED ELEMENT : ", copiedDataObject[sortBy][indices[0]]["tastingNotes"][indices[1]])
            }





            let checkBoxStatus = copiedDataObject[sortBy][indices[0]]["property"]
            let mutableCheckBox = []
            checkBoxStatus.forEach((checkBoxItem, checkBoxItemIndex) => {
                mutableCheckBox.push(checkBoxItem)

            })

            if (devModeBool == true) {
                console.log("=============================mutableCheckBox", mutableCheckBox)
            }




            mutableCheckBox[1] = false
            if (newBoolState == true) {
                mutableCheckBox[1] = true
            } else {

                let tastingNotesArray = copiedDataObject[sortBy][indices[0]]["tastingNotes"]
                tastingNotesArray.forEach((tastingNotesItem, tastingNotesItemIndex) => {

                    if (tastingNotesItem[1] == true) {
                        mutableCheckBox[1] = true
                    }

                })



            }


            copiedDataObject[sortBy][indices[0]]["property"] = mutableCheckBox


        }


        /************************************* 
         * 
               2222               222222
              2    2             2      2
                   2                    2
                  2                    2
                 2                    2 
                2                    2
               2                    2
              2                    2
             2          0000      2
             2222222    0000     22222222
        
        
        *************************************/

        // here take values from sliders and add to search object , so no will be: type, grape, region, sliderValues
        // only do this if slider value has changed, so set conditional at top of this fxn for one or other









        // if (typeof value == 'string') {
        //     console.log("STRING")
        // }
        // if (Array.isArray(value) == true) {
        //     console.log("ARRAY")
        //     value.map((nestedItem, nestedIndex) => {
        //         console.log(nestedItem)
        //         //boolDataObject[index][key][nestedIndex] = [nestedItem, true]
        //     })
        // }





        // refSelectedActiveMenu.map((item, index) => {
        //     console.log("state[selectedActiveMenu].map ", item)

        // Object.entries(item).forEach(([key, value]) => {
        //     console.log(key, " ", typeof key, " ", value, typeof value, Array.isArray(value)); // Output: a, b, c
        //     if (typeof value == 'string') {
        //         console.log("STRING")
        //     }
        //     if (Array.isArray(value) == true) {
        //         console.log("ARRAY")
        //         value.map((nestedItem, nestedIndex) => {
        //             console.log(nestedItem)
        //             //boolDataObject[index][key][nestedIndex] = [nestedItem, true]
        //         })
        //     }
        // });

        // })

        // console.log("boolDataObject : ", boolDataObject)



        if (devModeBool == true) {
            console.log("2.2")
        }


        if (action.payload.activeMenu == 'glasses') {
            selectedActiveMenu = "glassDataSearchObject"
            return {
                fullWineData: state.fullWineData,
                glassDataArray: state.glassDataArray,
                bottleDataArray: state.bottleDataArray,
                glassDataSearchObject: copiedDataObject,
                bottleDataSearchObject: state.bottleDataSearchObject,
                wineSearchResults: state.wineSearchResults,
                wineSearchResultsLength: state.wineSearchResultsLength,
                wineFlightResults: state.wineFlightResults,
            };
        }
        if (action.payload.activeMenu == 'bottles') {
            selectedActiveMenu = "bottleDataSearchObject"
            return {
                fullWineData: state.fullWineData,
                glassDataArray: state.glassDataArray,
                bottleDataArray: state.bottleDataArray,
                glassDataSearchObject: state.glassDataSearchObject,
                bottleDataSearchObject: copiedDataObject,
                wineSearchResults: state.wineSearchResults,
                wineSearchResultsLength: state.wineSearchResultsLength,
                wineFlightResults: state.wineFlightResults,
            };
        }





    }





























    /************************************* 
        
           2222             55555555
          2    2            5
               2            5
              2             5
             2               555555  
            2                      5
           2                        5
          2                         5
         2          0000           5
         2222222    0000     555555
        
    *************************************/

    if (action.type === 'UPDATE_SLIDER_VALUES') {

        if (devModeBool == true) {
            console.log("UPDATE_SLIDER_VALUES -------------------------", action.payload.toggleData)
        }


        //let selectedActiveMenuData = state[selectedActiveMenu]
        let valuesArray = action.payload.toggleData
        let newKeyName = valuesArray[0]
        let newKeyValue = valuesArray[1]

        let selectedActiveMenu = null
        if (action.payload.activeMenu == 'glasses') {
            selectedActiveMenu = "glassDataSearchObject"


        }
        if (action.payload.activeMenu == 'bottles') {
            selectedActiveMenu = "bottleDataSearchObject"

        }

        if (devModeBool == true) {
            console.log("UPDATE_SLIDER_VALUES", selectedActiveMenu)
        }




        const buildMutableSliderObject = (activeMenu) => {

            if (devModeBool == true) {
                console.log("-------------------buildMutableSliderObject ", activeMenu)
            }



            const referenceSliderValues = activeMenu['sliderValues']
            /// this doesnt work because copy by reference -- need to fix
            const mutableSliderValues = Object.assign({}, referenceSliderValues);

            if (devModeBool == true) {
                console.log("-------------------mutableSliderValues ", mutableSliderValues)
            }



            // Object.entries(activeMenu).forEach(([key, value]) => {
            //     console.log(key, " ", typeof key, " ", value, typeof value, Array.isArray(value)); // Output: a, b, c

            // });

            return mutableSliderValues
        }

        let mutableSliderObject = buildMutableSliderObject(state[selectedActiveMenu])

        mutableSliderObject[newKeyName] = newKeyValue

        if (devModeBool == true) {
            console.log("mutableSliderObject ", mutableSliderObject)
        }




        let selectedActiveMenuDataReference = { ...state[selectedActiveMenu] }
        selectedActiveMenuDataReference['sliderValues'] = mutableSliderObject

        if (devModeBool == true) {
            console.log("selectedActiveMenuDataReference ", selectedActiveMenuDataReference)
        }












        // THIS IS A HACK SOLUTION THAT SHOULD BE RECONSIDERED LATER !!!
        // THIS IS A HACK SOLUTION THAT SHOULD BE RECONSIDERED LATER !!!
        // THIS IS A HACK SOLUTION THAT SHOULD BE RECONSIDERED LATER !!!
        // THIS IS A HACK SOLUTION THAT SHOULD BE RECONSIDERED LATER !!!        

        let unselectedInactiveMenu = null
        if (selectedActiveMenu == 'glassDataSearchObject') {
            unselectedInactiveMenu = "bottleDataSearchObject"
        }
        if (selectedActiveMenu == 'bottleDataSearchObject') {
            unselectedInactiveMenu = "glassDataSearchObject"
        }


        if (devModeBool == true) {
            console.log("unselectedInactiveMenu ", unselectedInactiveMenu)
        }

        let unselectedInactiveMenuDataReference = { ...state[unselectedInactiveMenu] }


        if (devModeBool == true) {
            console.log("unselectedInactiveMenuDataReference ", unselectedInactiveMenuDataReference)
        }


        unselectedInactiveMenuDataReference['sliderValues'] = mutableSliderObject




        if (selectedActiveMenu == 'glassDataSearchObject') {
            //selectedActiveMenu = "glassDataSearchObject"
            let returnObject = []
            return {
                fullWineData: state.fullWineData,
                glassDataArray: state.glassDataArray,
                bottleDataArray: state.bottleDataArray,
                glassDataSearchObject: selectedActiveMenuDataReference,
                bottleDataSearchObject: unselectedInactiveMenuDataReference,
                wineSearchResults: state.glassDataArray,
                wineSearchResultsLength: state.wineSearchResultsLength,
                wineFlightResults: state.wineFlightResults,
            };
        }

        if (selectedActiveMenu == 'bottleDataSearchObject') {
            //selectedActiveMenu = "bottleDataSearchObject"
            return {
                fullWineData: state.fullWineData,
                glassDataArray: state.glassDataArray,
                bottleDataArray: state.bottleDataArray,
                glassDataSearchObject: unselectedInactiveMenuDataReference,
                bottleDataSearchObject: selectedActiveMenuDataReference,
                wineSearchResults: state.glassDataArray,
                wineSearchResultsLength: state.wineSearchResultsLength,
                wineFlightResults: state.wineFlightResults,
            };
        }








        // return {
        //     fullWineData: state.fullWineData,
        //     glassDataArray: state.glassDataArray,
        //     bottleDataArray: state.bottleDataArray,
        //     glassDataSearchObject: state.glassDataSearchObject,
        //     bottleDataSearchObject: state.bottleDataSearchObject,
        //     wineSearchResults: state.glassDataArray,
        //     wineSearchResultsLength: state.wineSearchResultsLength,
        //     wineFlightResults: state.wineFlightResults,
        // };


        // if (selectedActiveMenu == 'glassDataSearchObject') {
        //     //selectedActiveMenu = "glassDataSearchObject"
        //     let returnObject = []
        //     return {
        //         fullWineData: state.fullWineData,
        //         glassDataArray: state.glassDataArray,
        //         bottleDataArray: state.bottleDataArray,
        //         glassDataSearchObject: selectedActiveMenuDataReference,
        //         bottleDataSearchObject: state.bottleDataSearchObject,
        //         wineSearchResults: state.glassDataArray,
        //         wineSearchResultsLength: state.wineSearchResultsLength,
        //         wineFlightResults: state.wineFlightResults,
        //     };
        // }
        // if (selectedActiveMenu == 'bottleDataSearchObject') {
        //     //selectedActiveMenu = "bottleDataSearchObject"
        //     return {
        //         fullWineData: state.fullWineData,
        //         glassDataArray: state.glassDataArray,
        //         bottleDataArray: state.bottleDataArray,
        //         glassDataSearchObject: state.glassDataSearchObject,
        //         bottleDataSearchObject: selectedActiveMenuDataReference,
        //         wineSearchResults: state.glassDataArray,
        //         wineSearchResultsLength: state.wineSearchResultsLength,
        //         wineFlightResults: state.wineFlightResults,
        //     };
        // }


        // if (typeof state.wineSearchResults === 'undefined') {
        //     console.log('The variable is undefined.');
        //     return {
        //         fullWineData: state.fullWineData,
        //         glassDataArray: state.glassDataArray,
        //         bottleDataArray: state.bottleDataArray,
        //         glassDataSearchObject: state.glassDataSearchObject,
        //         bottleDataSearchObject: state.bottleDataSearchObject,
        //         wineSearchResults: state.glassDataArray,
        //         wineSearchResultsLength: glassDataArrayLength,
        //         wineFlightResults: state.wineFlightResults,
        //     };
        // } else {
        //     console.log('The variable is defined.');
        //     return {
        //         fullWineData: state.fullWineData,
        //         glassDataArray: state.glassDataArray,
        //         bottleDataArray: state.bottleDataArray,
        //         glassDataSearchObject: state.glassDataSearchObject,
        //         bottleDataSearchObject: state.bottleDataSearchObject,
        //         wineSearchResults: state.wineSearchResults,
        //         wineSearchResultsLength: state.wineSearchResultsLength,
        //         wineFlightResults: state.wineFlightResults,
        //     };
        // }



    }


































    /************************************* 
        
        
        
           3333
              3
              3
              3
           3333
              3
              3
              3
              3
           3333
        
        
        
    *************************************/



    // action number 3


    if (action.type === 'UPDATE_WINE_SEARCH_RESULTS') {

        if (devModeBool == true) {
            console.log('UPDATE_WINE_SEARCH_RESULTS ', action.payload)
            console.log('STATE ', state)
        }





        let dataToSearchArray = null
        let selectedSearchObjectName = null

        let selectedSearchArrayName = null
        if (action.payload.activeMenu == 'glasses') {
            selectedSearchArrayName = "glassDataArray"
            selectedSearchObjectName = "glassDataSearchObject"

        }
        if (action.payload.activeMenu == 'bottles') {
            selectedSearchArrayName = "bottleDataArray"
            selectedSearchObjectName = "bottleDataSearchObject"

        }
        dataToSearchArray = state[selectedSearchArrayName]

        let sortByProperty = action.payload.sortBy
        let currentDataSearchPropertyArray = state[selectedSearchObjectName][sortByProperty]


        if (devModeBool == true) {
            console.log("SEARCH ARRAY: ", dataToSearchArray)
            console.log("currentDataSearchPropertyArray", currentDataSearchPropertyArray)
        }




        // now iterate over search array to obtain subset that meets search criteria
        // already narrowed between bottles and glasses
        // array.map and check each entry against relevant category (property, i.e. type, grape, or region)
        // if item passes category check, then check against tasting notes
        // get a list of all tasting notes of item
        // compare list against full tasting notes true, false doesnt exclude but lack of any true does
        // as soon as a single true is found, wine will be included
        // or could be done by tasting note exclusion, need to consider this, maybe build EXCLUDE feature
        // e.g. press once to turn note off, twice to exclude things with that note, not sure if thats a good idea or not
        // could make subset for each category of tasting notes that are true, but probably faster to make first version 
        //      if just compare everything and then check if false after


        let wineSearchResultsArray = []
        dataToSearchArray.forEach((dataElement) => {

            // console.log("SEARCHING ELEMENT : ", dataElement)
            // console.log(dataElement[sortByProperty])
            // console.log("currentDataSearchPropertyArray", currentDataSearchPropertyArray)

            let addedToResults = false
            let setAddToResults = false
            currentDataSearchPropertyArray.forEach((compareElement, compareElementIndex) => {
                addedToResults = false
                setAddToResults = false
                //console.log("compareElement ", compareElementIndex, compareElement['property'])
                let currentComparisonElement = compareElement['property']
                let currentComparisonTastingNotes = compareElement['tastingNotes']
                if (dataElement[sortByProperty] == currentComparisonElement[0]) {
                    //console.log("TRUE FALSE CHECK")
                    //console.log(compareElementIndex)
                    /////console.log(compareElement[sortByProperty][compareElementIndex-1]['property'])
                    if (currentComparisonElement[1] == true) {
                        //console.log("MATCH FOUND!")
                        let currentTastingNotes = dataElement['varietal_descriptors']
                        let currentTastingNotesLowerCase = currentTastingNotes.toLowerCase()
                        let currentTastingNotesLowerCaseStringWithoutSpaces = currentTastingNotesLowerCase.replace(/\s/g, "");
                        let tastingNotesArray = currentTastingNotesLowerCaseStringWithoutSpaces.split(",");
                        //console.log("currentComparisonTastingNotes ", currentComparisonTastingNotes)
                        //console.log("currentTastingNotes ", currentTastingNotes, tastingNotesArray)

                        // problem was comparing upper and lower case - this solved bottles, removing tasting notes with spaces mostly fixed glasses
                        // but there is still one glass that is having a problem, oh i missed one double word, maybe now it will work

                        tastingNotesArray.forEach((tastingNotesArrayElement) => {
                            //console.log('tastingNotesArrayElement ', tastingNotesArrayElement)
                            currentComparisonTastingNotes.forEach((comparisonTastingNotesArrayElement) => {

                                let comparisonTastingNotesArrayElementCopy = comparisonTastingNotesArrayElement[0]
                                let comparisonTastingNotesArrayElementLowerCase = comparisonTastingNotesArrayElementCopy.toLowerCase()
                                if (comparisonTastingNotesArrayElementLowerCase == tastingNotesArrayElement) {
                                    if (comparisonTastingNotesArrayElement[1] == true) {
                                        setAddToResults = true
                                        //console.log("addedToResults 1", setAddToResults)
                                    }
                                }
                                //console.log('comparisonTastingNotesArrayElement ', comparisonTastingNotesArrayElement)
                            })
                        })

                        if (devModeBool == true) {
                            console.log("addedToResults 2", setAddToResults)
                        }



                        if (setAddToResults == true) {
                            //console.log("addedToResults 3", setAddToResults)
                            if (addedToResults == false) {
                                wineSearchResultsArray.push(dataElement)
                                //console.log("addedToResults 4", setAddToResults, addedToResults)
                                addedToResults = true
                                //console.log("addedToResults 5", setAddToResults, addedToResults)
                            }
                        }
                        if (setAddToResults == false) {

                            if (devModeBool == true) {
                                console.log("FALSE UNADDED ELEMENT: ", dataElement)
                            }


                        }
                    }
                    if (currentComparisonElement[1] == false) {

                        if (devModeBool == true) {
                            console.log("MATCH EXCLUDED")
                        }


                    }
                }

            })
        })


        if (devModeBool == true) {
            console.log("wineSearchResultsArray  ", wineSearchResultsArray)
        }


        let wineSearchResultsLength = wineSearchResultsArray.length

        if (devModeBool == true) {
            console.log("wineSearchResultsLength  ", wineSearchResultsLength)
        }




        /*************************************     
            
               3333             1
                  3            11
                  3           1 1
                  3          1  1
               3333             1
                  3             1
                  3             1    
                  3             1
                  3   000       1
               3333   000   111111111
            
    
        *************************************/



        // for (let i = 0; i <= wineParamArrayLength; i++) {

        //     if (queryResultArray[i] == true) {
        //         ////console.log(i, " true")
        //         let testItem = randomArray[i]

        //         for (let k = 2; k <= 7; k++) {
        //             let testItemParamValue = testItem[k]
        //             if (testItemParamValue < lowLimitMatrix[k - 2]) {
        //                 ////console.log(i, " low false")
        //                 queryResultArray[i] = false
        //                 break
        //             }
        //             if (testItemParamValue > highLimitMatrix[k - 2]) {
        //                 ////console.log(i, " high false")
        //                 queryResultArray[i] = false
        //                 break
        //             }
        //         }
        //     }

        // }


        if (devModeBool == true) {
            console.log('3.10')
        }



        let wineSliderSearchResultsArray = []
        let wineSliderSearchResultsArrayLength = null

        if (devModeBool == true) {
            console.log('3.11', selectedSearchObjectName)
        }



        const sliderSearchObject = state[selectedSearchObjectName]['sliderValues']

        if (devModeBool == true) {
            console.log('3.12', sliderSearchObject)
            console.log('Object is empty', Object.keys(sliderSearchObject))
            console.log('3.13')
        }






        if (Object.keys(sliderSearchObject).length === 0) {

            if (devModeBool == true) {
                console.log('Object is empty');
            }


            wineSliderSearchResultsArray = wineSearchResultsArray
            wineSliderSearchResultsArrayLength = wineSearchResultsLength

        } else {




            if (devModeBool == true) {
                console.log('Object is not empty');
            }



            wineSearchResultsArray.forEach((dataElement) => {

                if (devModeBool == true) {
                    console.log(dataElement)
                }


                ////const sliderSearchObject = state[selectedSearchObjectName]['sliderValues']
                //console.log(sliderSearchObject)
                let addToResults = true
                Object.keys(sliderSearchObject).forEach((key, keyIndex) => {

                    if (devModeBool == true) {
                        console.log(key, keyIndex, sliderSearchObject[key], dataElement[key])
                    }


                    let sliderRange = sliderSearchObject[key]

                    // if (dataElement[key] >= sliderRange[0]) {
                    //     if (dataElement[key] <= sliderRange[1]) {
                    //         console.log("true")
                    //         addToResults = true
                    //     }
                    // }

                    if (dataElement[key] < sliderRange[0]) {

                        if (devModeBool == true) {
                            console.log("false")
                        }



                        addToResults = false
                    }
                    if (dataElement[key] > sliderRange[1]) {

                        if (devModeBool == true) {
                            console.log("false")
                        }



                        addToResults = false
                    }

                })

                if (addToResults == true) {
                    wineSliderSearchResultsArray.push(dataElement)
                }

            })

            wineSliderSearchResultsArrayLength = wineSliderSearchResultsArray.length





        }








        return {
            fullWineData: state.fullWineData,
            glassDataArray: state.glassDataArray,
            bottleDataArray: state.bottleDataArray,
            glassDataSearchObject: state.glassDataSearchObject,
            bottleDataSearchObject: state.bottleDataSearchObject,
            wineSearchResults: wineSliderSearchResultsArray,
            wineSearchResultsLength: wineSliderSearchResultsArrayLength,
            wineFlightResults: state.wineFlightResults,
        };

        // return {
        //     fullWineData: state.fullWineData,
        //     glassDataArray: state.glassDataArray,
        //     bottleDataArray: state.bottleDataArray,
        //     glassDataSearchObject: state.glassDataSearchObject,
        //     bottleDataSearchObject: state.bottleDataSearchObject,
        //     wineSearchResults: wineSearchResultsArray,
        //     wineSearchResultsLength: wineSearchResultsLength,
        //     wineFlightResults: state.wineFlightResults,
        // };



    }













































    /************************************* 
        
        
        
              4
             44
            4 4
           4  4
          4   4
         4444444
              4
              4
              4
              4
        
        
        
    *************************************/


    // action number 4

    if (action.type === 'INITIALIZE_WINE_SEARCH_RESULTS') {

        if (devModeBool == true) {
            console.log('INITIALIZE_WINE_SEARCH_RESULTS ', action.payload)
        }


        // console.log('STATE ', state)


        // console.log('action.payload.activeMenu ', action.payload.activeMenu)
        // console.log('action.payload.sortBy', action.payload.sortBy)

        let dataToSearchArray = null
        let selectedSearchObjectName = null

        let selectedSearchArrayName = null
        if (action.payload.activeMenu == 'glasses') {
            selectedSearchArrayName = "glassDataArray"
            selectedSearchObjectName = "glassDataSearchObject"

        }
        if (action.payload.activeMenu == 'bottles') {
            selectedSearchArrayName = "bottleDataArray"
            selectedSearchObjectName = "bottleDataSearchObject"

        }
        dataToSearchArray = state[selectedSearchArrayName]

        let sortByProperty = action.payload.sortBy
        /////  console.log('STATE ', state)

        let currentDataSearchPropertyArray = null



        try {
            currentDataSearchPropertyArray = state[selectedSearchArrayName]

        } catch {
            if (devModeBool == true) {
                console.log("state not ready")
            }

        }

















        return {
            fullWineData: state.fullWineData,
            glassDataArray: state.glassDataArray,
            bottleDataArray: state.bottleDataArray,
            glassDataSearchObject: state.glassDataSearchObject,
            bottleDataSearchObject: state.bottleDataSearchObject,
            wineSearchResults: currentDataSearchPropertyArray,
            wineSearchResultsLength: state.wineSearchResultsLength,
            wineFlightResults: state.wineFlightResults,
        };
    }






    /*
    
    
    
            console.log("SEARCH ARRAY: ", dataToSearchArray)
            console.log("currentDataSearchPropertyArray", currentDataSearchPropertyArray)
    
            // now iterate over search array to obtain subset that meets search criteria
            // already narrowed between bottles and glasses
            // array.map and check each entry against relevant category (property, i.e. type, grape, or region)
            // if item passes category check, then check against tasting notes
            // get a list of all tasting notes of item
            // compare list against full tasting notes true, false doesnt exclude but lack of any true does
            // as soon as a single true is found, wine will be included
            // or could be done by tasting note exclusion, need to consider this, maybe build EXCLUDE feature
            // e.g. press once to turn note off, twice to exclude things with that note, not sure if thats a good idea or not
            // could make subset for each category of tasting notes that are true, but probably faster to make first version 
            //      if just compare everything and then check if false after
    
    
            let wineSearchResultsArray = []
            dataToSearchArray.forEach((dataElement) => {
    
                // console.log("SEARCHING ELEMENT : ", dataElement)
                // console.log(dataElement[sortByProperty])
                // console.log("currentDataSearchPropertyArray", currentDataSearchPropertyArray)
    
                let addedToResults = false
                let setAddToResults = false
                currentDataSearchPropertyArray.forEach((compareElement, compareElementIndex) => {
    
                    //console.log("compareElement ", compareElementIndex, compareElement['property'])
                    let currentComparisonElement = compareElement['property']
                    let currentComparisonTastingNotes = compareElement['tastingNotes']
                    if (dataElement[sortByProperty] == currentComparisonElement[0]) {
                        //console.log("TRUE FALSE CHECK")
                        //console.log(compareElementIndex)
                        /////console.log(compareElement[sortByProperty][compareElementIndex-1]['property'])
                        if (currentComparisonElement[1] == true) {
                            //console.log("MATCH FOUND!")
                            let currentTastingNotes = dataElement['tasting_notes']
                            let currentTastingNotesLowerCase = currentTastingNotes.toLowerCase()
                            let currentTastingNotesLowerCaseStringWithoutSpaces = currentTastingNotesLowerCase.replace(/\s/g, "");
                            let tastingNotesArray = currentTastingNotesLowerCaseStringWithoutSpaces.split(",");
                            //console.log("currentComparisonTastingNotes ", currentComparisonTastingNotes)
                            //console.log("currentTastingNotes ", currentTastingNotes, tastingNotesArray)
    
    
                            tastingNotesArray.forEach((tastingNotesArrayElement) => {
                                //console.log('tastingNotesArrayElement ', tastingNotesArrayElement)
                                currentComparisonTastingNotes.forEach((comparisonTastingNotesArrayElement) => {
                                    if (comparisonTastingNotesArrayElement[0] == tastingNotesArrayElement){
                                        if (comparisonTastingNotesArrayElement[1] == true){
                                            setAddToResults = true
                                            //console.log("addedToResults 1", setAddToResults)
                                        }
                                    }
                                    //console.log('comparisonTastingNotesArrayElement ', comparisonTastingNotesArrayElement)
                                })
                            })
    
                            console.log("addedToResults 2", setAddToResults)
    
                            if (setAddToResults == true){
                                //console.log("addedToResults 3", setAddToResults)
                                if (addedToResults == false){
                                    wineSearchResultsArray.push(dataElement)
                                    //console.log("addedToResults 4", setAddToResults, addedToResults)
                                    addedToResults = true
                                    //console.log("addedToResults 5", setAddToResults, addedToResults)
                                }
                            }
    
                        }
                        if (currentComparisonElement[1] == false) {
                            console.log("MATCH EXCLUDED")
                        }
                    }
    
                })
            })
    
    
    
    
    
    
    
    */



    //console.log("wineSearchResultsArray  ", wineSearchResultsArray)




















































    // 




    // if (action.type === 'TOGGLE_ALL') {

    //     console.log('TOGGLE_ALL ',  action.payload)



    //     return {
    //         fullWineData: state.fullWineData,
    //         glassDataArray: state.glassDataArray,
    //         bottleDataArray: state.bottleDataArray,
    //         glassDataSearchObject: state.glassDataSearchObject,
    //         bottleDataSearchObject: state.bottleDataSearchObject,
    //         wineSearchResults: state.wineSearchResults,
    //     };

    // }











    /************************************* 
    
    
    
     555555
     5
     5
     5
      5555
          5
          5
          5
     5555
    
    
    
    /*************************************/


    // action number 5

    if (action.type === 'TOGGLE_ALL') {

        const toggleBoolValue = action.payload.toggleValue
        const toggleBoolValue2 = action.payload.toggleValue

        const wineDataArray = state.fullWineData

        /*

                let glassDataArray = []
                let bottleDataArray = []
        
                wineDataArray.forEach((element, index) => {
                    // console.log(`Element at index ${index}: ${element}`);
                    // console.log(element);
                    // console.log(element.price_bottle);
                    // console.log(element.price_glass);
                    if (element.price_glass_8_oz === null) {
                        //console.log("this item is a BOTTLE")
                        bottleDataArray.push(element)
                    } else {
                        //console.log("NOT A BOTTLE")
                        glassDataArray.push(element)
                    }
                });
                
        */



        let glassDataArray = state.glassDataArray
        let bottleDataArray = state.bottleDataArray
        // let glassDataSearchObject = state.glassDataSearchObject
        // let bottleDataSearchObject = state.bottleDataSearchObject


        let glassDataSearchObject = {
            type: [],
            grape: [],
            region: [],
            country_or_domestic_state: [],
            sliderValues: {},
        }
        let bottleDataSearchObject = {
            type: [],
            grape: [],
            region: [],
            country_or_domestic_state: [],
            sliderValues: {},
        }

        // - iterate over this set and generate checkbox search categories based off of selection (type, grape, or location)

        glassDataArray.forEach((element, index) => {
            //console.log(element.type);
            //console.log(element.region);
            //console.log(element.grape);

            if (!glassDataSearchObject.type.includes(element.type)) {
                glassDataSearchObject.type.push(element.type);
            }


        });


        if (devModeBool == true) {
            console.log('glassDataSearchObject ', glassDataSearchObject)
        }

























        // pass all of the glasses data or bottles data along with the key to be populated: type, grape, or region
        const populateSearchObject = (dataArray, searchKey) => {

            let returnArray = []
            let returnArray2 = []
            const dataMap = {};


            // here the first level of the dataMap is populated with each unique ky in the dataarray, e.g. bubbles white rose red
            dataArray.forEach((element) => {

                if (!returnArray.includes(element[searchKey])) {
                    returnArray.push(element[searchKey]);
                    dataMap[element[searchKey]] = []
                }

                dataMap[element[searchKey]].push(element)

            });


            // console.log("returnArray", returnArray)
            // console.log("dataMap  ", dataMap)
            // console.log("Object.keys(dataMap)  ", Object.keys(dataMap))
            // console.log("Object.entries(dataMap)  ", Object.entries(dataMap))



            // ALL TASTING NOTE STUFF
            Object.keys(dataMap).forEach((key, keyIndex) => {

                let dataMapElementArray = dataMap[key]
                let tastingNotesArray = []
                let tastingNotesArray2 = []
                let tastingNoteIndex = 0
                //console.log("key, obj[key] ", key, dataMap[key]);

                dataMapElementArray.forEach((element) => {
                    //const parsedArray = JSON.parse(element.tasting_notes);

                    // let commaSeparatedString = element.varietal_descriptors
                    // console.log("element.varietal_descriptors" , element.varietal_descriptors)
                    // if (commaSeparatedString == null){
                    //     commaSeparatedString = '1,2'
                    // }

                    const commaSeparatedString = element.varietal_descriptors
                    const elements = commaSeparatedString.split(',').map(item => item.trim());
                    const jsonString = JSON.stringify(elements);
                    //console.log(jsonString);
                    //console.log(typeof jsonString);
                    const parsedArray = JSON.parse(jsonString);

                    // [stringTastingNote, true, tastingNoteIndex]


                    parsedArray.forEach((stringTastingNote) => {
                        if (!tastingNotesArray.includes(stringTastingNote)) {
                            tastingNotesArray.push(stringTastingNote);
                            tastingNotesArray2.push([stringTastingNote, toggleBoolValue2, [keyIndex, tastingNoteIndex]]);
                            tastingNoteIndex += 1
                        }
                    });


                    //console.log("element.tasting_notes", element.tasting_notes)

                });

                /////console.log("FOR KEY OF : ", key, " TASTING NOTES ARE: ", tastingNotesArray2)


                // populates the returnarray 
                returnArray2.push({ property: [key, toggleBoolValue, [keyIndex]], tastingNotes: tastingNotesArray2 })

            });



            return returnArray2

        };




        glassDataSearchObject.type = populateSearchObject(glassDataArray, 'type')
        glassDataSearchObject.grape = populateSearchObject(glassDataArray, 'grape')
        glassDataSearchObject.region = populateSearchObject(glassDataArray, 'region')
        glassDataSearchObject.country_or_domestic_state = populateSearchObject(glassDataArray, 'country_or_domestic_state')
        glassDataSearchObject.sliderValues = state['glassDataSearchObject']['sliderValues']

        //console.log('glassDataArray ',glassDataArray)
        // console.log('glassDataSearchObject ',glassDataSearchObject)

        bottleDataSearchObject.type = populateSearchObject(bottleDataArray, 'type')
        bottleDataSearchObject.grape = populateSearchObject(bottleDataArray, 'grape')
        bottleDataSearchObject.region = populateSearchObject(bottleDataArray, 'region')
        bottleDataSearchObject.country_or_domestic_state = populateSearchObject(bottleDataArray, 'country_or_domestic_state')
        bottleDataSearchObject.sliderValues = state['bottleDataSearchObject']['sliderValues']

        //console.log('QUERY_WINE_DATA_SUCCESS action.payload: ', action.payload)
        return {
            fullWineData: state.fullWineData,
            glassDataArray: state.glassDataArray,
            bottleDataArray: state.bottleDataArray,
            glassDataSearchObject: glassDataSearchObject,
            bottleDataSearchObject: bottleDataSearchObject,
            wineSearchResults: state.wineSearchResults,
            wineSearchResultsLength: state.wineSearchResultsLength,
            wineFlightResults: state.wineFlightResults,
        };
    }
















































    /*************************************
    
    
    
        666
       6
      6
     6
     66666
     6    6
     6    6
     6    6
      6666
    
    
    
    /*************************************/

    // action number 6

    if (action.type === 'GENERATE_CURRENT_RESULTS') {



        // console.log("state.glassDataArray ", state.glassDataArray)


        let glassDataArrayLength = state.glassDataArray.length


        // console.log("state.wineSearchResults", state.wineSearchResults)
        // console.log("state.wineSearchResultsLength", state.wineSearchResultsLength)

        // return {
        //     fullWineData: state.fullWineData,
        //     glassDataArray: state.glassDataArray,
        //     bottleDataArray: state.bottleDataArray,
        //     glassDataSearchObject: state.glassDataSearchObject,
        //     bottleDataSearchObject: state.bottleDataSearchObject,
        //     wineSearchResults: state.glassDataArray,
        //     wineSearchResultsLength: glassDataArrayLength,
        //     wineFlightResults: state.wineFlightResults,
        // };

        if (typeof state.wineSearchResults === 'undefined') {
            if (devModeBool == true) {
                console.log('The variable is undefined.');
            }

            return {
                fullWineData: state.fullWineData,
                glassDataArray: state.glassDataArray,
                bottleDataArray: state.bottleDataArray,
                glassDataSearchObject: state.glassDataSearchObject,
                bottleDataSearchObject: state.bottleDataSearchObject,
                wineSearchResults: [],
                wineSearchResultsLength: 0,
                wineFlightResults: state.wineFlightResults,
            };
        } else if (state.wineSearchResultsLength == 0) {
            if (devModeBool == true) {
                console.log('The variable is defined.');
            }

            return {
                fullWineData: state.fullWineData,
                glassDataArray: state.glassDataArray,
                bottleDataArray: state.bottleDataArray,
                glassDataSearchObject: state.glassDataSearchObject,
                bottleDataSearchObject: state.bottleDataSearchObject,
                wineSearchResults: [],
                wineSearchResultsLength: 0,
                wineFlightResults: state.wineFlightResults,
            };
        } else {
            if (devModeBool == true) {
                console.log('The variable is defined.');
            }

            return {
                fullWineData: state.fullWineData,
                glassDataArray: state.glassDataArray,
                bottleDataArray: state.bottleDataArray,
                glassDataSearchObject: state.glassDataSearchObject,
                bottleDataSearchObject: state.bottleDataSearchObject,
                wineSearchResults: state.wineSearchResults,
                wineSearchResultsLength: state.wineSearchResultsLength,
                wineFlightResults: state.wineFlightResults,
            };
        }



        // return {
        //     fullWineData: state.fullWineData,
        //     glassDataArray: state.glassDataArray,
        //     bottleDataArray: state.bottleDataArray,
        //     glassDataSearchObject: state.glassDataSearchObject,
        //     bottleDataSearchObject: state.bottleDataSearchObject,
        //     wineSearchResults: state.glassDataArray,
        //     wineSearchResultsLength: glassDataArrayLength,
        // };

    }

























































    /*



 777777
       7
       7
      7
     7
    7
   7
   7
   7
   7



*/








    return state;

}











/*



order to do stuff:
    *make dummy database and populate w/ sample data
    *make server for db that can query and get info and send
    *accept data in react app and 
    *    -use to populate redux entry
    
    *when load to /winelist page, auto download current version of database

    dynamically generate searh menu based off of data
        *- iterate over all entries, and create set of items in selection e.g. bottles, flights, glasses
        note: still need to do more for flights
            - save this to the "search results" secion of redux store
            --- update --- after first getting data from server, populate list for all three, i.e. make complete list of bottles and glasses
            --- also make data structure for flights

        - iterate over this set and generate checkbox search categories based off of selection (type, grape, or location)
        - iterate over each checkbox category and generate list of flavor notes
        - render page with appropriate checkboxes populated with flavor notes
        - hard code slider options for each of : glasses / bottles  -- (flights will not be searchable)
            - e.g. checkboxes: true, slider1: true, ... , slider6: true, for bottles but : slider5: false for glasses, and all
            false for flights
        - flights will require special logic as it is going to be displayed differently, however this will be 
        handled by "flights" sub-component of winemenu page



        - checkbox data will be stores with useState in a SINGLE variable which will consiste of an object with 
        keys generated from the redux checkbox categories, and each of these will contain an object with a key
        to set category to be true/false AND
        there will also be another key in this object with "flavor notes" which will have a value of another object
        which will have each flavor note as a key with a value of true/false indicating display status

        - slider data will be stored for each slider in a separate useState value, and this will contain a key for
        true or false to indicate whether to render this slider on the page, and if slider is NOT rendered its range will be 
        fully selected at all times so it can still be searched by but will NOT exclude anything
        so slider is effectively still there and is just invisible and inaccessible



    write code to query and create display data based off of full dataset




















    Certainly! Here's a selection of top 5 flavor notes for each type of wine:

Red Wine Flavor Notes:

    Blackberry
    Cherry
    Plum
    Blackcurrant
    Vanilla

White Wine Flavor Notes:

    Apple
    Pear
    Citrus (e.g., lemon, lime)
    Peach
    Floral (e.g., jasmine, honeysuckle)

Rosé Wine Flavor Notes:

    Strawberry
    Raspberry
    Watermelon
    Citrus (e.g., orange, grapefruit)
    Floral (e.g., rose, violet)

Sparkling Wine/Champagne Flavor Notes:

    Green Apple
    Pear
    Citrus (e.g., lemon, lime)
    Brioche
    Toasty










Certainly! Here's a list of common flavor notes associated with different types of wine:

    Red Wine Flavor Notes:
        Blackberry
        Cherry
        Plum
        Blackcurrant
        Raspberry
        Black Cherry
        Vanilla
        Tobacco
        Cedar
        Chocolate
        Earthy
        Spicy (e.g., black pepper, cloves)

    White Wine Flavor Notes:
        Apple
        Pear
        Citrus (e.g., lemon, lime, grapefruit)
        Peach
        Apricot
        Melon
        Tropical fruits (e.g., pineapple, mango)
        Honey
        Floral (e.g., jasmine, honeysuckle)
        Mineral
        Crisp
        Creamy (e.g., buttery, vanilla)

    Rosé Wine Flavor Notes:
        Strawberry
        Raspberry
        Watermelon
        Cranberry
        Redcurrant
        Peach
        Citrus (e.g., orange, grapefruit)
        Floral (e.g., rose, violet)
        Herbaceous
        Refreshing
        Light-bodied

    Sparkling Wine/Champagne Flavor Notes:
        Green Apple
        Pear
        Citrus (e.g., lemon, lime)
        Brioche
        Toasty
        Nutty
        Yeasty
        Floral (e.g., acacia, jasmine)
        Crisp
        Effervescent

Please note that these flavor notes are not exhaustive and can vary depending on the specific wine varietals, regions, winemaking techniques, and personal interpretations. Wine tasting experiences can be subjective, and individual palates may detect different flavors. Feel free to use this list as a starting point and adapt it according to your specific needs and preferences in your wine app.








*/













