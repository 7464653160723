import React, { useState } from "react";
import { useTransition, useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import '../../images/book/app2.css'

import importedImage from '../../images/book/cover.jpg'
import importedImage1 from '../../images/book/paper1.jpg'
// import importedImage2 from 'desk.jpg'
// import importedImage3 from 'logo.png'
// import importedImage4 from '102.jpg'

/// import importedImage5 from '../../images/book/backcover1.jpg'

// import importedImage6 from '../images/book/bottle.jpg'
// import importedImage7 from '../images/book/paper2.jpg'
// import importedImage8 from '../images/book/button.png'

import Modal from "react-modal";
import '../../images/book/modalStyles.css'
//import ModalComponent from './ModalComponent'
import InfoPage from './InfoPage'










const devModeBool = false

// if (devModeBool == true) {

// }








const Book = () => {

















    const bookPages = [
        { id: 0, renderModal: false, pr: '4rem', prt: '2.7rem', z: 15, z2: 35, image: importedImage, text: "" },
        { id: 1, renderModal: true, pr: '4.2rem', prt: '2.9rem', z: 14, z2: 34, image: importedImage1, text: "<h1>page 1 - red wine bottle ---- $100</h1>" },
        { id: 2, renderModal: true, pr: '4.4rem', prt: '3.1rem', z: 13, z2: 33, image: importedImage1, text: "page 2 - white wine bottle ---- $200" },
        { id: 3, renderModal: true, pr: '4.6rem', prt: '3.3rem', z: 12, z2: 32, image: importedImage1, text: "page 3 - red wine bottle 2 ---- $300" },
        { id: 4, renderModal: true, pr: '4.8rem', prt: '3.5rem', z: 11, z2: 31, image: importedImage1, text: "page 4 - white wine bottle 2 ---- $400" },
        //{ id: 5, renderModal: true, pr: '5rem', prt: '3.7rem', z: 10, z2: 30, image: importedImage1, text: "page 5 - red wine bottle ---- $100" },
        // { id: 6, renderModal: true, pr: '5.2rem', prt: '3.9rem', z: 9, z2: 29, image: importedImage1, text: "page 6 - white wine bottle ---- $200" },
        // { id: 7, pr: '65px', prt: '65px', z: 8, z2: 28, image: importedImage1, text: "page 7 - red wine bottle 2 ---- $300" },
        // { id: 8, pr: '70px', prt: '70px', z: 7, z2: 27, image: importedImage1, text: "page 8 - red wine bottle ---- $100" },
        // { id: 9, pr: '75px', prt: '75px', z: 6, z2: 26, image: importedImage1, text: "page 9 - white wine bottle ---- $200" },
        // { id: 10, pr: '80px', prt: '80px', z: 5, z2: 25, image: importedImage1, text: "page 10 - red wine bottle 2 ---- $300" },
        // { id: 11, pr: '85px', prt: '85px', z: 4, z2: 24, image: importedImage1, text: "page 11 - white wine bottle 2 ---- $400" },
        // { id: 12, pr: '90px', prt: '90px', z: 3, z2: 23, image: importedImage1, text: "page 12 - red wine bottle ---- $100" },
        // { id: 13, pr: '95px', prt: '95px', z: 2, z2: 22, image: importedImage1, text: "page 13 - white wine bottle ---- $200" },
        // { id: 14, pr: '100px', prt: '100px', z: 1, z2: 21, image: importedImage1, text: "page 14 - red wine bottle 2 ---- $300" },
        { id: 7, renderModal: false, pr: '105px', prt: '105px', z: 0, z2: 20, image: importedImage1, text: "THE END" },
    ]

    // importedImage5

    const [{ x, y }, set] = useSpring(() => ({ x: 0, y: 0 }));


    const [value, setValue] = useState({

        disabled: false,
        currentPageId: 0,

    });

    const [page, setPage] = useState({

        currentPageId: 0,
        previousPageId: 0,

    });







    let firstSlice = bookPages.slice(1)
    for (let x = 0; x < firstSlice.length; x++) {
        firstSlice[x].pr = String(3 + (x + 1) * .4) + 'rem'
    }

    if (devModeBool == true) {
        console.log('firstSlice ', firstSlice)
    }

    

    const [pagesRemaining, setPagesRemaining] = useState({

        pageArray: firstSlice,
    });



    const transitionVars = {
        pageForward: {
            from: {
                x: '0%',
                scale: '1',
                rotateY: '0deg',
            },
            leave: {
                x: "-50%",
                scale: '1.1',
                rotateY: '-90deg',
            }
        },
        pageBack: {
            from: {
                x: "-50%",
                scale: '1.1',
                rotateY: '-90deg',
            },
            leave: {
                x: '0%',
                scale: '1',
                rotateY: '0deg',
            }
        }
    }


    const [pageDirection, setPageDirection] = useState({

        direction: transitionVars.pageForward,

    });

















    const bind = useDrag(({ down, movement: [mx, my] }) => {
        //set({ x: down ? mx : 0, y: down ? my : 0 })
        //console.log("x", x.animation.fromValues[0]);

        if (devModeBool == true) {
            console.log("mx", mx);
        }



        //if (x.animation.fromValues[0] < -20) {




        if (value.disabled == false) {

            if (mx < -50) {

                if (devModeBool == true) {
                    console.log("flip")
                }


                
                setIsVisible(v => !v)
                set({ x: 0, y: 0 })
                //console.log("x", x.animation.fromValues[0]);

                setValue({
                    disabled: true,
                });




                setPageDirection({
                    direction: transitionVars.pageForward,
                });




                ///////////////////////////////////////////////////////
                ///////////////////////////////////////////////////////
                ///////////////////////////////////////////////////////
                ///////////////////////////////////////////////////////


                setTimeout(() => {



                    if (page.currentPageId < bookPages.length - 1) {

                        if (devModeBool == true) {
                            console.log("length ", page.currentPageId, bookPages.length)
                        }


                        
                        setPage({
                            currentPageId: page.currentPageId + 1,
                            previousPageId: page.currentPageId,
                        });

                        let newSlice = bookPages.slice(page.currentPageId + 1)
                        //   newSlice.map((entry)=>{
                        //       console.log('entry ', entry)
                        //   }) 


                        for (let x = 0; x < newSlice.length; x++) {
                            newSlice[x].pr = String(3 + (x + 1) * .4) + 'rem'

                        }

                        if (devModeBool == true) {
                            console.log('newSlice ', newSlice)
                        }


                        

                        setPagesRemaining({
                            pageArray: newSlice,
                        })

                    } else {

                        setPage({
                            currentPageId: 0,
                            previousPageId: page.currentPageId,
                        });

                        let newSlice = bookPages.slice(1)
                        for (let x = 0; x < newSlice.length; x++) {
                            newSlice[x].pr = String(3 + (x + 1) * .4) + 'rem'
                        }

                        if (devModeBool == true) {
                            console.log('newSlice ', newSlice)
                        }

                        

                        setPagesRemaining({
                            pageArray: newSlice, //bookPages.slice(page.currentPageId)
                        })

                    }





                    setValue({
                        disabled: false,
                    });



                }, 400)



            }




            if (mx > 50) {


                if (devModeBool == true) {
                    console.log("flip")
                }


                
                setIsVisible(v => !v)
                set({ x: 0, y: 0 })

                setValue({
                    disabled: true,
                });


                setPageDirection({
                    direction: transitionVars.pageBack,
                });


                if (page.currentPageId > 0) {

                    if (devModeBool == true) {
                        console.log("length ", page.currentPageId, bookPages.length)
                    }


                    setPage({
                        currentPageId: page.currentPageId - 1,
                        previousPageId: page.currentPageId,
                    });


                    let newSlice = bookPages.slice(page.currentPageId - 1)
                    //   newSlice.map((entry)=>{
                    //       console.log('entry ', entry)
                    //   }) 


                    for (let x = 0; x < newSlice.length; x++) {
                        newSlice[x].pr = String(3 + (x + 1) * .4) + 'rem'

                    }

                    if (devModeBool == true) {
                        console.log('newSlice ', newSlice)
                    }



                    


                    setPagesRemaining({
                        pageArray: newSlice, //bookPages.slice(page.currentPageId)
                    })

                } else {

                    setPage({
                        currentPageId: bookPages.length - 1,
                        previousPageId: page.currentPageId,
                    });


                    let newSlice = bookPages.slice(bookPages.length)
                    for (let x = 0; x < newSlice.length; x++) {
                        newSlice[x].pr = String(3 + (x + 1) * .4) + 'rem'
                    }

                    if (devModeBool == true) {
                        console.log('newSlice ', newSlice)
                    }


                    

                    setPagesRemaining({
                        pageArray: newSlice, //bookPages.slice(page.currentPageId)
                    })

                }

                setTimeout(() => {
                    setValue({
                        disabled: false,
                    });
                }, 300)

            }

        }


    });













    const [isVisible, setIsVisible] = useState(false)

    const { xy, c } = useSpring({ // extracted values from the object props

        // from: { xy: [0, 0], c: "green" },
        //xy: isVisible ? [800, 200] : [0, 0],
        //c: isVisible ? "red" : "green",

    });

    const transition = useTransition(isVisible, {
        from: {
            opacity: ".5",
            //rotateX: '100deg',

            //rotateY:"-90deg",
            position: "absolute",
            //config: { duration: "100" },
            zIndex: bookPages[page.currentPageId].z2,

            //x: "0%",

            x: pageDirection.direction.from.x,//"-50%" ,//"-50%",
            scale: pageDirection.direction.from.scale, //'1.1',//'1.1',
            rotateY: pageDirection.direction.from.rotateY, //'-90deg',    //"-90deg",


        },
        enter: {
            opacity: "1",
            //rotateX: "0deg",


            rotateX: "0deg",
            position: "absolute",
            config: { duration: "600" },
            zIndex: bookPages[page.currentPageId].z2,

            scale: '1',
            x: "0%",
            rotateY: "0deg",



        },
        leave: {
            opacity: "0.8",
            //rotateX: "-100deg",

            //rotateX:"-2deg",
            position: "absolute",
            config: { duration: "400" },
            zIndex: bookPages[page.currentPageId].z2,

            // x:"0%" ,//"-50%",
            // //y: "5%",
            // scale: '1.0',//'1.1',
            // rotateY: '0deg',    //"-90deg",


            // x:"-50%" ,//"-50%",
            // scale: '1.1',//'1.1',
            // rotateY: '-90deg',    //"-90deg",

            x: pageDirection.direction.leave.x,//"-50%" ,//"-50%",
            scale: pageDirection.direction.leave.scale, //'1.1',//'1.1',
            rotateY: pageDirection.direction.leave.rotateY, //'-90deg',    //"-90deg",
        },
        immediate: key => key === "zIndex"
    })












    // <button style={{ padding: '5rem' }} onClick={() => {
    //   setIsVisible(v => !v)
    // }}  >click</button>










    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }


























    return (






        <div style={{ width: '100%', height: 'auto', position: 'relative' }} >

            <div className="container"
                style={{
                    //backgroundImage: `url(${importedImage2})`,
                    backgroundColor: 'linen',// originally 'indigo', rosybrown color for background behind book
                    backgroundRepeat: 'repeat',
                    backgroundSize: 'contain',
                    height: 'auto',
                    //minHeight: '1300px',
                    width: '100%',//'60rem',
                }}
            >

                {transition((style, item, t, index) =>
                    // item ?
                    <div style={{
                        position: 'absolute',
                        // left:"3.2rem", these work for page size 0.2rem
                        // top: "1.8rem",
                        left: "3rem",
                        top: "3.1rem",
                    }}>

                        <animated.div
                            style={{
                                ...style,
                            }}
                        //className='item'
                        >

                            <animated.div
                                {...bind()}
                                className="Square"
                                style={{ x, y, touchAction: "none" }}
                            >

                                <div style={{
                                    //  display: 'flex', 
                                    //  flexWrap: 'nowrap',  
                                    //  justifyContent: 'flex-start', 
                                    //width: '30rem',
                                    //margin: '0px',
                                    backgroundImage: `url(${bookPages[page.currentPageId].image})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    width: '20rem',
                                    height: '30rem',
                                    borderRadius: '5px',
                                    padding: '0rem',
                                    margin: '0rem',
                                    //borderRight: '5px solid gray',
                                    //borderRight: '12px solid gray',
                                    //flexDirection: 'column',
                                    //justifyContent: 'center',
                                    zIndex: bookPages[page.currentPageId].z2,
                                    //   position: 'relative',
                                    //   left: bookPages[page.currentPageId].pr,
                                    //   top: bookPages[page.currentPageId].prt,
                                    // position: 'absolute',
                                    //   left:"3rem",
                                    //   top: "3rem",
                                }}
                                >





                                    <div >
                                        <InfoPage style={{ color: 'blue', margin: '0rem', padding: '0rem' }} render={{ pages: bookPages, pageNumber: page.currentPageId }} />
                                    </div>







                                </div>
                            </animated.div>
                        </animated.div>
                    </div>
                )}



                <div
                    style={{
                        //  display: 'flex', 
                        //  flexWrap: 'nowrap',  
                        //  justifyContent: 'flex-start', 
                        //width: '30rem',
                        position: 'absolute',
                        //left: '300px',
                        // paddingLeft: bookPages[page.currentPageId].pr,
                        // paddingTop: bookPages[page.currentPageId].pr,
                        backgroundImage: `url(${importedImage1})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        width: '20rem',
                        height: '30rem',
                        zIndex: '19',
                        left: '3rem',
                        top: '3.1rem',
                        borderRight: '0px solid gray',
                        borderBottom: '0px solid gray',
                        // borderLeft: '5px solid gray',
                        borderRadius: '5px',
                    }}>

                </div>





                {pagesRemaining.pageArray.map((row) => (
                    <div key={row.id}
                        style={{
                            //  display: 'flex', 
                            //  flexWrap: 'nowrap',  
                            //  justifyContent: 'flex-start', 
                            //width: '30rem',
                            position: 'absolute',
                            //left: '300px',
                            // paddingLeft: bookPages[page.currentPageId].pr,
                            // paddingTop: bookPages[page.currentPageId].pr,
                            backgroundImage: `url(${row.image})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            width: '20rem',
                            height: '30rem',
                            zIndex: row.z,
                            left: row.pr,
                            top: row.pr,
                            borderRight: '0px solid gray',
                            borderBottom: '0px solid gray',
                            // borderLeft: '5px solid gray',
                            borderRadius: '5px',
                        }}>

                    </div>
                ))}


            </div>

        </div>








    );
};

export default Book;







/*


                      <InfoPage style={{ color: 'blue' , margin: '0rem' , padding: '0rem' }} render={bookPages[page.currentPageId].renderModal} />
  

*/



