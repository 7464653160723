import React from 'react';
import Menubar from "../../components/layout/MenuBarDev"

import { Grid, Box, Typography } from '@mui/material';

import Footer from "../../components/layout/Footer"
import Book from "./Book"
import ImgScroll from "./ImgScroll"


const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            width: '100%',

            backgroundColor: 'black',
            height: '30rem',
        }}
    >
        {props.children}
    </Box>
);



const WineBook = (props) => {
    // Component logic and state go here

    return (
        <div>
            <StyledBox >
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        <Menubar ></Menubar>
                    </Grid>
                </Grid>
                {/* <Typography align="center" fontSize='2.3rem' fontWeight='bold' backgroundColor='black' color='rgb(171,18,57)' >
                    WineBook
                </Typography> */}
                {/* 
                <Book></Book>
 */}
                <Book></Book>
            </StyledBox>

            <StyledBox >



            </StyledBox>



            <Footer></Footer>

        </div>
        // JSX markup for the component goes here
    );
};

export default WineBook;