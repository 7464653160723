import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import SliderElement from "./SliderElement";
import { useState, useEffect } from "react";

const StyledPaper = (props) => (
    <Paper
        sx={{
            backgroundColor: 'silver', color: 'black' // slategray / dimgray / gray / silver
        }}
    >
        {props.children}
    </Paper>
);

const StyledBoxText = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: 'center',
            width: '100%',
        }}
    >
        {props.children}
    </Box>
);


// let min = 0
// let max = 5
// let step = 1
// let marks = true
// const CustomSlider = (props) => (
//     <Slider
//         size="medium" // or small
//         // value={value}
//         // onChange={handleChange}
//         min={min}
//         max={max}
//         step={step}
//         //marks={marks}
//         valueLabelDisplay="auto"
//         //getAriaValueText={value => `${value}`}
//         color='primary'
//     >
//         {props.children}

//     </Slider>
// );




const devModeBool = false

// if (devModeBool == true) {

// }




const CustomSlider = (props) => {

    // Extract data from props
    const { data } = props;
    const { labels } = props;
    ///// console.log('labels: ', labels)

    const { updateSliderValues } = props;


    const [sliderValue, setSliderValue] = useState([0, 5]);
    const handleChangeSlider = newValue => {
        setSliderValue(newValue);

        if (devModeBool == true) {
            console.log("slider value ", sliderValue, newValue)
        }


        
        updateSliderValues(newValue, labels.sliderName)
    };


    const handleChangeButton = changeValue => {

        if (devModeBool == true) {
            console.log("changeValue", changeValue)
            console.log("sliderValue", sliderValue)
        }




        let newSliderValue = []

        if (changeValue < 0) {

            if (sliderValue[0] > 1) {
                newSliderValue = [sliderValue[0] - 1, sliderValue[1]]
            } else if (sliderValue[1] > 1) {
                newSliderValue = [sliderValue[0], sliderValue[1] - 1]
            } else {
                newSliderValue = sliderValue
            }

        }

        if (changeValue > 0) {

            if (sliderValue[1] < 5) {
                newSliderValue = [sliderValue[0], sliderValue[1] + 1]
            } else if (sliderValue[0] < 5) {
                newSliderValue = [sliderValue[0] + 1, sliderValue[1]]
            } else {
                newSliderValue = sliderValue
            }

        }

        if (devModeBool == true) {
            console.log("sliderValue", sliderValue, "newSliderValue", newSliderValue)
        }

        
        handleChangeSlider(newSliderValue)
    };



    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: "space-between",
            //opacity: '70%',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
        }}>

            <Grid item xs={3} sm={3} md={3} onClick={() => handleChangeButton('-1')} >
                <StyledPaper

                ><Typography
                    style={{ padding: '0px', margin: '10px', width: '22vw' }}
                    fontWeight='bold' fontSize='1rem'

                >{labels['left']}</Typography></StyledPaper>
            </Grid>
            <Grid item xs={5} sm={5} md={5} style={{ width: '22vw' }}>
                <SliderElement
                    value={sliderValue}
                    onChange={handleChangeSlider}
                    min={1}
                    max={5}
                    step={1}
                    marks={true}



                ></SliderElement>
            </Grid>
            <Grid item xs={2} sm={2} md={2} onClick={() => handleChangeButton('1')} >
                <StyledPaper><Typography
                    style={{ padding: '0px', margin: '10px', width: '22vw' }}
                    fontWeight='bold' fontSize='1rem'>{labels['right']}</Typography></StyledPaper>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
                <StyledPaper

                ><Typography
                    style={{ padding: '0px', margin: '10px', width: '7vw' }}
                    fontWeight='bold' fontSize='1rem'>{sliderValue[0]}-{sliderValue[1]} </Typography></StyledPaper>
            </Grid>

        </div >
    );
};

export default CustomSlider;



// <Grid item xs={5} sm={5} md={5} >
// <CustomSlider
//     // value={value4}
//     // onChange={handleChange4}
//     min={0}
//     max={5}
//     step={1}
// //marks={true}
// />
// </Grid>


