import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404 Not Found</h1>
      <p>This page does not exist.</p>
    </div>
  );
};

export default NotFound;




/*




import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404 Not Found</h1>
      <p>Oops! The page you're looking for does not exist.</p>
    </div>
  );
};

export default NotFound;





*/