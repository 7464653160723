import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import { useState, useEffect } from "react";

import FlightWine from './FlightWine'


const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "wrap",
            justifyContent: "center",
            padding: '0rem',
            //width: 'auto',
            backgroundColor: '#696969',//'gray',
            ///width: '85%',
            width: '100%',
            paddingBottom: '12px',
            //margin: '0rem',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "wrap",
            justifyContent: "flex-start",
            ////padding: '0.5rem',
            width: '100%',
            backgroundColor: 'black',
            padding: '3px',
            ///padding: '0px',
            //margin: '4px',
            marginBottom: '12px',
            borderRadius: '12px',
        }}
    >
        {props.children}
    </Box>
);

const StyledBox3 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "wrap",
            justifyContent: "center",
            ///padding: '0.5rem',
            width: '100%',
            padding: '5px',

        }}
    >
        {props.children}
    </Box>
);
const StyledBox4 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "center",
            ///padding: '0.5rem',
            width: '100%',
            padding: '5px',
            margin: '15px',
        }}
    >
        {props.children}
    </Box>
);

const FlightMenuItem = (props) => {


    // Extract data from props
    const data = props.item;
    const displayItems = data.wine_glasses
    /////console.log("======================================FLIGHT DATA: =================", data)
    return (
        <div style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <StyledBox2>
                <StyledBox>

                    <div style={{ width: '100%' , display: 'flex', flexDirection: 'row', justifyContent: 'space-around' , backgroundColor: 'silver' }}>
                        <div style={{ width: '75%' }}>
                            <StyledBox3>
                                <Typography align="center" color='black' fontSize='1.4rem' fontWeight='bold' >{data.flight_name}</Typography>
                            </StyledBox3>
                        </div>
                        <div style={{ width: 'auto' }}>
                            <StyledBox3>
                                <Typography align="center" color='black' fontSize='1.4rem' fontWeight='bold' >${data.wine_glasses[0].price_flight}</Typography>
                            </StyledBox3>
                        </div>


                    </div>


                    <StyledBox4>
                        {displayItems.map((item) => (
                            <FlightWine key={`flightMenuItem_${item.id}`} item={item}></FlightWine>
                        ))}
                    </StyledBox4>
                </StyledBox>
            </StyledBox2>

        </div >
    );
};

export default FlightMenuItem;

// color='rgb(171,18,57)'