import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button'

import MenuBar from "../../components/layout/MenuBar"
import Footer from "../../components/layout/Footer"
import FeedbackForm from './FeedbackForm'

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            width: '100%',
            backgroundColor: 'black',
            //height: '50rem',   //height: '69rem',
        }}
    >
        {props.children}
    </Box>
);

const StyledTypography = (props) => (
    <Box
        sx={{
            width: '100%',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem',
        }}>
        <Typography
            sx={{
                width: '80%',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {props.children}
        </Typography>
    </Box>
);



const FeedbackPage = (props) => {

    // Extract data from props
    const { data } = props;

    return (
        <div>
            <StyledBox>


                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MenuBar ></MenuBar>
                    </Grid>

                        <Box style={{ width: '80%', padding: '1rem', backgroundColor: 'black', display: 'flex', flexDirection: 'column' }}></Box>

                        <StyledTypography >
                            Thanks for trying out the Rouge Wine Selector!
                        </StyledTypography>

                        <StyledTypography >
                            This project is still under development and we're interested in any feedback you may have, positive or negative, about your experience using this application.
                        </StyledTypography>

                        <StyledTypography >
                            Please fill out the comment box below and click submit to provide your feedback. No name, email or other signup necessary.
                        </StyledTypography>

                        <StyledTypography >
                            Thanks!
                        </StyledTypography>

                        

                    

                </Grid>
            </StyledBox>



            <FeedbackForm></FeedbackForm>



            <Footer></Footer>
        </div >
    );
};

export default FeedbackPage;

