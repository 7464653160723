
import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import { useState, useEffect } from "react";

import { useRef } from 'react';
import testImg from "../../images/foodImg.jpg"

import CONFIG from '../../config/config.js'
const FOOD_IMAGE_SERVER_IP = CONFIG.FOOD_IMAGE_SERVER_IP
const FOOD_IMAGE_SERVER_URL = CONFIG.FOOD_IMAGE_SERVER_URL


const StyledBox = (props) => (
    <Box
        sx={{

            position: "absolute",
            padding: '0rem',
            backgroundColor: 'gray',
            width: '80%',
            height: '11rem',
            paddingBottom: '12px',
            backgroundImage: `url(${testImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backdropFilter: 'blur(1px)', // Adjust the blur amount as needed
            opacity: 0, // Adjust the opacity to control the intensity of the background
            minHeight: '10rem',
            animation: 'fade-in 0.3s forwards',
            animationDelay: '0s', // Adjust the delay as needed
        }}
    >
        {props.children}
    </Box>
);

const StyledBox2 = (props) => (
    <Box
        sx={{

            position: "relative",

            // display: 'flex',
            // flexDirection: 'row',
            // flexWrap: "wrap",
            // justifyContent: "flex-start",


            ////padding: '0.5rem',
            width: '100%',
            height: '12rem',
            backgroundColor: 'black',
            //padding: '3px',
            ///padding: '0px',
            //margin: '4px',
            marginBottom: '12px',
            borderRadius: '8px',
            //zIndex: -2,
            opacity: 1,

            animation: 'fade-in2 02s forwards',
            animationDelay: '0s', // Adjust the delay as needed

        }}
    >
        {props.children}
    </Box>
);

const StyledBox3 = (props) => (
    <Box
        sx={{
            //position: 'relative',

            display: 'flex',
            flexDirection: 'row',
            flexWrap: "wrap",
            justifyContent: "center",
            // ///padding: '0.5rem',
            width: '100%',
           // padding: '5px',
            // zIndex: 2,
            // filter: 'blur(0px)', // Adjust the blur amount as needed
            // opacity: 1, // Adjust the opacity to control the intensity of the background

        }}
    >
        {props.children}
    </Box>
);



const FoodItem = (props) => {


    //const { foodItem } = props;
    const { open } = props;
    
    const  foodItem = {dish_name: 'test', price:'00'}

    const { foodInfo } = props;

    let imageURL = FOOD_IMAGE_SERVER_IP + FOOD_IMAGE_SERVER_URL + foodInfo.image
    
    
    
    // let imageURL = 'http://192.168.3.18:6996/images/' + foodItem.images[0]
    // FOOD_IMAGE_SERVER_IP + FOOD_IMAGE_SERVER_URL
    // 'https://www.simpletech.dev/images/images/' + 



    const childRef = useRef(null);

    function handleParentClick() {
        // Call a function in the child component
        /// childRef.current.childFunction();
        console.log('handleclick')
      }


    return (
        <div >

            <style>

                {`
                
                @keyframes fade-in {
                    from {
                      opacity: 0;
                    }
                    to {
                      opacity: 0.4; // Adjust the final opacity to control the intensity of the background
                    }
                  }

                  @keyframes fade-in2 {
                    from {
                      opacity: 0;
                    }
                    to {
                      opacity: 1; // Adjust the final opacity to control the intensity of the background
                    }
                  }



                `}

            </style>

            <StyledBox2 >





                <Box
                onClick={handleParentClick}
                    sx={{
                        position: "absolute",
                        padding: '0rem',
                        backgroundColor: 'gray',
                        width: '98%',
                        height: '90%',
                        paddingBottom: '12px',
                        backgroundImage: `url(${imageURL})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backdropFilter: 'blur(1px)', // Adjust the blur amount as needed
                        opacity: 0, // Adjust the opacity to control the intensity of the background
                        minHeight: '10rem',
                        animation: 'fade-in 0.3s forwards',
                        animationDelay: '0s', // Adjust the delay as needed
                        borderRadius: '8px',
                    }}
                >
                </Box>





                

                <div style={{paddingTop: '0rem'}}>
                   
                   



                {/* <StyledBox3>
                    <Typography align="center" color='white' fontSize='1.4rem' fontWeight='bold' >{foodItem.dish_name}</Typography>
                </StyledBox3> */}


                {/* <StyledBox3>
                    <Typography align="center" color='white' fontSize='0.9rem' >{foodItem.short_description}</Typography>
                </StyledBox3> */}

                <StyledBox3>
                    <Typography align="center" color='white' fontSize='1.3rem' fontWeight='bold' >${foodInfo.price}.00</Typography>
                </StyledBox3>

                {/* <DetailsModal
                    style={{ color: 'blue' }}
                    render={true}
                    data={foodItem}
                    ref={childRef}
                >
                </DetailsModal> */}


                
                </div>


            </StyledBox2>

        </div >
    );
};

export default FoodItem;




/*








                <StyledBox>
                </StyledBox>






            <h1>{foodItem.dish_name}</h1>


                                    <div className="custom-text">
                            <h2>Text over Blurred Background</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>




                                            <StyledBox3>
                        <Typography align="center" color='white' fontSize='0.9rem' >{foodItem.short_description}</Typography>
                    </StyledBox3>

                    <StyledBox3>
                        <Typography align="center" color='white' fontSize='1.3rem' fontWeight='bold' >${foodItem.price}.00</Typography>
                    </StyledBox3>




                    <h2 style={{ position: 'relative', color: 'white' }}>Text over Blurred Background</h2>


*/

