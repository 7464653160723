import React, { useState } from "react";
import Modal from "react-modal";

import '../../../css/modalStyles.css'


////import bottle_image from '../../../images/bottles/dalla_valle.png'

import { LazyLoadImage } from "react-lazy-load-image-component";

import CONFIG from '../../../config/config.js'
import { Typography, Box } from "@mui/material";
const WINE_IMAGE_SERVER_IP = CONFIG.WINE_IMAGE_SERVER_IP
const WINE_IMAGE_SERVER_URL = CONFIG.WINE_IMAGE_SERVER_URL



import { forwardRef } from 'react';

import ModalComponentPrice from './ModalComponentPrice';



// import { useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';


///////////////////////////////////////////////////////////////////
// REFER TO THIS:
//  https://dev.to/devmdmamun/create-contextual-modal-navigation-with-react-router-v6-28k2
///////////////////////////////////////////////////////////////////






const ModalComponent = forwardRef((props, ref) => {

    const buttonOpacity = props.buttonOpacity
   //// console.log("buttonOpacity ", buttonOpacity)
    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }


    React.useImperativeHandle(ref, () => ({
        childFunction
    }));

    function childFunction() {
        // Function logic in the child component
       //// console.log("Child function called from parent");
        toggleModal()
    }





    ///console.log("MODAL PROPS: ", props.data)
    const wineDetails = props.data;
    ////console.log("MODAL PROPS wineDetails: ", wineDetails)
    ////console.log("MODAL PROPS2: ", wineDetails.wineName)
    //  { wineDetails.wineName }


    //const imgURL = "http://192.168.3.20:6996/images/" + wineDetails.wineID

    const imgURL = WINE_IMAGE_SERVER_IP + WINE_IMAGE_SERVER_URL + wineDetails.image
    ////console.log("imgURL ", imgURL)
    // const imgURL = "https://www.simpletech.dev/images/images/" + wineDetails.image




    let tastingNotesArray = []

    function parseString(inputString) {
        // Split the input string using comma as the delimiter
        // if (inputString){
        const parts = inputString.split(',');

        // Trim spaces at the beginning of each part
        const trimmedParts = parts.map(part => part.trim());

        // Return the resulting array
        return trimmedParts;
        // }
        // else{
        //     return ['none']
        // }
    }

    tastingNotesArray = parseString(wineDetails.varietal_descriptors);
    ////console.log("tastingNotesArray ", tastingNotesArray);


    // let light_bold_percent = (wineDetails.light_bold / 5) * 100
    // let smooth_tannic_percent = (wineDetails.smooth_tannic / 5) * 100
    // let dry_sweet_percent = (wineDetails.dry_sweet / 5) * 100
    // let soft_acidic_percent = (wineDetails.soft_acidic / 5) * 100

    // let scorePercentObject = {
    //     light_bold: light_bold_percent.toString() + '%',
    //     smooth_tannic: smooth_tannic_percent.toString() + '%',
    //     dry_sweet: dry_sweet_percent.toString() + '%',
    //     soft_acidic: soft_acidic_percent.toString() + '%',
    // }

    let light_bold_percent = (wineDetails.body_light_full / 5) * 100
    let smooth_tannic_percent = (wineDetails.tannin_low_high / 5) * 100
    let dry_sweet_percent = (wineDetails.dry_sweet / 5) * 100
    let soft_acidic_percent = (wineDetails.acid_low_high / 5) * 100

    let scorePercentObject = {
        body_light_full: light_bold_percent.toString() + '%',
        tannin_low_high: smooth_tannic_percent.toString() + '%',
        dry_sweet: dry_sweet_percent.toString() + '%',
        acid_low_high: soft_acidic_percent.toString() + '%',
    }









    // console.log("scorePercentObject",scorePercentObject)


    // {wineDetails.tasting_notes}
    // wineDetails.tasting_notes.map((item, index) => (

    // ))

    // example array mapping
    // {renderDisplayItems.map((item, index) => (
    //     <ResultItem
    //         key={`displayItem_${index}`}
    //         id={index + 1}
    //         data={item}
    //     ></ResultItem>
    // ))}








    // const location = useLocation();s
    // const navigate = useNavigate();

    // useEffect(() => {
    //     const handleLocationChange = () => {
    //         toggleModal(); // Function to close the modal by setting showModal to false
    //     };

    //     const unblock = navigate(handleLocationChange);

    //     return () => {
    //         unblock(); // Unsubscribe the listener when the component unmounts
    //     };
    // }, [location, navigate]);



    const boolTrue = true

    if (props.render == true) {

        return (

            <div className="modalApp" style={{ width: '100%', height: '2rem', opacity: '100%', }}>








                <style>{`
        .graph-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .graph-row {
          display: flex;
          align-items: center;
          margin-bottom: 0px;
          margin-top: 0px;
          margin-right: 0px;
          margin-left: 0px;
        }

        .graph-bar {
          width: 8rem;
          height: 8px;
          background-color: gray;
          border-radius: 3px;
          margin-left: 0px;
        }

        .graph-bar::before {
          content: "";
          display: block;
          height: 100%;
          background-color: maroon;
          border-radius: 3px;
          width: var(--progress);
          transition: width 0.3s ease-in-out;
        }
      `}</style>














                {/* color: 'maroon',
                fontSize: '1rem',
                fontWeight: 'bold',
                width: '60%',
                padding: '0.3rem',
                opacity: '0%', */}






                <button style={{
                    color: 'maroon',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    position: 'relative',
                    width: '100%',
                    height:'100%',
                    padding: '0rem',
                    opacity: buttonOpacity,

                }} onClick={toggleModal}>VIEW DETAILS</button>

                <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModal}
                    contentLabel="My dialog"
                    className="mymodal"
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                >
                    <div style={{
                        //margin: '0rem', padding: '3rem',
                        width: '18rem',
                        height: '38rem',
                        padding: '1rem',
                        margin: '0rem',
                        border: '0rem',
                        ////backgroundImage: `url(${importedImage7})`,

                        //backgroundColor: 'silver',
                        backgroundColor: 'rgb(70,50,50)',
                        color: 'gray',
                        //// backgroundRepeat: 'no-repeat',
                        //// backgroundSize: 'cover',
                    }}>










                        <div style={{
                            textAlign: 'center',
                            padding: '0rem',
                        }}>
                            <Typography

                                noWrap={boolTrue}

                                sx={{
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                    color: 'black',
                                    paddingBottom: '0.5rem',
                                    paddingTop: '0rem',
                                    paddingLeft: '0rem',
                                    paddingRight: '0rem',


                                }}
                            >{wineDetails.name}</Typography>
                        </div>



                        <div
                            style={{
                                //height: '100%',
                                //minHeight: '1300px',
                                //width: '100%',//'60rem',
                                margin: '0px',
                            }}
                        >



                            {/* <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold', }}>TYPE: {wineDetails.type}</Typography>
                            <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold', }}>GRAPE: {wineDetails.grape}</Typography>
                            <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold', }}>LOCATION: {wineDetails.region}</Typography>
                            <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold', }}>REGION: {wineDetails.subregion} </Typography> */}



                            {/*                             
                            <p><span style={{ fontWeight: 'bold', }}>TYPE:</span> {wineDetails.type}</p>
                            <p><span style={{ fontWeight: 'bold', }}>GRAPE:</span> {wineDetails.grape}</p>
                            <p><span style={{ fontWeight: 'bold', }}>LOCATION:</span> {wineDetails.subregion} {wineDetails.region}</p>
 */}

                        </div>





                        <div
                            style={{
                                display: "flex",
                                flexDirection: 'row',
                                height: '27rem',
                                paddingBottom: '1rem',
                                paddingTop: '0rem',
                                paddingLeft: '0rem',
                                paddingRight: '0rem',
                            }}>


                            <div
                                style={{
                                    padding: '0rem',
                                    width: '50%'
                                }}>


                                {/* 
                                <LazyLoadImage
                                    src={imgURL}
                                    align="center"
                                    // width={112}
                                    // height={200}
                                    style={{
                                        paddingTop: '0rem',
                                        paddingLeft: '0rem',
                                        paddingRight: '1rem',
                                        paddingBottom: '0rem',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                    alt="Image Alt"
                                />
 */}


                                <Box
                                    sx={{
                                        position: "absolute",
                                        padding: '0rem',
                                        backgroundColor: 'gray',
                                        width: '9rem',
                                        height: '27rem',
                                        //paddingBottom: '12px',
                                        backgroundImage: `url(${imgURL})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backdropFilter: 'blur(1px)', // Adjust the blur amount as needed
                                        opacity: 1, // Adjust the opacity to control the intensity of the background
                                        minHeight: '10rem',
                                        animation: 'fade-in 0.3s forwards',
                                        animationDelay: '0s', // Adjust the delay as needed

                                        paddingBottom: '1rem',
                                        paddingTop: '0rem',
                                        paddingLeft: '0rem',
                                        paddingRight: '0rem',


                                    }}
                                >
                                </Box>



                            </div>


                            <div
                                style={{
                                    height: '100%',
                                    //minHeight: '1300px',
                                    width: '45%',//'60rem',
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-evenly",


                                    paddingBottom: '0rem',
                                    paddingTop: '0rem',
                                    paddingLeft: '1rem',
                                    paddingRight: '0rem',
                                }}
                            >

                                {/* <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                > */}


                                <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', }}>TYPE: </Typography>
                                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', color: 'black' }}> {wineDetails.type}</Typography>


                                {/* </div> */}


                                {/* <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                > */}


                                <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', }}>REGION:</Typography>
                                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', color: 'black' }}>{wineDetails.country_or_domestic_state},</Typography>

                                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', color: 'black' }}>{wineDetails.region}</Typography>


                                {/* </div> */}


                                {/* 
                                <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', }}>LOCATION:</Typography>
                                 */}

                               



                                <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', }}>GRAPE:</Typography>
                                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', color: 'black', paddingBottom: '1rem', }}>{wineDetails.grape}</Typography>














                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', }}>LIGHT / FULL BODY </Typography>
                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: 'black', }}>{wineDetails.body_light_full}</Typography>
                                </div>
                                <div className="graph-container">
                                    <div className="graph-row">
                                        <div className="graph-bar" style={{ '--progress': scorePercentObject.body_light_full }}></div>
                                    </div>
                                </div>




                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', }}>TANNIN</Typography>
                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: 'black', }}>{wineDetails.tannin_low_high}</Typography>
                                </div>
                                <div className="graph-container">
                                    <div className="graph-row">
                                        <div className="graph-bar" style={{ '--progress': scorePercentObject.tannin_low_high }}></div>
                                    </div>
                                </div>


                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', }}>DRY / SWEET</Typography>
                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: 'black', }}>{wineDetails.dry_sweet}</Typography>
                                </div>
                                <div className="graph-container">
                                    <div className="graph-row">
                                        <div className="graph-bar" style={{ '--progress': scorePercentObject.dry_sweet }}></div>
                                    </div>
                                </div>


                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', }}>ACIDITY</Typography>
                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: 'black', }}>{wineDetails.acid_low_high}</Typography>
                                </div>
                                <div className="graph-container">
                                    <div className="graph-row">
                                        <div className="graph-bar" style={{ '--progress': scorePercentObject.acid_low_high }}></div>
                                    </div>
                                </div>




 










                                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', paddingTop: '1rem', }}>FLAVOR NOTES:</Typography>






                                {tastingNotesArray.map((item, index) => (

                                    <Typography

                                        sx={{
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            color: 'black',
                                            padding: '0px',
                                            margin: '0px',
                                        }}

                                        key={`tasting_note-${index}`}

                                    >
                                        {item}
                                    </Typography>

                                ))}

                                {/* 
                                <p><span style={{ fontWeight: 'bold', }}>light/bold:</span>  {wineDetails.tasting_notes}  </p>
                                <p><span style={{ fontWeight: 'bold', }}>smooth/tannic:</span>  {wineDetails.tasting_notes}  </p>
                                <p><span style={{ fontWeight: 'bold', }}>dry/sweet:</span>  {wineDetails.tasting_notes}  </p>
                                <p><span style={{ fontWeight: 'bold', }}>soft/acidic:</span>  {wineDetails.tasting_notes}  </p>
 */}

                            </div>

                        </div>

                        {/* 
                        <p><span style={{ fontWeight: 'bold', }}>NOTES:</span>  {wineDetails.tasting_notes}  </p>
                        <p><span style={{ fontWeight: 'bold', }}>DESCRIPTION:</span> {wineDetails.info} </p>
                        <p><span style={{ fontWeight: 'bold', }}>PAIRS WITH:</span>  {wineDetails.pairs} </p>
                        <p><span style={{ fontWeight: 'bold', }}>PRICE:</span> {wineDetails.price_bottle}</p>
 */}


                        {/* <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', }}>DESCRIPTION: {wineDetails.info}</Typography>
                        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', }}>PAIRS WITH: {wineDetails.pairs}</Typography> */}

                        
                        {/* <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', }}>GLASS 5 OZ: ${wineDetails.price_glass_5_oz}</Typography>
                        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', }}>GLASS 8 OZ: ${wineDetails.price_glass_8_oz}</Typography>
                        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', }}>BOTTLE: ${wineDetails.price_bottle}</Typography> */}


                        <ModalComponentPrice wineDetails={wineDetails}></ModalComponentPrice>








                        {/* 

                        <div className="graph-container">
                            <div className="graph-row">
                                <h3>Parameter 1</h3>
                                <div className="graph-bar" style={{ '--progress': '80%' }}></div>
                            </div>
                            <div className="graph-row">
                                <h3>Parameter 2</h3>
                                <div className="graph-bar" style={{ '--progress': '50%' }}></div>
                            </div>
                            <div className="graph-row">
                                <h3>Parameter 3</h3>
                                <div className="graph-bar" style={{ '--progress': '20%' }}></div>
                            </div>

                        </div>
 */}


                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>

                            <button onClick={toggleModal} style={{
                                opacity: '70%',
                                backgroundColor: 'black',
                                color: 'maroon', //'rgb(171,18,57)', //'maroon',
                                fontSize: '1rem',
                                padding: '0.5rem',
                                padding: '1rem',
                                margin: '0rem',
                                border: '0rem',
                                fontWeight: 'bold',
                                width: '100%',
                            }}
                            >CLOSE DETAILS</button>
                        </div>



                    </div>

                </Modal>
            </div>

        )


    } else {
        return (
            <div></div>
        )
    }

})

export default ModalComponent;

