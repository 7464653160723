
import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";

// import image1 from "./image1.jpg"
// import image2 from "./image2.jpg"


import CONFIG from '../../config/config.js'
const FOOD_IMAGE_SERVER_IP = CONFIG.FOOD_IMAGE_SERVER_IP
const FOOD_IMAGE_SERVER_URL = CONFIG.FOOD_IMAGE_SERVER_URL


const ImageScroll = (props) => {


    // const images = [
    //     { id: 1, image: image2 },
    //     { id: 2, image: image1 },
    // ];

    // Extract data from props
    const imageData = props.images;

    let elementID = -1
    const images = imageData.map(element => {
        elementID = elementID + 1
        return {
            id: elementID,
            image: FOOD_IMAGE_SERVER_IP + FOOD_IMAGE_SERVER_URL + element,

            // image: 'http://192.168.3.18:6996/images/' + element,
        
        };
    });
    ////console.log("resultArray    ----     ", images)
    //const bookPages = resultArray

    // if (images.length > 1) {
    //     images.shift()
    // }

    const [index, setIndex] = useState(0);

    const transition = useTransition(images[index], {
        key: images[index].id, // Add a unique key
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 700 },
    });





    // let timerLength = 1000
    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setIndex((prevIndex) => (prevIndex + 1) % images.length);

    //     }, timerLength);

    //     return () => clearInterval(timer);
    // }, [images.length]);




    // const [timerLength, setTimerLength] = useState(2000);
    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setIndex((prevIndex) => (prevIndex + 1) % images.length);

    //     }, timerLength);

    //     return () => clearInterval(timer);
    // }, [images.length]);

    // setTimeout(() => {
    //     setTimerLength(4000)
    // }, 3000); 






    //const [timerLength, setTimerLength] = useState(1500);
    const [timerLength, setTimerLength] = useState(2000);

    useEffect(() => {
      const timer = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, timerLength);
    
      return () => clearInterval(timer);
    }, [images.length, timerLength]);
    



    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     setTimerLength(3000);
    //   }, 3000);
    
    //   return () => clearTimeout(timeout);
    // }, []);









    // let timerLength = 1000;
    // const [prevIndex, setPrevIndex] = useState(0);

    // useEffect(() => {
    //   const timer = setInterval(() => {
    //     setIndex((prevIndex) => {
    //       setPrevIndex(prevIndex); // Store the previous index
    //       return (prevIndex + 1) % images.length;
    //     });

    //     if (timerLength === 1000 && prevIndex === 2) {
    //       setTimeout(() => {
    //         timerLength = 2000;
    //       }, 5000); // Change the duration as needed
    //     }
    //   }, timerLength);

    //   return () => clearInterval(timer);
    // }, [images.length, prevIndex]);










    return (
        <div style={{
            //height: '10rem', 
            width: '17rem',
            //overflow: "hidden",
        }}>



            {transition((style, item) => (
                <animated.div
                    key={item.id}
                    style={{
                        ...style,
                        position: "absolute",
                        top: '6rem',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: "cover",
                        //position: "relative",
                        backgroundPosition: "center center",
                        height: "10rem", /* Adjust height as needed */
                    }}
                />
            ))}


        </div>
    );
};

export default ImageScroll;



































// {
//     transition((style, item) => (
//         <animated.div
//             key={item.id}
//             style={{
//                 backgroundImage: `url(${item.image})`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center center",
//                 height: "200px", /* Adjust height as needed */
//             }}
//         />
//     ))
// }





            // backgroundSize: "cover",
            // display: 'flex',
            // backgroundImage: `url(${images[0].image})`,































// import React, { useState, useEffect } from "react";
// import { useTransition, useSpring, animated } from "react-spring";
// import { useDrag } from "react-use-gesture";
// import '../../images/book/app2.css'
// import '../../images/book/modalStyles.css'




/*

import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";

const ImageScroll = (props) => {



    // Extract data from props
    const { images } = props;

    let elementID=-1
    const resultArray = images.map(element => {
        elementID = elementID + 1
        return {
            id: elementID,
            image: 'http://192.168.3.18:6996/images/' + element,
        };
    });
    console.log("resultArray    ----     ", resultArray)
    const bookPages = resultArray





  const [index, setIndex] = useState(0);

  // Set up the transition for animating the slider
  const transition = useTransition(resultArray[index], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
  });

  // Automatically scroll through images
  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % resultArray.length);
    }, 1000);

    return () => clearInterval(timer);
  }, [resultArray.length]);

  return (
    <div >
      {transition((style, item) => (
        <animated.div
          key={item.id}
          style={{
            ...style,
            backgroundImage: `url(${item.image})`,
          }}
        />
      ))}
    </div>
  );
};

export default ImageScroll;






*/


































/*

const ImageScroll = (props) => {

    // Extract data from props
    const { images } = props;

    let elementID=-1
    const resultArray = images.map(element => {
        elementID = elementID + 1
        return {
            id: elementID,
            image: 'http://192.168.3.18:6996/images/' + element,
        };
    });
    console.log("resultArray    ----     ", resultArray)
    const bookPages = resultArray




















    const [seconds, setSeconds] = useState(0);


    // function toggle() {
    //     setIsActive(!isActive);
    // }

    // function reset() {
    //     setSeconds(0);
    //     setIsActive(false);
    // }


    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        console.log("useEffect")
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 700);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => {
            console.log("interval")
            autoAdvance()
            clearInterval(interval)
        }
    }, [isActive, seconds]);





























    function autoAdvance() {

        console.log("autoAdvance")
        // setIsVisible(v => !v)
        // set({ x: 0, y: 0 })

        // setValue({
        //     disabled: true,
        // });


        // setPageDirection({
        //     direction: transitionVars.pageBack,
        // });


        if (page.currentPageId > 0) {

            console.log("length ", page.currentPageId, bookPages.length)

            setPage({
                currentPageId: page.currentPageId - 1,
                previousPageId: page.currentPageId,
            });


            //let newSlice = bookPages.slice(page.currentPageId - 1)
            //   newSlice.map((entry)=>{
            //       console.log('entry ', entry)
            //   }) 


            // for (let x = 0; x < newSlice.length; x++) {
            //     newSlice[x].pr = String(3 + (x + 1) * .4) + 'rem'

            // }
            // console.log('newSlice ', newSlice)


            // setPagesRemaining({
            //     pageArray: newSlice, //bookPages.slice(page.currentPageId)
            // })

            console.log("if newslice ")

        } else {

            setPage({
                currentPageId: bookPages.length - 1,
                previousPageId: page.currentPageId,
            });


            //let newSlice = bookPages.slice(bookPages.length)
            // for (let x = 0; x < newSlice.length; x++) {
            //     newSlice[x].pr = String(3 + (x + 1) * .4) + 'rem'
            // }
            // console.log('newSlice ', newSlice)

            // setPagesRemaining({
            //     pageArray: newSlice, //bookPages.slice(page.currentPageId)
            // })

            console.log("else newslice ")

        }

        // setTimeout(() => {
        //     setValue({
        //         disabled: false,
        //     });
        // }, 300)


    }































































    const [{ x, y }, set] = useSpring(() => ({ x: 0, y: 0 }));


    const [value, setValue] = useState({

        disabled: false,
        currentPageId: 0,

    });

    const [page, setPage] = useState({

        currentPageId: 0,
        previousPageId: 0,

    });



    console.log("=================value ", value)



    let firstSlice = bookPages.slice(1)
    // for (let x = 0; x < firstSlice.length; x++) {
    //     firstSlice[x].pr = String(3 + (x + 1) * .4) + 'rem'
    // }
    // console.log('firstSlice ', firstSlice)

    const [pagesRemaining, setPagesRemaining] = useState({

        pageArray: firstSlice,
    });
























    const transitionVars = {
        pageForward: {
            from: {
                x: '30%',
                scale: '1',
                rotateY: '0deg',
            },
            leave: {
                x: "0%",
                scale: '1',
                rotateY: '0deg',
            }
        },
        pageBack: {
            from: {
                x: "-30%",
                scale: '1',
                rotateY: '0deg',
            },
            leave: {
                x: '0%',
                scale: '1',
                rotateY: '0deg',
            }
        }
    }


    const [pageDirection, setPageDirection] = useState({

        direction: transitionVars.pageForward,

    });

















    const bind = useDrag(({ down, movement: [mx, my] }) => {
        //set({ x: down ? mx : 0, y: down ? my : 0 })
        //console.log("x", x.animation.fromValues[0]);
        console.log("mx", mx);
        //if (x.animation.fromValues[0] < -20) {




        if (value.disabled == false) {

            if (mx < -50) {
                console.log("flip")
                setIsVisible(v => !v)
                set({ x: 0, y: 0 })
                //console.log("x", x.animation.fromValues[0]);

                setValue({
                    disabled: true,
                });




                setPageDirection({
                    direction: transitionVars.pageForward,
                });


            }


        }


    });













    const [isVisible, setIsVisible] = useState(false)





















    const transition = useTransition(isVisible, {
        // from: {
        //     opacity: "0",
        //     //rotateX: '100deg',

        //     //rotateY:"-90deg",
        //     position: "absolute",
        //     config: { duration: "2000" },
        //     zIndex: bookPages[page.currentPageId].z2,

        //     //x: "0%",

        //     x: pageDirection.direction.from.x,//"-50%" ,//"-50%",
        //     scale: pageDirection.direction.from.scale, //'1.1',//'1.1',
        //     rotateY: pageDirection.direction.from.rotateY, //'-90deg',    //"-90deg",


        // },
        enter: {
            opacity: "0.5",
            //rotateX: "0deg",


            rotateX: "0deg",
            position: "absolute",
            config: { duration: "100" },
            zIndex: bookPages[page.currentPageId].z2,

            scale: '1',
            x: "90%",
            rotateY: "0deg",



        },
        leave: {
            opacity: "0.5",
            //rotateX: "-100deg",

            //rotateX:"-2deg",
            position: "absolute",
            config: { duration: "100" },
            zIndex: bookPages[page.currentPageId].z2,

            // x:"0%" ,//"-50%",
            // //y: "5%",
            // scale: '1.0',//'1.1',
            // rotateY: '0deg',    //"-90deg",


            // x:"-50%" ,//"-50%",
            // scale: '1.1',//'1.1',
            // rotateY: '-90deg',    //"-90deg",

            x: pageDirection.direction.leave.x,//"-50%" ,//"-50%",
            scale: pageDirection.direction.leave.scale, //'1.1',//'1.1',
            rotateY: pageDirection.direction.leave.rotateY, //'-90deg',    //"-90deg",
        },
        immediate: key => key === "zIndex"
    })



































    // return (
    //     <div>

    //     <h1 style={{color: 'black' , backgroundColor: 'gray'}}>{images[0]}</h1>

    //     </div >
    // );



    return (

        <div style={{ width: '100%', height: '10rem', position: 'relative' }} >



<style>

{`

@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1; // Adjust the final opacity to control the intensity of the background
    }
  }

  @keyframes fade-in2 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1; // Adjust the final opacity to control the intensity of the background
    }
  }



`}

</style>




            <div className="container"
                style={{
                    //backgroundImage: `url(${importedImage2})`,
                    //backgroundColor: 'linen',// originally 'indigo', rosybrown color for background behind book
                    backgroundRepeat: 'repeat',
                    backgroundSize: 'contain',
                    height: 'auto',
                    //minHeight: '1300px',
                    width: '100%',//'60rem',
                }}>
                {transition((style, item, t, index) =>
                    // item ?
                    <div style={{
                        position: 'relative',
                        // left:"3.2rem", these work for page size 0.2rem
                        // top: "1.8rem",
                        left: "0rem",
                        top: "0rem",
                        //backgroundColor: 'gray',
                        padding: '0rem',
                    }}>

                        <animated.div
                            {...bind()}
                            className="Square"
                            style={{ x, y, touchAction: "none" }}
                        >

                            <div style={{
                                //  display: 'flex', 
                                //  flexWrap: 'nowrap',  
                                //  justifyContent: 'flex-start', 
                                //width: '30rem',
                                //margin: '0px',
                                backgroundImage: `url(${bookPages[page.currentPageId].image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                width: '18rem',
                                height: '15rem',
                                borderRadius: '0px',
                                padding: '0rem',
                                margin: '0rem',
                                //borderRight: '5px solid gray',
                                //borderRight: '12px solid gray',
                                //flexDirection: 'column',
                                //justifyContent: 'center',
                                zIndex: bookPages[page.currentPageId].z2,
                                //   position: 'relative',
                                //   left: bookPages[page.currentPageId].pr,
                                //   top: bookPages[page.currentPageId].prt,
                                // position: 'absolute',
                                //   left:"3rem",
                                //   top: "3rem",
                                
                                // animation: 'fade-in 0.3s forwards',
                                // animationDelay: '0s', // Adjust the delay as needed
                            }}
                            >

                            </div>
                        </animated.div>


                    </div>
                )}

            </div>
        </div>
    );




};

export default ImageScroll;

*/