import React from 'react';
import { connect } from 'react-redux';

import Menubar from "../components/layout/MenuBar"

import { styled } from '@mui/system';

import { Grid, Box, Paper, Card, CardMedia } from '@mui/material';

// import shareablesImg from '../images/shareables.webp'
// import entreesImg from '../images/entrees.webp'
// import pizzasImg from '../images/pizzas.webp'
// import dessertsImg from '../images/desserts.webp'

// import ModalComponent from './ModalComponent'
// import LazyPic from './LazyPic'

const StyledBox = (props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      width: '100%',

      backgroundColor: 'black',
      height: '69rem',
    }}
  >
    {props.children}
  </Box>
);


const StyledPaper = (props) => (
  <Paper
    sx={{
      background: 'black',
      color: 'white'
    }}
  >
    {props.children}
  </Paper>
);

const ImageStyledPaper = (props) => (
  <Paper
    sx={{
      margin: '1rem',
      backgroundColor: 'black',
      repeat: 'no-repeat',
      backgroundSize: 'cover',
      width: "15rem",
      height: "6rem"
    }}
  >
    {props.children}
  </Paper>
);

const StyledBox2 = (props) => (
  <Box
    sx={{
      margin: '1rem',
      backgroundColor: 'black',
    }}
  >
    {props.children}
  </Box>
);



const spacing = '8rem'

const Selector = ({ data }) => {
  return (
    <div >
    <StyledBox>


      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Menubar ></Menubar>
        </Grid>

        {/* <ModalComponent style={{ paddingLeft: '0rem', color: 'blue' }} render={true} />
        <LazyPic></LazyPic> */}
        
      </Grid>
    </StyledBox>
  </div >
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data,
  };
};

export default connect(mapStateToProps)(Selector);


/*

    <div>
      <Grid container justify="center" spacing={spacing}>

      <Grid item xs={6} sm={3}>
        <Box><Menubar ></Menubar></Box>
          
        </Grid>
        <Grid item xs={6} sm={3}>
        <Paper>hello</Paper>
          
        </Grid>

      </Grid>
    </div>





<div >
<Grid container spacing={3}>
  <Grid item xs={12}>
    <Paper >xs=12</Paper>
  </Grid>
  <Grid item xs={12} sm={6}>
    <Paper >xs=12 sm=6</Paper>
  </Grid>
  <Grid item xs={12} sm={6}>
    <Paper >xs=12 sm=6</Paper>
  </Grid>
  <Grid item xs={6} sm={3}>
    <Paper >xs=6 sm=3</Paper>
  </Grid>
  <Grid item xs={6} sm={3}>
    <Paper >xs=6 sm=3</Paper>
  </Grid>

</Grid>
</div>















  return (
    <div >
      <StyledBox>


        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Menubar ></Menubar>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >

            <Paper
              style={{
                margin: '1rem',
                backgroundColor: 'black',
                backgroundImage: `url(${shareablesImg})`,
                repeat: 'no-repeat',
                backgroundSize: 'cover',
                width: "90%",
                height: "10rem"
              }} square>
            </Paper>

            <StyledPaper >SHAREABLES</StyledPaper>

          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${entreesImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <StyledPaper >ENTREES</StyledPaper>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${pizzasImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <StyledPaper >PIZZAS</StyledPaper>
          </Grid>

          <Grid item xs={12} sm={8} md={8} >
            <Card>
              <Paper
                style={{
                  margin: '0rem',
                  padding: '10%',
                  border: '0rem',
                  backgroundColor: 'black',
                  backgroundImage: `url(${dessertsImg})`,
                  repeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: "80%",
                  height: "auto",
                }} square
              />
            </Card>
            <StyledPaper >DESSERTS</StyledPaper>
          </Grid>

        </Grid>
      </StyledBox>
    </div >
  );













*/
