
export const UpdateWineList = (argument) => ({
  type: 'UPDATE_WINE_LIST',
  payload: {
    display: argument.display,
    sort: argument.sort
  },
});



// import { fetchItems } from '../api/MenuData';

// export const UpdateWineList = (argument) => async (dispatch) => {
//   try {

//     const fetchedItems = await fetchItems(argument);
//     // Call the fetchItems function

//     console.log("UPDATE_WINE: ", argument, " ", fetchedItems)

//     const payloadObject = {
//       display: argument,
//       items: fetchedItems,
//     }

//     dispatch(fetchItemsSuccess(payloadObject));
//   } catch (error) {
//     // Handle any error if necessary
//   }
// };

// const fetchItemsSuccess = (payloadObject) => ({
//   type: 'UPDATE_WINE_LIST',
//   payload: payloadObject,
// });
























// export const QueryWineDataSuccess = (query) => async (dispatch) => {

//   console.log("--------------------------------------------------ATTEMPTING TO UPDATE_WINE_SEARCH_RESULTS 1")
//   try {

//     dispatch(QueryWineDataSuccess2(query));


//     let dispatchPayload = {
//       sortBy: 'type',
//       activeMenu: 'glasses',
//       toggleData: ['bubbles', true, [0,1]],
//     }

//     let multiDispatchObject = {
//       searchPanelFields: dispatchPayload,
//       searchResults: "dataToSearch",
//     }

//     console.log("--------------------------------------------------ATTEMPTING TO UPDATE_WINE_SEARCH_RESULTS")
//     dispatch(UpdateWineSearchResults(multiDispatchObject));

//   } catch (error) {
//     console.log("--------------------------------------------------FAILED TO UPDATE_WINE_SEARCH_RESULTS")
//   }
// };

// const QueryWineDataSuccess2 = (query) => ({
//   type: 'QUERY_WINE_DATA_SUCCESS',
//   payload: query,
// });

// const UpdateWineSearchResults = (payloadObject) => ({
//   type: 'UPDATE_WINE_SEARCH_RESULTS',
//   payload: payloadObject,
// });