

import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';




import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";

import Add from
    "@mui/icons-material/Add";
import Remove from
    "@mui/icons-material/Remove";

import IconButton from "@mui/material/IconButton";


import FoodItem from "./FoodItem"




const CategoryHeading = (props) => {

    const [open2, setOpen2] = useState(false);

    // Extract data from props
    // const { data } = props;
    const { item } = props;
    const { index } = props;

   ///// console.log("CategoryHeading item ", index, item)

    return (

        <div>

                <style>

                {`




.fade {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  .visible {
    opacity: 1;
  }
  
  .hidden {
    opacity: 0;
  }






                `}

                </style>


            <Card sx={{
                minWidth: 300,
                border: "6px solid black", //"4px solid rgba(211,211,211,0.6)",

                backgroundColor: 'gray',
                borderRadius: '0px',
                opacity: '100%',
                margin: '0.2rem',
                borderRadius: '0.7rem',
            }}>
                <CardHeader

                    onClick={() => setOpen2(!open2)}
                    aria-label="expand"
                    size="small"

                    title={item[0].category_name}
                    action={
                        <IconButton
                            onClick={() => setOpen2(!open2)}
                            aria-label="expand"
                            size="small"
                        ///// backgroundColor= "rgba(211,211,211,0.4)"
                        >
                            {open2 ? <Remove />
                                : <Add />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                    ///// backgroundColor: "rgba(211,211,211,0.4)"
                }}>


                    <Collapse in={open2} timeout="auto"
                        unmountOnExit>
                        <CardContent>
                            <Container sx={{
                                height: 'auto',
                                lineHeight: 2,
                                width: '100%',
                                ////backgroundColor: 'yellow',

                            }}>



                                {/* {open2 && (
                                    <> */}


                                        {item.map((foodItem) => (
                                            <div  className={`fade ${open2 ? 'visible' : 'hidden'}`} key={foodItem.dish_name}>
                                                <FoodItem foodItem={foodItem} open={open2}  />
                                            </div>
                                        ))}


                                    {/* </>
                                )} */}

                                {/* {!open2 && (
                                    <>
                                        {item.map((foodItem) => (
                                            <div key={foodItem.dish_name} style={{height: '2rem'}}>
                                                
                                            </div>
                                        ))}
                                    </>
                                )} */}





                            </Container>
                        </CardContent>
                    </Collapse>


                </div>
            </Card>



        </div >

    );
};

export default CategoryHeading;





//  <h1 style={{ color: 'white' }}  >item</h1>

/*



.fade {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  .visible {
    opacity: 1;
  }
  
  .hidden {
    opacity: 0;
  }




  .fade {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
  }
  
  .visible {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
  
  .hidden {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s;
  }



                                {item.map((foodItem) => (
                                    <div key={foodItem.dish_name}>
                                        <FoodItem foodItem={foodItem} open={open2} ></FoodItem>
                                    </div>

                                ))}











        <h1>{foodItem.dish_name}</h1>

        <h1 style={{ color: 'white' }}  >{item[0].category_name}</h1>

        <h1 style={{ color: 'white' }}  >{item[0].category_name}</h1>


        <div>
            <h1 style={{ color: 'white' }}  >{item[0].category_name}</h1>
        </div >


                                {displayItems.map((item) => (
                                    <MenuDisplay key={item.id} item={item}></MenuDisplay>
                                ))}


*/
