

import CONFIG from '../../config/config.js'
const WINE_DATA_SERVER_IP = CONFIG.WINE_DATA_SERVER_IP
const WINE_DATA_SERVER_URL = CONFIG.WINE_DATA_SERVER_URL

const GLOBAL_OPACITY = CONFIG.GLOBAL_OPACITY



import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';
import MenuBar from "../../components/layout/MenuBar"

import Footer from "../../components/layout/Footer"
////import MenuDisplay from './MenuDisplay'

import { UpdateMenu } from "../../actions/UpdateMenu"
import { useDispatch } from 'react-redux';

import { connect } from 'react-redux';
import { useState, useEffect, useRef } from "react";

import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider, } from '@mui/material';

import wineImg from '../../images/wineImg.jpg'


import { UpdateWineList } from "../../actions/UpdateWineList"
import QueryWineDataSuccess from "../../actions/QueryWineDataSuccess"
import { UpdateSearchPanelFields } from "../../actions/UpdateSearchPanelFields"
import { InitializeWineSearchResults } from "../../actions/InitializeWineSearchResults"




import RenderSelector from './RenderSelector'

import { Route, useLocation } from 'react-router-dom';

import SortByPanel from './SortByPanel'
import SearchPanel from './SearchPanel'


import { GenerateCurrentResults } from "../../actions/GenerateCurrentResults"


import { ToggleAll } from "../../actions/ToggleAll"


import background_image from './background_wine_glass.jpg'

import { useTransition, animated } from "react-spring";

import ResultsBanner from './common/ResultsBanner'
import FadingComponent from './common/FadingComponent'







const devModeBool = false








const DivWrapper = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: '1rem',
            width: 'auto',
            height: 'auto',
            minHeight: '120vh',
            backgroundColor: 'black',
            backgroundImage: `url(${background_image})`,
            backgroundAttachment: 'fixed',
            repeat: 'no-repeat',
            backdropFilter: 'blur(0)', // or backdropFilter: 'none'
            padding: '0px',
            margin: '0px',
            border: '0px',
        }}
    >
        {props.children}
    </Box>
);




// const DivWrapper = (props) => (
//     <Box
//         sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             flexWrap: "wrap",
//             justifyContent: "space-between",
//             gap: '1rem',
//             width: '100%',

//             height: 'auto',
//             minHeight: '120vh',

//             //minHeight: '-webkit-fill-available',
//             //opacity: '95%',
//             backgroundColor: 'black',

//             backgroundImage: `url(${background_image})`,
//             backgroundAttachment: 'fixed',
//             repeat: 'no-repeat',
//             //backgroundSize: 'cover',

//             // height: 'auto', // '69rem',
//             // display: 'flex',
//             // flexDirection: 'column',
//             // gap: '2rem',
//             // width: '100%',
//             // backgroundColor: 'black',

//         }}
//     >
//         {props.children}
//     </Box>
// );




const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-around",
            width: '100%',
            backgroundColor: 'black',
            opacity: GLOBAL_OPACITY,
        }}
    >
        {props.children}
    </Box>
);


// sx={{ color:'#420420' }}
const StyledButton = (props) => (
    <Button
        sx={{
            fontSize: '1.0rem',
            fullWidth: "true",
            size: "large",
            padding: '5px',
            color: '#420420',
        }}
    >
        {props.children}
    </Button>
);

const StyledTypography = (props) => (
    <Typography
        sx={{
            fontSize: '1.4rem',
            fontWeight: 'bold',
            padding: '3px',
            margin: '5px',
            backgroundColor: 'silver',
            opacity: '50%',
            width: 'auto',
        }}
    >
        {props.children}
    </Typography>
);








const StyledBox2 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '1rem',
            width: '100%',
            backgroundColor: 'black',
            justifyContent: 'center',
            //height: '50rem',   //height: '69rem',
        }}
    >
        {props.children}
    </Box>
);












const WineList = (props) => {






    const theme = createTheme({
        palette: {
            primary: {
                main: 'rgb(150, 18, 57)',
            },
            error: {
                main: '#0044ff',
            },
        },

    });












    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {


            ////console.log("FETCH_FUNCTION")

            let fetchEndpointURL = WINE_DATA_SERVER_IP + WINE_DATA_SERVER_URL
            // 'http://192.168.3.18:8880/api/dataserver'
            //WINE_DATA_SERVER_IP + WINE_DATA_SERVER_URL
            ////console.log("WINE_DATA_SERVER_IP + WINE_DATA_SERVER_URL", WINE_DATA_SERVER_IP+WINE_DATA_SERVER_URL)


            // 'https://rougewineselector.com/api/dataserver'    
            // let fetchEndpointURL = 'https://rougewineselector.com/api/dataserver' 

            //'http://192.168.3.13:8880/data' 
            //'http://localhost:8880/data' 
            //'http://192.168.3.13:8880/data' 
            //'https://your-api-endpoint/data' 


            try {
                // Make the network request to query for database data
                const response = await fetch(fetchEndpointURL);
                const data = await response.json();

                if (devModeBool == true) {
                    console.log('FETCH_DATA SUCCESS ----- ', data)
                }



                // Dispatch an action with the retrieved data

                //dispatch({ type: 'QUERY_WINE_DATA_SUCCESS', payload: data });
                await dispatch(QueryWineDataSuccess(data));
                setLoading(false);


                // let dispatchPayload = {
                //     sortBy: 'type',
                //     activeMenu: 'glasses',
                //     toggleData: ['bubbles', true, [0, 0]],
                // }

                // let dataToSearch = null

                // let multiDispatchObject = {
                //     searchPanelFields: dispatchPayload,
                //     searchResults: dataToSearch,
                // }
                // try {
                //     dispatch(UpdateSearchPanelFields(multiDispatchObject))
                // } catch (error) {
                //     console.error('Error dispatching actions:', error);
                // }





            } catch (error) {
                // Handle any errors that occur during the request
                if (devModeBool == true) {
                    console.error('Error:', error);
                    console.log('FETCH_DATA ----- ERROR')
                }

            }



        };

        fetchData();
    }, []);

    // empty array makes it just happen on initial page load?
    // https://codedamn.com/news/reactjs/useeffect-dependency
    // useEffect dependency array in React.js – Complete Guide






    // function myFunction() {
    //     console.log("Delayed function executed.");


    //     let dispatchPayload = {
    //         sortBy: 'type',
    //         activeMenu: 'glasses',
    //         toggleData: ['bubbles', true, [0, 1]],
    //     }

    //     let dataToSearch = null

    //     let multiDispatchObject = {
    //         searchPanelFields: dispatchPayload,
    //         searchResults: dataToSearch,
    //     }
    //     try {
    //         dispatch(UpdateSearchPanelFields(multiDispatchObject))
    //     } catch (error) {
    //         console.error('Error dispatching actions:', error);
    //     }

    // }













    // const theme = createTheme({
    //     palette: {
    //         primary: {
    //             main: 'rgb(150,18,57)',
    //         },
    //         error: {
    //             main: '#0044ff',
    //         },
    //     }
    // })







    // Extract data from props
    const { data } = props;

    //console.log("PROPS DATA: ", props)

    const displayHeading = props.reducerData.display
    const displayItems = props.reducerData.queryObjectArray
    const renderComponent = props.reducerData.renderComponent


    const { wineData } = props;

    const location = useLocation();
    const locationData = location;
    const locationState = locationData.state;
    const initialDisplayItem = 'GLASSES'
    if (locationState != null) {
        initialDisplayItem = locationState.displayItem;
    }

    if (devModeBool == true) {
        console.log("PROPS displayHeading 1: ", locationData)
        console.log("PROPS displayHeading 2: ", locationState)
        console.log("PROPS displayHeading 3: ", initialDisplayItem)
    }


    const [rerenderChild, setRerenderChild] = useState(false);

    useEffect(() => {
        // Update the desired property based on prop1Value changes
        // You can trigger any necessary actions or state updates here
        // For example: updateDisplaySelection(prop1Value);
        setMenuFunction(initialDisplayItem)
        ////console.log("initialDisplayItem  ", initialDisplayItem);
        setRerenderChild(true);
    }, [initialDisplayItem]);




    const [activeMenu, setActiveMenu] = useState(initialDisplayItem);
    const [sortBy, setSortBy] = useState('type');



















    const toggleAll = (boolValue) => {


        let actionObject = {
            activeMenu: activeMenu.toLowerCase(),
            sortBy: sortBy,
        }
        let toggleBool = boolValue

        if (devModeBool == true) {
            console.log("TOGGLE", actionObject, toggleBool)
        }



        let dispatchDataObject = {
            actionObject: actionObject,
            toggleBool: toggleBool,
        }


        dispatch(ToggleAll(dispatchDataObject))



        // let generatePayload = null
        // setTimeout(() => {
        //     dispatch(GenerateCurrentResults(generatePayload))
        // }, 2000);
    };






    const setMenuFunction = (newValue) => {

        setActiveMenu(newValue);
        ////console.log("activeMenu: ", activeMenu)
        //setTimeout(() => {
        toggleAll(false)
        // }, 500);


        //toggleAll(false)

        // let sortByLower = sortBy.toLowerCase()
        // let activeMenuLower = activeMenu.toLowerCase()

        // let initializePayloadObject = {
        //     sortBy: sortByLower,
        //     activeMenu: activeMenuLower,
        // }

        // setTimeout(newFxn(initializePayloadObject), 2000);


    };

    //console.log("activeMenu: ", activeMenu)

    const setSortByFunction = (newValue) => {
        setSortBy(newValue);
        if (devModeBool == true) {
            console.log("sortBy: ", sortBy)
        }


        toggleAll(false)
    };











    const dispatch = useDispatch();

    // const DispatchUpdateWineList = () => {
    //     console.log('DispatchUpdateWineList: ', activeMenu)
    //     let actionObject = {
    //         display: activeMenu,
    //         sort: sortBy,
    //     }
    //     try {
    //         dispatch(UpdateWineList(actionObject))
    //     } catch (error) {
    //         console.error('Error dispatching actions:', error);
    //     }
    // };

    // useEffect(() => {
    //     console.log("effect")
    //     DispatchUpdateWineList()
    // }, [activeMenu, sortBy]);




    // function newFxn() {

    //     let sortByLower = sortBy.toLowerCase()
    //     let activeMenuLower = activeMenu.toLowerCase()

    //     let initializePayloadObject = {
    //         sortBy: sortByLower,
    //         activeMenu: activeMenuLower,
    //     }

    //     dispatch(InitializeWineSearchResults(initializePayloadObject))
    // }




    // useEffect(() => {
    //     setTimeout(newFxn(), 2000);
    // }, [activeMenu, sortBy]);



    // let sortByLower = sortBy.toLowerCase()
    // let activeMenuLower = activeMenu.toLowerCase()

    // let initializePayloadObject = {
    //     sortBy: sortByLower,
    //     activeMenu: activeMenuLower,
    // }

    // setTimeout(newFxn(initializePayloadObject), 2000);



    useEffect(() => {
        const initializeData = async () => {

            if (devModeBool == true) {
                console.log('DispatchUpdateWineList: ', activeMenu)
            }


            let actionObject = {
                display: activeMenu,
                sort: sortBy,
            }
            try {
                await dispatch(UpdateWineList(actionObject))


                let sortByLower = sortBy.toLowerCase()
                let activeMenuLower = activeMenu.toLowerCase()

                let initializePayloadObject = {
                    sortBy: sortByLower,
                    activeMenu: activeMenuLower,
                }

                await dispatch(InitializeWineSearchResults(initializePayloadObject))

                const generatePayload = { activeMenu: 'glasses', sortBy: 'type' }


                setTimeout(() => {
                    dispatch(GenerateCurrentResults(generatePayload))
                }, 1000);

                ///await dispatch(ToggleAll(false))



            } catch (error) {
                console.error('Error dispatching actions:', error);
            }


        };

        initializeData();
    }, [activeMenu, sortBy]);


























    const thresholdElementRef = useRef(null);
    let thresholdPosition = 0;
    let thresholdPassed = true
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const thresholdElementRef2 = useRef(null);
    let thresholdPosition2 = 0;
    let thresholdPassed2 = true
    const [triggerUpdate2, setTriggerUpdate2] = useState(false);

    
    
    const [scrollValue, setScrollValue] = useState(0);



    // useEffect(() => {
    //     let timeoutId;

    //     const handleScroll2 = () => {
    //       clearTimeout(timeoutId);
    //       timeoutId = setTimeout(() => {
    //         setScrollValue(window.scrollY);
    //       }, 100); // Adjust the debounce delay (milliseconds) as needed
    //     };

    //     window.addEventListener('scroll', handleScroll2);

    //     return () => {
    //       window.removeEventListener('scroll', handleScroll2);
    //       clearTimeout(timeoutId);
    //     };
    //   }, []);





    useEffect(() => {

        const handleScroll = () => {

            // let scrollPosition = window.scrollY;
            // setScrollValue(scrollPosition)
            // console.log("scrollValue ", scrollValue)



            const { top } = thresholdElementRef.current.getBoundingClientRect();
            // console.log("top ", top)

            // if (scrollPosition > thresholdPosition) {
            //     console.log("passed threshold")
            //     console.log('scrolling ', window.scrollY)
            //   // The threshold has been passed
            //   // Do something, like closing the menu
            // }

            if (top < 0) {
                //console.log("passed threshold")
                setTriggerUpdate(true);
                // The threshold has been passed
                // Do something, like closing the menu
            }
            if (top > 0) {
                //console.log("Not passed threshold")
                setTriggerUpdate(false);
                // The threshold has been passed
                // Do something, like closing the menu
            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [thresholdPosition]);






    useEffect(() => {

        const handleScroll2 = () => {

            const { top } = thresholdElementRef2.current.getBoundingClientRect();

            if (top < 0) {
                setTriggerUpdate2(true);
            }
            if (top > 0) {
                setTriggerUpdate2(false);
            }

        };

        window.addEventListener('scroll', handleScroll2);

        return () => {
            window.removeEventListener('scroll', handleScroll2);
        };
    }, [thresholdPosition2]);









    // useEffect(() => {
    //     if (thresholdElementRef.current) {
    //         const { top } = thresholdElementRef.current.getBoundingClientRect();
    //         console.log(top)
    //         //thresholdPosition = window.scrollY + top;
    //     }
    // }, []);









    ////console.log("===================== ", props)











    const fadeAnim = `

@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.4; // Adjust the final opacity to control the intensity of the background
    }
  }

  @keyframes fade-in2 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1; // Adjust the final opacity to control the intensity of the background
    }
  }



`

    // import { useTransition, animated } from "react-spring";

    // const transition = useTransition(images[index], {
    //     key: images[index].id, // Add a unique key
    //     from: { opacity: 0 },
    //     enter: { opacity: 1 },
    //     leave: { opacity: 0 },
    //     config: { duration: 700 },
    // });

    const [index, setIndex] = useState(0);
    const transition = useTransition(index, {
        key: index,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 7000 },
    });


























    return (
        <div style={{ backgroundColor: 'black', width: '1' }}>




            <ThemeProvider theme={theme}>

                {/* 
                triggerUpdate && (
 */}

                <div style={{
                    opacity: "100%",
                    //backgroundColor: 'gray', 
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    zIndex: 9999,
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    //flexDirection: 'column',
                    padding: '0rem',
                    border: '0rem',
                    margin: '0rem',
                    //height:'1rem',
                    //backgroundColor: 'gray',
                    //animation: `${fadeAnim} `, // Use the fade-in animation for 2 seconds


                }} >






                    {/* <p>MATCHES {props.wineData.wineSearchResultsLength}</p> */}


                    {/* <Typography align="center" fontSize='1.3rem' color='black' fontWeight='bold'
                            style={{ padding: '0px', margin: '0px', }}
                        >
                            AVAILABLE MATCHES
                        </Typography>


                        <Typography align="center" fontSize='1.3rem' color='black' fontWeight='bold'
                            style={{ padding: '0px', margin: '0px', }}
                        >
                            {props.wineData.wineSearchResultsLength}
                        </Typography> */}




                    {/* <ResultsBanner data={true}>
                    //parentElementOffset={thresholdElementRef.current?.getBoundingClientRect().top}
                    </ResultsBanner> */}


                    <FadingComponent
                        inputProp={triggerUpdate}
                        value={props.wineData.wineSearchResultsLength}
                        activeMenu={activeMenu}
                    ></FadingComponent>







                </div>

                {/*                 
                )}
 */}

                <DivWrapper>

                    <Box  >

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <MenuBar rerender={rerenderChild}></MenuBar>
                            </Grid>


                            <StyledBox2>
                                <Grid item xs={12} sm={8} md={8}  >
                                    <Card >
                                        <Paper
                                            style={{
                                                margin: '0rem',
                                                padding: '10%',
                                                border: '0rem',
                                                backgroundColor: 'black',
                                                backgroundImage: `url(${wineImg})`,
                                                repeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                width: "100%",
                                                height: "auto",
                                            }} square
                                        />
                                    </Card>
                                </Grid>
                            </StyledBox2>


                        </Grid>




                        <Grid item xs={12} sm={12} md={12} >
                            <StyledBox>

                                <Grid item xs={6} sm={4} md={4} align="center"  >
                                    <Typography sx={{ opacity: GLOBAL_OPACITY, margin: '5px', }} backgroundColor='#707070' width='7rem'
                                        onClick={() => {
                                            setMenuFunction('GLASSES')
                                        }} >
                                        <StyledButton >Glasses</StyledButton>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} align="center"  >
                                    <Typography sx={{ opacity: GLOBAL_OPACITY, margin: '5px', }} backgroundColor='#707070' width='7rem'
                                        onClick={() => {
                                            setMenuFunction('FLIGHTS')
                                        }} >
                                        <StyledButton   >Flights</StyledButton>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} align="center" >
                                    <Typography sx={{ opacity: GLOBAL_OPACITY, margin: '5px', }} backgroundColor='#707070' width='7rem'
                                        onClick={() => {
                                            setMenuFunction('BOTTLES')
                                        }} >
                                        <StyledButton >Bottles</StyledButton>
                                    </Typography>
                                </Grid>
                            </StyledBox>
                        </Grid>

                        <Grid item xs={6} sm={4} md={4} align="center" style={{ borderRadius: '0px', }}>
                            <StyledTypography backgroundColor='silver'
                            >
                                {displayHeading}
                            </StyledTypography>
                        </Grid>





                        <SortByPanel activeMenu={activeMenu} setSortByFunction={setSortByFunction}></SortByPanel>


                        <div ref={thresholdElementRef} >
                        </div>

                        <SearchPanel activeMenu={activeMenu} sortBy={sortBy} thresholdPassed={thresholdPassed} triggerUpdate={triggerUpdate2}></SearchPanel>

                        <div ref={thresholdElementRef2} >
                        </div>



                        <div style={{ display: "flex", justifyContent: 'center', maxWidth: '100vw' }}>
                            <RenderSelector activeMenu={activeMenu} selection={renderComponent} stillLoading={loading} ></RenderSelector>
                        </div>


                        <Footer></Footer>
                    </Box>
                </DivWrapper>
            </ThemeProvider>
        </div >
    );
};








const mapStateToProps = (state) => {
    const reducerData = state.reducer4
    const wineData = state.wineData
    return {
        reducerData,
        wineData,
    };
};

export default connect(mapStateToProps)(WineList);















{/* 


                    <Typography align="center" fontSize='1rem' backgroundColor='black' color='rgb(171,18,57)'
                        style={{ padding: '0px', margin: '0px' }}
                    >
                        SORT BY:
                    </Typography>


                    <Grid item xs={12} sm={12} md={12} style={{
                        padding: '5px',
                        paddingTop: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                    }}>
                        <Button style={{ padding: '0px', margin: '0px', width: '30vw', backgroundColor: 'gray' }}
                            onClick={() => {
                                setSortByFunction('type')
                            }}
                        >TYPE</Button>

                        <Button style={{ padding: '0px', margin: '0px', width: '30vw', backgroundColor: 'gray' }}
                            onClick={() => {
                                setSortByFunction('grape')
                            }}
                        >GRAPE</Button>
                        <Button style={{ padding: '0px', margin: '0px', width: '30vw', backgroundColor: 'gray' }}
                            onClick={() => {
                                setSortByFunction('region')
                            }}
                        >REGION</Button>

                    </Grid>

 */}


{/* 

                    <Grid item xs={12} sm={12} md={12} >
                        <StyledBox>

                            <Grid item xs={6} sm={4} md={4} align="center"  >
                                <Typography backgroundColor='silver' width='7rem'
                                    onClick={() => {
                                        setMenuFunction('WINE GLASSES')
                                    }} >
                                    <StyledButton>Glasses</StyledButton>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} align="center"  >
                                <Typography backgroundColor='silver' width='7rem'
                                    onClick={() => {
                                        setMenuFunction('FLIGHTS')
                                    }} >
                                    <StyledButton   >Flights</StyledButton>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} align="center" >
                                <Typography backgroundColor='silver' width='10rem'
                                    onClick={() => {
                                        setMenuFunction('WINE BOTTLES')
                                    }} >
                                    <StyledButton >Bottles</StyledButton>
                                </Typography>
                            </Grid>
                        </StyledBox>
                    </Grid>

 */}

{/* 
                    {renderComponent}

                    */}
































/*



["NAME", 'INFO', 'ID', 'TYPE', 'GRAPE', 'LOCATION', 'ALLERGEN', 'PRICE', 'DESCRIPTION', 'FLAVORS', 'PAIRS', "IMAGE" ],

["NAME", 'INFO', 'ID', 'TYPE', 'GRAPE', 'LOCATION', 'ALLERGEN', 'PRICE', 'DESCRIPTION', 'FLAVORS', 'PAIRS', "IMAGE" ],

new categories for DB
id - each wine gets an id to be used internally in the db
name - name to be displayed on top of title card and modal
info - short description for modal, NOT SEARCHABLE
type - string, e.g. bubbles, white, rose, red, etc, categories should auto generate based on querying all types
grape - string, e.g. pinot noir, sauvignon blanc, etc. also should auto generate
-- IDEA -- maybe have button to switch between categories, either "type" or "grape" or maybe even "location"
region - e.g. italy, california, france, etc. Broad region so multiple wines will have same one so it can be used as a searchable category 
sub-region OR location - more specific location, e.g. the city it is from, not searchable

scores for each of the slider categories

flavor notes - comma separated list of flavor notes, will be used to dynamically generate list that is clickable to turn notes on or off
minor flavor notes - not searchable, will appear in modal but not search menu, used to enable inclusion without cluttering

allergen
price
    - price per glass
    - price per bottle
Presence of lultiple items e.g. both glass and bottle implies available by the glass
presence of single entry for bottle price implies it is a bottle 

flight name - if present it will appear on the flights list under that flight
-- so flights will be dynaically generated based on this category
flight category - e.g. whites, reds, bourbon, etc. - will dynamically generate flight categories based on this data

SEPARATE TABLE - category priority - unique number which determines where on list category should be

detailed description - maybe dont include, omit for now
pairs - suggested pairings, could be searchable at some point, include for now without search feature
image - name of image file associated with wine, allow for multiple images stored as comma separated list in DB 

to limit network request traffic, db query will result in sending full list of wines to be 
cached in front end and used for further manipulation, this will happen whenever page is loaded
on wine search, so when click is made to redirect to /winelist this should populate a winelist in the redux store with ALL data

need separate priorities db to ensure proper sequence of categories, if this matters, e.g. bubbles = 0, white = 1, rose = 2 etc.
also priority for: grape types, regions, flight category 




order to do stuff:
    *make dummy database and populate w/ sample data
    *make server for db that can query and get info and send
    *accept data in react app and 
    *    -use to populate redux entry
    
    *when load to /winelist page, auto download current version of database

    dynamically generate searh menu based off of data
        *- iterate over all entries, and create set of items in selection e.g. bottles, flights, glasses
        note: still need to do more for flights
            - save this to the "search results" secion of redux store
            --- update --- after first getting data from server, populate list for all three, i.e. make complete list of bottles and glasses
            --- also make data structure for flights

        - iterate over this set and generate checkbox search categories based off of selection (type, grape, or location)
        - iterate over each checkbox category and generate list of flavor notes
        - render page with appropriate checkboxes populated with flavor notes
        - hard code slider options for each of : glasses / bottles  -- (flights will not be searchable)
            - e.g. checkboxes: true, slider1: true, ... , slider6: true, for bottles but : slider5: false for glasses, and all
            false for flights
        - flights will require special logic as it is going to be displayed differently, however this will be 
        handled by "flights" sub-component of winemenu page



        - checkbox data will be stores with useState in a SINGLE variable which will consiste of an object with 
        keys generated from the redux checkbox categories, and each of these will contain an object with a key
        to set category to be true/false AND
        there will also be another key in this object with "flavor notes" which will have a value of another object
        which will have each flavor note as a key with a value of true/false indicating display status

        - slider data will be stored for each slider in a separate useState value, and this will contain a key for
        true or false to indicate whether to render this slider on the page, and if slider is NOT rendered its range will be 
        fully selected at all times so it can still be searched by but will NOT exclude anything
        so slider is effectively still there and is just invisible and inaccessible



    write code to query and create display data based off of full dataset







*/













