
import React, { useState } from 'react';

import CONFIG from '../../config/config.js'
const WINE_CLUB_SIGNUP_IP = CONFIG.WINE_CLUB_SIGNUP_IP
const WINE_CLUB_SIGNUP_URL = CONFIG.WINE_CLUB_SIGNUP_URL

let fetchEndpointURL = WINE_CLUB_SIGNUP_IP + WINE_CLUB_SIGNUP_URL

function SignupForm() {
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const [isPhoneChecked, setIsPhoneChecked] = useState(false);
    const [submitted, setSubmitted] = useState(false); // Track form submission
    const [selected, setSelected] = useState('email'); // Track form submission

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleEmailCheckboxChange = () => {
        setIsEmailChecked(!isEmailChecked);
    };

    const handlePhoneCheckboxChange = () => {
        setIsPhoneChecked(!isPhoneChecked);
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     // You can handle the form submission here and send data to the backend
    //     // For now, let's just log the selected options
    //     console.log('Email:', isEmailChecked ? email : 'Not selected');
    //     console.log('Phone Number:', isPhoneChecked ? phoneNumber : 'Not selected');
    // };





    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            firstName,
            email: isEmailChecked ? email : email,
            phoneNumber: isPhoneChecked ? phoneNumber : null,
        };

        // fetchEndpointURL  'http://localhost:5000/submit' 

        try {
            const response = await fetch(fetchEndpointURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                console.log('Data submitted successfully.');
                setSubmitted(true);
                // You can reset the form fields or display a success message here.
            } else {
                console.error('Error submitting data.');
                setSubmitted(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setSubmitted(true);
        }
    };










    return (
        <div style={{ textAlign: 'center', padding: '20px', color: 'white' }}>
            <p style={{ fontSize: '1.3rem', fontWeight: 'bold' }} >Coming Soon: Rouge Wine Club</p>
            <p style={{ fontSize: '1.2rem' }} >A curated selection of wines available weekly, bi-weekly, or monthly!</p>
            <p style={{ fontSize: '1.2rem' }} >Sign up below to be notified when we launch!</p>


            {submitted ? ( // If the form has been submitted, show the thank-you message
                <div>
                    <p style={{ color: 'rgb(150, 18, 57)', fontSize: '1.3rem', fontWeight: 'bold' }} >Thank you, {firstName}, for signing up!</p>
                    <p style={{ color: 'rgb(150, 18, 57)', fontSize: '1.3rem', fontWeight: 'bold' }} >Please check your {selected} for confirmation</p>
                </div>
            ) : ( // If the form has not been submitted, show the form elements

                <form onSubmit={handleSubmit} style={{ display: 'inline-block', textAlign: 'left' }}>



                    <div style={{ marginBottom: '15px' }}>
                        {/* <label htmlFor="firstName" style={{ fontSize: '18px' }}>
                        First Name
                    </label> */}
                        <input
                            type="text"
                            id="firstName"
                            placeholder="Enter your first name"
                            value={firstName}
                            onChange={handleFirstNameChange}
                            required
                            style={{ width: '100%', padding: '5px', fontSize: '16px' }}
                        />
                    </div>



                    <div style={{ marginBottom: '15px' }} >
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            style={{ width: '100%', padding: '5px', fontSize: '16px' }}
                        />
                    </div>



                    {/* <div style={{ marginBottom: '15px' }}>
                        <input
                            type="checkbox"
                            id="emailCheckbox"
                            checked={isEmailChecked}
                            onChange={handleEmailCheckboxChange}
                            style={{ width: '30px', height: '30px', marginRight: '10px' }}
                        />
                        <label htmlFor="emailCheckbox" style={{ fontSize: '18px' }}>
                            Notify by email
                        </label>
                    </div>
                    {isEmailChecked && (
                        <div style={{ marginBottom: '15px' }} >
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={handleEmailChange}
                                required
                                style={{ width: '100%', padding: '5px', fontSize: '16px' }}
                            />
                        </div>
                    )} */}



                    {/* <div style={{ marginBottom: '15px' }}>
                        <input
                            type="checkbox"
                            id="phoneCheckbox"
                            checked={isPhoneChecked}
                            onChange={handlePhoneCheckboxChange}
                            style={{ width: '30px', height: '30px', marginRight: '10px' }}
                        />
                        <label htmlFor="phoneCheckbox" style={{ fontSize: '18px' }}>
                            Notify by text message
                        </label>
                    </div>
                    {isPhoneChecked && (
                        <div>
                            <input
                                type="tel"
                                placeholder="Enter your phone number"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                required
                                style={{ width: '100%', padding: '5px', fontSize: '16px' }}
                            />
                        </div>
                    )} */}



                    <div style={{ display: 'flex', justifyContent: 'center' }} >
                        <button type="submit" style={{ marginTop: '15px', padding: '10px 20px', fontSize: '18px' }}>
                            Submit
                        </button>
                    </div>

                </form>
            )}

        </div>

    );
}

// Sign up for Email
// Sign up for Text Messaging




export default SignupForm;
