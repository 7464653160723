
export default function QueryWineDataSuccess (query) {
    return {
      type: 'QUERY_WINE_DATA_SUCCESS',
      payload: query
    };
  };







// I THINK THIS IS NO LONGER USED BUT NOT SURE

// export const QueryWineDataSuccess = (query) => async (dispatch) => {

//   console.log("--------------------------------------------------ATTEMPTING TO UPDATE_WINE_SEARCH_RESULTS 1")
//   try {

//     dispatch(QueryWineDataSuccess2(query));


//     let dispatchPayload = {
//       sortBy: 'type',
//       activeMenu: 'glasses',
//       toggleData: ['bubbles', true, [0,1]],
//     }

//     let multiDispatchObject = {
//       searchPanelFields: dispatchPayload,
//       searchResults: "dataToSearch",
//     }

//     console.log("--------------------------------------------------ATTEMPTING TO UPDATE_WINE_SEARCH_RESULTS")
//     dispatch(UpdateWineSearchResults(multiDispatchObject));

//   } catch (error) {
//     console.log("--------------------------------------------------FAILED TO UPDATE_WINE_SEARCH_RESULTS")
//   }
// };

// const QueryWineDataSuccess2 = (query) => ({
//   type: 'QUERY_WINE_DATA_SUCCESS',
//   payload: query,
// });

// const UpdateWineSearchResults = (payloadObject) => ({
//   type: 'UPDATE_WINE_SEARCH_RESULTS',
//   payload: payloadObject,
// });